import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as OnlineResearch } from '../../assets/OnlineprotectionTwoColor.svg';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import { UserContext } from '../../Contexts/UserContext';

const MyAccount = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [loading, setLoading] = useState(false);
  const [userContext] = useContext(UserContext);
  const [account, setAccount] = useState({});
  const [plan, setPlan] = useState('');

  useEffect(() => {
    setLoading(true);
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/users/account',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setAccount(res.data.account);
        if (res.data.account.plan === 'One Off') {
          setPlan(t('plan.plan1'));
        } else if (res.data.account.plan === '1 Year') {
          setPlan(t('plan.plan2'));
        } else if (res.data.account.plan === '2 Year') {
          setPlan(t('plan_old'));
        } else if (res.data.account.plan === '3 Year') {
          setPlan(t('plan.plan3'));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    window.document.title = t('page_title.my_account');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div className={classes.root + ' ' + classes.projectX}>
        <Grid container justifyContent="center">
          <Grid container item xs={12} className={classes.container}>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h6" align="center">
                  {t('my_account.title_1')}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography align="center" color="textSecondary">
                  {t('my_account.subtitle_1')}
                </Typography>
              </Box>
            </Grid>
            {/*1*/}
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRightt: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        alignContent="center"
                        container
                        item
                        xs={isLargeScreen ? 7 : 12}
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('my_account.title_2')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              {t('my_account.subtitle_2')}{' '}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={isLargeScreen ? 5 : 12}>
                        <OnlineResearch
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/*2*/}
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRightt: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography component="h6" variant="h6">
                            {t('my_account.title_3')}{' '}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.mTop12 + ' ' + classes.mBottom12}
                      >
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.firstname')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {userContext.user.firstName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.mTop12 + ' ' + classes.mBottom12}
                      >
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.lastname')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {userContext.user.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.mTop12 + ' ' + classes.mBottom12}
                      >
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.email')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {userContext.user.email}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container item xs={12} className={classes.mTop12}>
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.phone')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {userContext.user.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/*3*/}
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRightt: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography component="h6" variant="h6">
                            {t('my_account.title_4')}{' '}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.mTop12 + ' ' + classes.mBottom12}
                      >
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.companyname')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {account.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container item xs={12} className={classes.mTop12}>
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            {t('my_account.kvk')}{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">
                            {account.registrationNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* 4 */}
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRightt: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography component="h6" variant="h6">
                            {t('my_account.title_5')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid container item xs={12} className={classes.mTop12}>
                        <Grid item xs={isLargeScreen ? 3 : 12}>
                          <Typography
                            component="h6"
                            variant="button"
                            color="textSecondary"
                          >
                            Plan
                          </Typography>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 9 : 12}>
                          <Typography color="textSecondary">{plan}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withRouter(MyAccount);
