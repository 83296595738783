import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import BaseLayout from "../Layouts/BaseLayout";
import { useMediaQuery } from "react-responsive";
import ProjectSidebar from "../Components/Onboarding/ProjectSidebar";
import SubsidiesList from "../Components/Onboarding/SubsidiesList";
import { ReactComponent as SupportAgent } from "../assets/support_agent_black_36dp.svg";
import { OnboardingContext } from "../Contexts/Onboarding";
import { UserContext } from "../Contexts/UserContext";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";

const SelectSubsidies = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const [progressStep, setProgressStep] = useState(10);
  const [projectProgress, setProjectProgress] = useState(3);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);
  const {
    newProjectId,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setProjectSocial,
  } = useContext(OnboardingContext);
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    if (newProjectId) {
      let apiEndPoint =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      axios({
        method: "get",
        url: apiEndPoint + "/project/getFirstProject",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((response) => {
          if (response.data.success) {
            setProjectType(response.data.project.projectTypeId);
            localStorage.setItem(
              "firstProjectType",
              response.data.project.projectTypeId
            );
            if (response.data.project.projectTypeId === "0123X000001GJUGQA4") {
              setProjectProduct(response.data.project);
            } else if (
              response.data.project.projectTypeId === "0123X000001GJUCQA4"
            ) {
              setProjectInt(response.data.project);
            } else if (
              response.data.project.projectTypeId === "0123X000001GJUDQA4"
            ) {
              setProjectBusiness(response.data.project);
            } else if (
              response.data.project.projectTypeId === "0123X000001GJUEQA4"
            ) {
              setProjectEmployees(response.data.project);
            } else if (
              response.data.project.projectTypeId === "0123X000001GJUFQA4"
            ) {
              setProjectSocial(response.data.project);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    window.document.title = t("page_title.select_subsidies");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            <SubsidiesList
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              projectProgress={projectProgress}
              setProjectProgress={setProjectProgress}
              progressStep={progressStep}
              setProgressStep={setProgressStep}
              center={`${center}%`}
            />
          </>
        }
        sidebar={
          <ProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        close={
          <>
            {isLargeScreen && (
              <>
                <SupportAgent
                  style={{
                    paddingRight: 8,
                    fill: theme.palette.secondary.main,
                  }}
                />
                <span>+31 (0)85 580 74 21</span>
              </>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {t("buttons.logout")}
            </Button>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default SelectSubsidies;
