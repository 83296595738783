/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import MakeAccount from '../Components/Subsidyscan/MakeAccount';
import CTASidebar from '../Components/Subsidyscan/CTASidebar';
import { OnboardingContext } from './../Contexts/Onboarding';

const Report = () => {
  const { t } = useTranslation();
  const [progressStep, setProgressStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);
  const { subsidiesAmount, subsidiesNumber, answers, setAnswers, scanDone } =
    useContext(OnboardingContext);

  useEffect(() => {
    window.document.title = t('page_title.make_acccount');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!answers.Investment_Needed__c && !scanDone) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <BaseLayout
        main={
          <MakeAccount
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            scanDone={scanDone}
            subsidiesNumber={subsidiesNumber}
            subsidiesAmount={subsidiesAmount}
            answers={answers}
            setAnswers={setAnswers}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            center={`${center}%`}
          />
        }
        sidebar={
          <CTASidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            center={`${center}%`}
          />
        }
        close={
          <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)}>
            {t('buttons.close')}
          </Button>
        }
      ></BaseLayout>
    </>
  );
};

export default Report;
