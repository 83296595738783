import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import contractEn from '../../assets/contract_en.png';
import contractNl from '../../assets/contract_nl.png';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import IframeDialog from '../uiComponents/IframeDialog';
import { handleChange, checkValidation } from '../../JS/FormControls';
import { UserContext } from '../../Contexts/UserContext';
import { OnboardingContext } from '../../Contexts/Onboarding';
import { planOptionss } from '../../data/planOptions';
import Loader from '../uiComponents/Loader';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';
import axios from 'axios';
import useDebounce from './debounce';
import ReactGA from 'react-ga';
import i18n from '../../JS/i18n';
import { useTheme } from '@material-ui/core/styles';

const CheckoutDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { t } = useTranslation();
  const { setShowWelcomeNewUser } = useContext(OnboardingContext);
  const [userContext, setUserContext] = useContext(UserContext);
  const [searchString, setSearchString] = useState('');
  // renamed loading to loader to avoid conflict with autocomplete
  const [loader, setLoader] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [checkout, setCheckout] = useState({
    company: '',
    kvk: '',
    phone: userContext.user.phone,
  });
  const debouncedSearchTerm = useDebounce(searchString, 500);
  const [disabled, setDisabled] = useState(true);
  const [isSigned, setIsSigned] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep);
  const planOptions = planOptionss();

  const [signUrl, setSignUrl] = useState('');

  const [open, setOpen] = useState(false);

  const handleSignDialog = () => {
    setOpenSignDialog(false);
  };

  const handleSuccessDialog = () => {
    setOpenSuccessDialog(false);
    setIsSigned(true);
  };

  var apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  var requestBody = {
    Account: {
      name: checkout.company,
      dossier_number: checkout.kvk,
      phone: checkout.phone,
      plan: props.plan,
      selectedSubsidies: props.selectedSubsidies,
    },
    language: i18n.languages[0],
  };

  const getSigningUrl = () => {
    setLoader(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/checkout/createEsignPackage',
      withCredentials: true,
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setOpenSignDialog(true);
        setSignUrl(res.data.url);
        setIsSent(true);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  async function searchCharacters(search) {
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    return axios({
      method: 'post',
      url: apiEndPoint + '/companyinfo/getCompany',
      data: { company_name: search },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => res.data.company_list)
      .catch((error) => {
        return [];
      });
  }

  useEffect(() => {
    if (debouncedSearchTerm && searchString.length > 2) {
      setIsSearching(true);
      searchCharacters(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
        setCompanyOptions(results);
      });
    } else {
      setCompanyOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!open) {
      setCompanyOptions([]);
    }
  }, [open]);

  const ROUTES = ['/client/app/dashboard'];

  const handleNext = () => {
    setLoader(true);
    setShowWelcomeNewUser(true);
    var requestBody = {
      Account: {
        name: checkout.company,
        dossier_number: checkout.kvk,
      },
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/checkout/postProject',
      withCredentials: true,
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        ReactGA.event({
          category: 'Onboarding',
          action: 'Contract Signed',
          label: props.plan ? props.plan : '',
        });
        props.setProgressStep(props.progressStep + 1);
        setLoader(false);
        userContext.user.phone = checkout.phone;
        setUserContext((oldValues) => {
          return { ...oldValues, user: userContext.user };
        });
        history.push(ROUTES[0]);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setDisabled(!checkValidation(checkout));
  }, [checkout]);

  useEffect(() => {
    if (
      userContext.user.signingStatus === 'Sent' ||
      userContext.user.signingStatus === 'Signed'
    ) {
      setLoader(true);
      setSignUrl(userContext.user.signingURL);
      axios({
        method: 'get',
        url: apiEndPoint + '/checkout/getCompanyInfo',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userContext.token}`,
        },
      })
        .then((res) => {
          if (res.data.status === 'COMPLETED') {
            setDisabled(true);
            setIsSigned(true);
          } else {
            setDisabled(false);
            setIsSent(true);
          }
          setLoader(false);
          setCheckout({
            ...checkout,
            company: res.data.account.name,
            kvk: res.data.account.registrationNumber,
            phone: userContext.user.phone,
          });
          setSearchString(res.data.account.name);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loader && <Loader loading={loader} />}
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
        >
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            {/*<>TITLE</>*/}
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className="title-section"
            >
              <Grid item xs={12} lg={6} className={classes.titleCheckout}>
                <Typography variant="h6">{t('checkout.title')}</Typography>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent={isLarge ? 'flex-start' : 'center'}
              className={classes.subSection}
            >
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box mb={2.5}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitleCheckout}
                    >
                      {t('checkout.plan')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card} variant="outlined">
                    <CardContent>
                      <Typography
                        className={classes.cardTitle}
                        gutterBottom
                        variant="h6"
                      >
                        {planOptions[props.plan].title}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                      >
                        {planOptions[props.plan].subtitle}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitleCheckout}
                    >
                      {t('checkout.company')}
                    </Typography>
                  </Box>
                  <Box pb={2.5}>
                    <Typography
                      variant="body2"
                      className={classes.textDisabled}
                    >
                      {t('project-details.subtitle')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="company"
                    name="company"
                    open={open && searchString.length > 2}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    options={companyOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    loading={isSearching}
                    popupIcon={<SearchIcon />}
                    className={classes.formControl + ' ' + classes.searchIcon}
                    onChange={(e, value) => {
                      if (value) {
                        setCheckout({
                          ...checkout,
                          company: value.name,
                          kvk: value.dossier_number,
                        });
                      } else {
                        setCheckout({
                          ...checkout,
                          company: '',
                          kvk: '',
                        });
                      }
                    }}
                    inputValue={searchString}
                    onInputChange={(event, newInputValue) => {
                      setSearchString(newInputValue);
                    }}
                    noOptionsText={t('dialog.no_options')}
                    loadingText={t('dialog.loading')}
                    disabled={isSigned || isSent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('labels.company')}
                        placeholder=""
                        className={classes.helperText}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isSearching ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    id="kvk"
                    name="kvk"
                    label={t('labels.kvk')}
                    variant="outlined"
                    type="number"
                    fullWidth
                    autoComplete="off"
                    value={checkout.kvk}
                    onChange={(e) => {
                      handleChange(e, checkout, setCheckout);
                    }}
                    inputProps={{ maxLength: 8, minLength: 8 }}
                    disabled={isSigned || isSent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    id="phone"
                    name="phone"
                    label={t('labels.mobile') + ' / ' + t('labels.phone')}
                    variant="outlined"
                    type="number"
                    fullWidth
                    autoComplete="off"
                    value={checkout.phone}
                    placeholder="0612345678"
                    onChange={(e) => {
                      handleChange(e, checkout, setCheckout);
                    }}
                    disabled={isSigned || isSent}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*<>OVEREENKOMST</>*/}
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent={isLarge ? 'flex-end' : 'center'}
              className={classes.subSection}
            >
              <Grid
                container
                item
                xs={12}
                style={isLarge ? {} : { paddingBottom: 64 }}
              >
                <Grid item xs={12}>
                  <Box mb={2.5}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitleCheckout}
                    >
                      {t('checkout.contract')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item xs={12} className={classes.signSide}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundImage:
                        i18n.languages[0] === 'nl'
                          ? `url(${contractNl})`
                          : `url(${contractEn})`,
                      backgroundPosition: 'top',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      height: '454px',
                      border: '1px solid #E0E0E0',
                    }}
                  ></Grid>
                  <Grid
                    container
                    justifyContent="center"
                    item
                    xs={12}
                    className={
                      isSigned ? classes.signButtonBase : classes.signButton
                    }
                  >
                    <Button
                      color={isSigned ? 'primary' : 'secondary'}
                      variant={isSigned ? 'outlined' : 'contained'}
                      startIcon={
                        isSigned ? <CloudDownloadIcon /> : <CreateIcon />
                      }
                      disabled={disabled}
                      onClick={() => {
                        if (!isSigned) {
                          if (signUrl) {
                            setOpenSignDialog(true);
                          } else {
                            getSigningUrl();
                          }
                        }
                      }}
                    >
                      {isSigned ? t('labels.download') : t('labels.sign')}
                    </Button>
                    {isSigned && (
                      <IconButton
                        aria-label="check"
                        variant="contained"
                        style={{
                          position: 'relative',
                          right: 0,
                          top: 0,
                          cursor: 'auto',
                          backgroundColor: theme.palette.secondary.main,
                          color: '#ffffff',
                          width: 40,
                          height: 40,
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*<> BUTTONS SECTION</> */}
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                      disabled={isSigned || isSent}
                    >
                      {t('buttons.back')}
                    </Button>

                    <Button
                      color="secondary"
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={!isSigned}
                    >
                      {t('buttons.checkout')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* LOGOUT */}

      <ConfirmationDialog
        openDialog={props.openDialog}
        setOpenDialog={props.setOpenDialog}
        title={t('logout')}
        text={t('dialog.text2')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
      />

      <IframeDialog
        handleSignDialog={handleSignDialog}
        openSignDialog={openSignDialog}
        openSuccessDialog={openSuccessDialog}
        setOpenSignDialog={setOpenSignDialog}
        setOpenSuccessDialog={setOpenSuccessDialog}
        handleSuccessDialog={handleSuccessDialog}
        signUrl={signUrl}
      />
    </>
  );
};

export default CheckoutDetails;
