import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import {
  Grid,
  Button,
  Divider,
  Box,
  Typography,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';
import { default as File } from '../uiComponents/UploadedFile';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import DropzoneBox from '../uiComponents/DropzoneBox';

const UploadTypeInternational = ({
  progressStep,
  openDialog,
  setOpenDialog,
  center,
  newProjectId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(progressStep);
  const [loading, setLoading] = React.useState(false);

  const [budgetPlanId, setBudgetPlanId] = useState({
    id: '',
    filename: '',
  });
  const [projectPlanId, setProjectPlanId] = useState({
    id: '',
    filename: '',
  });

  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const getUploadParamsBudgetPlan = ({ file }) => {
    const body = new FormData();
    body.append('budget_plan', file);
    body.append('projectId', newProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsProjectPlan = ({ file }) => {
    const body = new FormData();
    body.append('project_plan', file);
    body.append('projectId', newProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const deleteFile = (documentType) => {
    axios({
      method: 'post',
      url: apiEndPoint + '/upload/delete',
      data: { projectId: newProjectId, documentType: documentType },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => {
      if (res.data.success) {
        if (documentType === 'budget_plan') {
          setBudgetPlanId({ id: '', filename: '' });
        } else if (documentType === 'project_plan') {
          setProjectPlanId({ id: '', filename: '' });
        }
      } else {
        console.log('error', res);
      }
    });
  };

  const handleChangeStatusParamsBudgetPlan = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setBudgetPlanId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusParamsProjectPlan = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setProjectPlanId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const ROUTES = ['/client/addproject', '/client/selectsubsidies'];

  const handleNext = () => {
    history.push(ROUTES[1]);
  };
  const handleBack = () => {
    history.push(ROUTES[0]);
  };

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: apiEndPoint + '/project/getAllDocuments',
      withCredentials: true,
      data: { projectId: newProjectId },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'budget_plan') {
              setBudgetPlanId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'project_plan') {
              setProjectPlanId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetPlanId.id, projectPlanId.id]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        className={classes.root + ' ' + classes.rootUpload}
      >
        <Grid
          container
          item
          xs={12}
          alignContent="center"
          justifyContent={isLargeScreen ? 'space-between' : 'center'}
          className={classes.section + ' ' + classes.uploadSection}
        >
          <Grid container item xs={12} className={classes.uploadContainer}>
            <Grid container item xs={12}>
              <Grid item xs={12} lg={6} style={{ maxWidth: 450 }}>
                <Box mb={1}>
                  <Typography variant="h6">{t('upload.title')}</Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    style={{ color: bdtheme.palette.text.disabled }}
                  >
                    {t('upload.subtitle')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div
                style={{
                  marginLeft: isLargeScreen ? 0 : 'auto',
                  marginRight: 'auto',
                  maxWidth: 450,
                }}
              >
                <DropzoneBox
                  file={budgetPlanId}
                  label={t('upload.label6')}
                  inputContent={t('upload.label5')}
                  getUploadParams={getUploadParamsBudgetPlan}
                  onChangeStatus={handleChangeStatusParamsBudgetPlan}
                  multiple={false}
                  maxFiles={'1'}
                  maxwidth={450}
                />
                {budgetPlanId.id && (
                  <div>
                    <Box mb={2.5} mt={5.5}>
                      <Typography variant="overline" color="textSecondary">
                        {t('upload.label6')}
                      </Typography>
                    </Box>
                    <File
                      name={budgetPlanId.filename}
                      id={budgetPlanId.id}
                      type={'budget_plan'}
                      deleteFile={deleteFile}
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div
                style={{
                  marginRight: isLargeScreen ? 0 : 'auto',
                  marginLeft: 'auto',
                  maxWidth: 450,
                }}
              >
                <DropzoneBox
                  file={projectPlanId}
                  label={t('upload.label7')}
                  inputContent={t('upload.label5')}
                  getUploadParams={getUploadParamsProjectPlan}
                  onChangeStatus={handleChangeStatusParamsProjectPlan}
                  multiple={false}
                  maxFiles={'1'}
                  maxwidth={450}
                />
                {projectPlanId.id && (
                  <div
                    style={{
                      maxWidth: 450,
                      marginLeft: isLargeScreen ? 'auto' : '',
                    }}
                  >
                    <Box mb={2.5} mt={5.5}>
                      <Typography variant="overline" color="textSecondary">
                        {t('upload.label7')}
                      </Typography>
                    </Box>
                    <File
                      name={projectPlanId.filename}
                      id={projectPlanId.id}
                      type={'project_plan'}
                      deleteFile={deleteFile}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid item xs={12}>
              <Grid container item xs={12} className={classes.sectionButtons}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.buttons}
                  style={{ display: 'flex', flexWrap: 'nowrap' }}
                >
                  <>
                    {!isLargeScreen && (
                      <Grid item>
                        <div style={{ height: 40, width: 40 }}>
                          <NivoDoughnut
                            data={data}
                            center={center}
                            colors={COLORS}
                            smallFont
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      justifyContent={
                        isLargeScreen ? 'space-between' : 'flex-end'
                      }
                      className={classes.specialButtons}
                    >
                      <Button
                        style={{ marginRight: 16 }}
                        startIcon={<KeyboardArrowLeftIcon />}
                        onClick={handleBack}
                      >
                        {t('buttons.back')}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={handleNext}
                      >
                        {t('buttons.next')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={showError}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
      >
        <Alert severity="error">
          {t('dialog.generic_error')}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
            style={{ marginLeft: 10 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>

      {/* LOGOUT */}

      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('logout')}
        text={t('dialog.text2')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
      />
    </>
  );
};

export default UploadTypeInternational;
