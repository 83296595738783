import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Divider,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTableStyles as useStyles } from "./styles";
import i18n from "../../JS/i18n";
import { useMediaQuery } from "react-responsive";
import { theme as bdtheme } from "../../JS/BrightDesignTheme";
import { doughnutData } from "../../data/picklistOptions";
import NumberFormat from "react-number-format";
import NivoDoughnut from "../uiComponents/NivoDoughnut";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../Contexts/Onboarding";
import { UserContext } from "../../Contexts/UserContext";
import Loader from "../uiComponents/Loader";
import ConfirmationDialog from "../uiComponents/ConfirmationDialog";
import axios from "axios";

function createData(
  SubsidyId,
  SubsidyName,
  SubsidyAmount,
  successRate,
  SubsidyDescription
) {
  return {
    SubsidyId,
    SubsidyName,
    SubsidyAmount,
    successRate,
    SubsidyDescription,
  };
}

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "SubsidyId",
      width: "0%",
    },
    {
      id: "SubsidyName",
      numeric: false,
      disablePadding: true,
      label: i18n.t("select_subsidies.subsidies"),
      width: "50%",
    },
    {
      id: "SubsidyAmount",
      numeric: true,
      disablePadding: false,
      label: i18n.t("select_subsidies.amount"),
      width: "20%",
    },
    {
      id: "successRate",
      numeric: true,
      disablePadding: false,
      label: i18n.t("select_subsidies.success"),
      width: "20%",
    },
  ];
  const { onSelectAllClick, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all subsidies" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ width: headCell.width, fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? false : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function AccordionHead(props) {
  const { onSelectAllClick, numSelected, rowCount, children } = props;

  return (
    <div style={{ padding: "0px 5px" }}>
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{ "aria-label": "select all subsidies" }}
        style={{ paddingRight: 20 }}
      />
      {children}
    </div>
  );
}

const SubsidiesList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { openDialog, setOpenDialog } = props;
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("success");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const COLORS = [bdtheme.palette.primary.main, "rgba(224, 224, 224, 1)"];
  const data = doughnutData(props.progressStep);
  const {
    newProjectId,
    selectedSubsidies,
    setSelectedSubsidies,
    returningUser,
    setReturningUser,
  } = useContext(OnboardingContext);
  const [userContext] = useContext(UserContext);

  const ROUTES = ["/client/upload", "/client/plan"];

  const [snackbar, setSnackbar] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
    setReturningUser(false);
  };

  const handleNext = () => {
    history.push(ROUTES[1]);
  };
  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.SubsidyId);
      setSelectedSubsidies(newSelecteds);
      return;
    }
    setSelectedSubsidies([]);
  };

  const handleClick = (event, SubsidyId) => {
    const selectedIndex = selectedSubsidies.indexOf(SubsidyId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSubsidies, SubsidyId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSubsidies.slice(1));
    } else if (selectedIndex === selectedSubsidies.length - 1) {
      newSelected = newSelected.concat(selectedSubsidies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSubsidies.slice(0, selectedIndex),
        selectedSubsidies.slice(selectedIndex + 1)
      );
    }
    setSelectedSubsidies(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (SubsidyId) => selectedSubsidies.indexOf(SubsidyId) !== -1;

  useEffect(() => {
    setLoading(true);
    let data = [];
    var requestBody = {
      ProjectId: newProjectId,
    };
    var apiEndPoint =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: "post",
      url: apiEndPoint + "/projectscan/showPotential",
      data: requestBody,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.subsidies.forEach((item, i) => {
          data.push(
            createData(
              item.SubsidyId,
              item.SubsidyName,
              item.SubsidyAmount,
              item.successRate,
              item.SubsidyDescription
                ? item.SubsidyDescription.replace(/(<([^>]+)>)/gi, "    ")
                : ""
            )
          );
        });
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    setSnackbar(returningUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div
        className={classes.root}
        style={{ display: "flex", alignContent: "center" }}
      >
        <Grid container className={classes.container}>
          <Grid container item xs={12} className={classes.header}>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="h6"
              >
                {t("select_subsidies.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                variant="body2"
                id="tableSubtitle"
              >
                {t("select_subsidies.subtitle")}
              </Typography>
              <Typography
                className={classes.total}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selectedSubsidies.length} {t("select_subsidies.selected")}
              </Typography>
            </Grid>
          </Grid>
          {/* DESKTOP */}
          {!isSmallScreen && (
            <Grid
              item
              xs={12}
              className={classes.content}
              style={rows.length <= 10 ? { borderBottom: "none" } : {}}
            >
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selectedSubsidies.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.SubsidyId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, row.SubsidyId)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.SubsidyId}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Tooltip
                                classes={{
                                  tooltip: classes.customWidthTooltip,
                                  popper: classes.customPositionTooltip,
                                }}
                                title={
                                  <div>
                                    {row.SubsidyDescription
                                      ? row.SubsidyDescription.substring(
                                          3,
                                          row.SubsidyDescription.length - 4
                                        )
                                      : "No description available"}
                                  </div>
                                }
                                placement="top-start"
                              >
                                <Typography className={classes.subsidyTitle}>
                                  {row.SubsidyName}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 16 }}>
                              {row.SubsidyAmount > 0 && (
                                <NumberFormat
                                  value={row.SubsidyAmount}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  isNumericString
                                  prefix={"€"}
                                />
                              )}
                              {row.SubsidyAmount <= 0 && " - "}
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 16 }}>
                              {(row.successRate <= 0 ||
                                row.successRate == null) &&
                                " - "}
                              {row.successRate > 0 &&
                                row.successRate &&
                                row.successRate + "%"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows.length > 10 && (
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          )}
          {/* MOBILE */}
          {isSmallScreen && (
            <Grid
              item
              xs={12}
              className={classes.contentMobile}
              style={{ marginBottom: 86 }}
            >
              <AccordionHead
                classes={classes}
                numSelected={selectedSubsidies.length}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              >
                {t("select_subsidies.subsidies")}
              </AccordionHead>
              {rows.map((row, index) => {
                let isItemSelected = isSelected(row.SubsidyId);
                return (
                  <Accordion
                    expanded={expanded === "panel" + index}
                    onChange={handleExpanded("panel" + index)}
                    style={{ border: "none", boxShadow: "none" }}
                    key={row.SubsidyId}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: bdtheme.palette.primary.main,
                          }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <FormControlLabel
                        aria-label="panel1bh-content"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClick(event, row.SubsidyId);
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={isItemSelected}
                            style={{ paddingRight: 20 }}
                          />
                        }
                        label={
                          <>
                            <Typography
                              className={classes.subsidyTitle}
                              variant="body1"
                              style={
                                row.SubsidyAmount > 0 || row.successRate > 0
                                  ? { marginBottom: 8 }
                                  : {}
                              }
                            >
                              {row.SubsidyName}
                            </Typography>

                            {row.SubsidyAmount > 0 && (
                              <>
                                <Typography component="span" variant="body2">
                                  {t("select_subsidies.amount")} :
                                </Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {" "}
                                  <NumberFormat
                                    value={row.SubsidyAmount}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    isNumericString
                                    prefix={"€"}
                                  />
                                </Typography>
                                {row.successRate > 0 && <br />}
                              </>
                            )}
                            {row.successRate > 0 && (
                              <>
                                <Typography component="span" variant="body2">
                                  {t("select_subsidies.success")} :
                                </Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {" "}
                                  {row.successRate && row.successRate + "%"}
                                </Typography>
                              </>
                            )}
                          </>
                        }
                        style={{
                          alignItems: "flex-start",
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: 46 }}></div>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ width: "100%" }}
                        >
                          {row.SubsidyDescription
                            ? row.SubsidyDescription.substring(
                                3,
                                row.SubsidyDescription.length - 4
                              )
                            : "No description available"}
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          )}
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justify="center"
            className={classes.section + " " + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Box mt={!isSmallScreen ? 6 : 0}>
                  <Divider />
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justify={isLargeScreen ? "space-between" : "flex-end"}
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t("buttons.back")}
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                      disabled={!selectedSubsidies.length}
                      onClick={handleNext}
                    >
                      {t("buttons.next")}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
          {/* here container */}
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackbar}
          autoHideDuration={1500}
          onClose={handleCloseSnackbar}
          message={t("choose_project.welcome_back_message")}
        />

        {/* LOGOUT */}

        <ConfirmationDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          title={t("logout")}
          text={t("dialog.text2")}
          cancel={t("buttons.close")}
          confirm={t("buttons.continue")}
        />
      </div>
    </>
  );
};

export default SubsidiesList;
