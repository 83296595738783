import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { DashboardContext } from '../../Contexts/DashboardContext';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Divider,
  Snackbar,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useMediaQuery } from 'react-responsive';
import NumberFormat from 'react-number-format';
import { ReactComponent as OnlineResearch } from '../../assets/OnlineresearchTwoColor.svg';

import {
  rdHoursOptions,
  rdCostOptions,
  focusOptionss,
  industryOptionsEn,
  industryOptionsNl,
  newProductOptionss,
  rdActivitiesOptionss,
  countryList,
  internationalActivitiesEn,
  internationalActivitiesNl,
  activitiesBussinessEn,
  themeOptionsEn,
  targetOptionsEn,
  activitiesBussinessNl,
  themeOptionsNl,
  targetOptionsNl,
  trlOptions,
} from '../../data/projectOptions';
import { mkbOptionss } from '../../data/picklistOptions';
import i18n from 'i18next';

const ProjectX = (props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const { projectEditSuccess, setProjectEditSuccess } =
    useContext(DashboardContext);
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const focusOptions = focusOptionss();
  const newProductOptions = newProductOptionss();
  const rdActivitiesOptions = rdActivitiesOptionss();
  const yesNoOptions = mkbOptionss();
  const industryOptions =
    i18n.languages[0] === 'nl' ? industryOptionsNl : industryOptionsEn;
  const internationalActivities =
    i18n.languages[0] === 'nl'
      ? internationalActivitiesNl
      : internationalActivitiesEn;
  const activitiesBusiness =
    i18n.languages[0] === 'nl' ? activitiesBussinessNl : activitiesBussinessEn;
  const themeOptions =
    i18n.languages[0] === 'nl' ? themeOptionsNl : themeOptionsEn;
  const targetOptions =
    i18n.languages[0] === 'nl' ? targetOptionsNl : targetOptionsEn;
  const [project, setProject] = useState({});
  const [industries, setIndustries] = useState('');
  const [focus, setFocus] = useState('');
  const [newness, setNewness] = useState('');
  const [activitiesRD, setActivitiesRD] = useState('');
  const [countriesActive, setCountriesActive] = useState('');
  const [countriesExpand, setCountriesExpand] = useState('');
  const [target, setTarget] = useState('');
  const [themes, setThemes] = useState('');
  const [employees1, setEmployees1] = useState('');
  const [employees2, setEmployees2] = useState('');
  const [employees3, setEmployees3] = useState('');
  const [employees4, setEmployees4] = useState('');
  const [whatActivities, setWhatActivities] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setProjectEditSuccess(false);
  };

  const labelTypes = {
    '0123X000001GJUGQA4': t('choose_project.type1'),
    '0123X000001GJUCQA4': t('choose_project.type2'),
    '0123X000001GJUDQA4': t('choose_project.type3'),
    '0123X000001GJUEQA4': t('choose_project.type4'),
    '0123X000001GJUFQA4': t('choose_project.type5'),
  };

  // TRANSLATE VALUES
  useEffect(() => {
    let ind = [];
    let foc = [];
    let news = [];
    let act = [];
    let countriesA = [];
    let countriesE = [];
    let theme = [];
    let targ = [];
    let actInt = [];
    let actBiz = [];
    //industryOptions
    if (project && project.Industry_Sector__c) {
      let values = project.Industry_Sector__c.split(';');
      values.forEach((val) => {
        Object.values(industryOptions).forEach((item) => {
          if (val === item.value) {
            ind.push(item.label);
          }
        });
      });
    }
    //focusOptions
    if (project && project.Project_Focus__c) {
      let values = project.Project_Focus__c.split(';');
      values.forEach((val) => {
        Object.values(focusOptions).forEach((item) => {
          if (val === item.value) {
            foc.push(item.label);
          }
        });
      });
    }
    //newProductOptions
    if (project && project.Newness__c) {
      let values = project.Newness__c.split(';');
      values.forEach((val) => {
        Object.values(newProductOptions).forEach((item) => {
          if (val === item.value) {
            news.push(item.label);
          }
        });
      });
    }
    //rdActivitiesOptions
    if (project && project.Activities_carried_out_by__c) {
      let values = project.Activities_carried_out_by__c.split(';');
      values.forEach((val) => {
        Object.values(rdActivitiesOptions).forEach((item) => {
          if (val === item.value) {
            act.push(item.label);
          }
        });
      });
    }
    //countryList
    if (project && project.Where_are_you_internationally_active__c) {
      let values = project.Where_are_you_internationally_active__c.split(';');
      values.forEach((val) => {
        Object.values(countryList).forEach((item) => {
          if (val === item.value) {
            countriesA.push(item.label);
          }
        });
      });
    }
    //countryList
    if (project && project.Where_countries_do_you_plan_to_expand__c) {
      let values = project.Where_countries_do_you_plan_to_expand__c.split(';');
      values.forEach((val) => {
        Object.values(countryList).forEach((item) => {
          if (val === item.value) {
            countriesE.push(item.label);
          }
        });
      });
    }
    //themeOptions
    if (project && project.Project_themes__c) {
      let values = project.Project_themes__c.split(';');
      values.forEach((val) => {
        Object.values(themeOptions).forEach((item) => {
          if (val === item.value) {
            theme.push(item.label);
          }
        });
      });
    }
    //targetOptions
    if (project && project.Project_target_group__c) {
      let values = project.Project_target_group__c.split(';');
      values.forEach((val) => {
        Object.values(targetOptions).forEach((item) => {
          if (val === item.value) {
            targ.push(item.label);
          }
        });
      });
    }
    //employees yesNoOptions
    if (project && project.Planning_to_hire_workers_at_disadvantage__c) {
      let value = project.Planning_to_hire_workers_at_disadvantage__c;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees4(item.label);
        }
      });
    }
    if (project && project.Hired_workers_at_disadvatage__c) {
      let value = project.Hired_workers_at_disadvatage__c;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees3(item.label);
        }
      });
    }
    if (project && project.Invest_in_Employees__c) {
      let value = project.Invest_in_Employees__c;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees2(item.label);
        }
      });
    }
    if (project && project.Learning_Work_Programs__c) {
      let value = project.Learning_Work_Programs__c;
      Object.values(yesNoOptions).forEach((item) => {
        if (value === item.value) {
          setEmployees1(item.label);
        }
      });
    }
    //project.What_activities__c
    if (project && project.What_activities__c) {
      let values = project.What_activities__c.split(';');
      if (project.RecordTypeId === '0123X000001GJUCQA4') {
        values.forEach((val) => {
          Object.values(internationalActivities).forEach((item) => {
            if (val === item.value) {
              actInt.push(item.label);
            }
          });
        });
        setWhatActivities(actInt.join(', '));
      }
      if (project && project.RecordTypeId === '0123X000001GJUDQA4') {
        values.forEach((val) => {
          Object.values(activitiesBusiness).forEach((item) => {
            if (val === item.value) {
              actBiz.push(item.label);
            }
          });
        });
        setWhatActivities(actBiz.join(', '));
      }
    }

    setIndustries(ind.join(', '));
    setFocus(foc.join(', '));
    setNewness(news.join(', '));
    setActivitiesRD(act.join(', '));
    setCountriesExpand(countriesE.join(', '));
    setCountriesActive(countriesA.join(', '));
    setThemes(theme.join(', '));
    setTarget(targ.join(', '));

    if (project) {
      let name = project.Project_Name__c;
      window.document.title = t('page_title.projectX', { name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1,
    );
    var requestBody = {
      SalesforceId: id,
    };
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/project/getProject',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setProject(res.data.project);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    if (location.state) {
      let name = location.state.name;
      window.document.title = t('page_title.projectX', { name });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userContext]);

  const topPageRef = React.useRef();

  const CardRow = (props) => {
    const { label, value, onClick, type } = props;

    return (
      <>
        <Grid container item xs={11}>
          <Grid item xs={12} md={4} style={{ paddingRight: 16 }}>
            <Typography
              variant="overline"
              color="textSecondary"
              style={{ display: 'content' }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid
            wrap="nowrap"
            justifyContent="space-between"
            container
            item
            xs={12}
            md={8}
            style={{ paddingRight: 16 }}
          >
            {type === 'formattedNumber' ? (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix={'€'}
              />
            ) : (
              <Tooltip title={<div>{value}</div>} placement="top-start">
                <Typography noWrap={isLargeScreen}>{value}</Typography>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={1}
          alignContent="center"
          justifyContent="flex-end"
        >
          <IconButton color="primary" size="small" onClick={onClick}>
            <EditIcon />
          </IconButton>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      {project && (
        <div
          className={classes.root + ' ' + classes.projectX}
          style={{ position: 'relative' }}
        >
          <div
            ref={topPageRef}
            id="anchor-div"
            style={{ position: 'absolute', top: '-120px', left: '0' }}
          ></div>

          <Grid container justifyContent="center">
            <Grid container item xs={12} className={classes.container}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="h6" align="center">
                    {t('projectX.title')}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography align="center" color="textSecondary">
                    {t('projectX.subtitle')}
                  </Typography>
                </Box>
              </Grid>
              {/*1*/}
              <Grid container item xs={12} className={classes.box}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant="outlined"
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid
                          alignContent="center"
                          container
                          item
                          xs={12}
                          md={isLargeScreen ? 7 : 6}
                        >
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography>
                                {t('projectX.card_title')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography variant="body2" color="textSecondary">
                                {t('projectX.card_subtitle')}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                          <OnlineResearch
                            style={{
                              width: isLargeScreen ? 'auto' : '100%',
                              float: 'right',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/*2*/}
              <Grid container item xs={12} className={classes.box}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant="outlined"
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography
                              component="h6"
                              variant="h6"
                              className={classes.boxCardHeader}
                            >
                              {t('project-details.project')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <Grid container item xs={12} md={11}>
                            <Grid item xs={12} md={4}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                {t('projectX.type')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Typography color="textSecondary">
                                {labelTypes[project.RecordTypeId]}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item md={1}></Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label1')}
                            value={project.Project_Name__c || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/client/app/projects/${project.Id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.Id,
                                  label: t('projectX.label1'),
                                  value: project.Project_Name__c,
                                  typeInput: 'textfield',
                                  name: 'Project_Name__c',
                                  rows: '',
                                  multiline: false,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label2')}
                            value={project.Idea__c || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/client/app/projects/${project.Id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.Id,
                                  label: t('projectX.label2'),
                                  value: project.Idea__c,
                                  secondValue: '',
                                  typeInput: 'textfield',
                                  name: 'Idea__c',
                                  rows: '5',
                                  multiline: true,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid container item xs={12} className={classes.mTop12}>
                          <CardRow
                            label={t('projectX.label3')}
                            value={project.Problem_Statement__c || ''}
                            onClick={() => {
                              history.push({
                                pathname: `/client/app/projects/${project.Id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.Id,
                                  label: t('projectX.label3'),
                                  value: project.Problem_Statement__c,
                                  secondValue: '',
                                  typeInput: 'textfield',
                                  name: 'Problem_Statement__c',
                                  rows: '5',
                                  multiline: true,
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* 3 PERIODE */}
              {project.RecordTypeId !== '0123X000001GJUEQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant="outlined"
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.period')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            {project.RecordTypeId === '0123X000001GJUDQA4' ? (
                              <CardRow
                                label={t('projectX.label29')}
                                value={
                                  project.Start_Date__c
                                    ? project.Start_Date__c
                                    : ''
                                }
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label4'),
                                      value: project.Start_Date__c,
                                      secondValue: '',
                                      typeInput: 'datefield',
                                      name: 'Start_Date__c',
                                      rows: '',
                                      multiline: false,
                                      endDate: false,
                                    },
                                  });
                                }}
                              ></CardRow>
                            ) : (
                              <CardRow
                                label={t('projectX.label4')}
                                value={
                                  project.Start_Date__c
                                    ? project.Start_Date__c +
                                      t('projectX.label28') +
                                      project.End_Date__c
                                    : ''
                                }
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label4'),
                                      value: project.Start_Date__c,
                                      secondValue: project.End_Date__c,
                                      typeInput: 'datefield',
                                      name: 'Start_Date__c',
                                      rows: '',
                                      multiline: false,
                                      endDate: true,
                                    },
                                  });
                                }}
                              ></CardRow>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 4 Research & Development */}
              {project.RecordTypeId === '0123X000001GJUGQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant="outlined"
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.randd')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label5')}
                              value={
                                project.No_of_R_D_employees_on_the_project__c ||
                                ''
                              }
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label5'),
                                    value:
                                      project.No_of_R_D_employees_on_the_project__c,
                                    secondValue: '',
                                    typeInput: 'textfield',
                                    name: 'No_of_R_D_employees_on_the_project__c',
                                    rows: '',
                                    multiline: false,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label6')}
                              value={project.R_D_Hours_of_the_Project__c || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label6'),
                                    value: project.R_D_Hours_of_the_Project__c,
                                    typeInput: 'selectfield',
                                    name: 'R_D_Hours_of_the_Project__c',
                                    options: rdHoursOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label7')}
                              value={project.R_D_Cost_of_the_Project__c || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label7'),
                                    value: project.R_D_Cost_of_the_Project__c,
                                    typeInput: 'selectfield',
                                    name: 'R_D_Cost_of_the_Project__c',
                                    options: rdCostOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('projectX.label8')}
                              value={activitiesRD || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label8'),
                                    value: project.Activities_carried_out_by__c,
                                    typeInput: 'radiofield',
                                    name: 'Activities_carried_out_by__c',
                                    options: rdActivitiesOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5 Markt */}
              {project.RecordTypeId === '0123X000001GJUGQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant="outlined"
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.market')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label9')}
                              value={industries || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label9'),
                                    selectedOptions: project.Industry_Sector__c,
                                    typeInput: 'autocompletefield',
                                    name: 'Industry_Sector__c',
                                    options: industryOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label10')}
                              value={newness || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label10'),
                                    value: project.Newness__c,
                                    typeInput: 'selectfield',
                                    name: 'Newness__c',
                                    options: newProductOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('focus.focus')}
                              value={focus || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('focus.focus'),
                                    selectedOptions: project.Project_Focus__c,
                                    typeInput: 'checkboxfield',
                                    name: 'Project_Focus__c',
                                    options: focusOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5.B International - Countries */}
              {project.RecordTypeId === '0123X000001GJUCQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant="outlined"
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                className={classes.boxCardHeader}
                              >
                                {t('projectX.label11')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('projectX.label12_1')}
                              value={countriesActive || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label12_1'),
                                    selectedOptions:
                                      project.Where_are_you_internationally_active__c,
                                    typeInput: 'autocompletefield',
                                    name: 'Where_are_you_internationally_active__c',
                                    options: countryList,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('projectX.label13_1')}
                              value={countriesExpand || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('projectX.label13_1'),
                                    selectedOptions:
                                      project.Where_countries_do_you_plan_to_expand__c,
                                    typeInput: 'autocompletefield',
                                    name: 'Where_countries_do_you_plan_to_expand__c',
                                    options: countryList,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* 5.D  employees activities */}
              {project.RecordTypeId === '0123X000001GJUEQA4' && (
                <>
                  <Grid container item xs={12} className={classes.box}>
                    <Grid item xs={12}>
                      <Card
                        className={classes.card}
                        variant="outlined"
                        style={(theme.custom.boxshadow, { marginRightt: 24 })}
                      >
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box mb={1}>
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  className={classes.boxCardHeader}
                                >
                                  {t('projectX.label20')}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label14')}
                                value={employees1 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label13'),
                                      value:
                                        project.Where_countries_do_you_plan_to_expand__c,
                                      typeInput: 'radiofield',
                                      name: 'Where_countries_do_you_plan_to_expand__c',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label15')}
                                value={employees2 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label15'),
                                      value: project.Invest_in_Employees__c,
                                      typeInput: 'radiofield',
                                      name: 'Invest_in_Employees__c',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('projectX.label16')}
                                value={employees3 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label16'),
                                      value:
                                        project.Hired_workers_at_disadvatage__c,
                                      typeInput: 'radiofield',
                                      name: 'Hired_workers_at_disadvatage__c',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.mTop12}
                            >
                              <CardRow
                                label={t('projectX.label17')}
                                value={employees4 || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('projectX.label17'),
                                      value:
                                        project.Planning_to_hire_workers_at_disadvantage__c,
                                      typeInput: 'radiofield',
                                      name: 'Planning_to_hire_workers_at_disadvantage__c',
                                      options: yesNoOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* 5.E Social */}
              {project.RecordTypeId === '0123X000001GJUFQA4' && (
                <Grid container item xs={12} className={classes.box}>
                  <Grid item xs={12}>
                    <Card
                      className={classes.card}
                      variant="outlined"
                      style={(theme.custom.boxshadow, { marginRightt: 24 })}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography
                                component="h6"
                                variant="h6"
                                className={classes.boxCardHeader}
                              >
                                {t('project-details.themes')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12 + ' ' + classes.mBottom12}
                          >
                            <CardRow
                              label={t('project-details.themes')}
                              value={themes || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('project-details.themes'),
                                    selectedOptions: project.Project_themes__c,
                                    typeInput: 'autocompletefield',
                                    name: 'Project_themes__c',
                                    options: themeOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.mTop12}
                          >
                            <CardRow
                              label={t('project-details.target')}
                              value={target || ''}
                              onClick={() => {
                                history.push({
                                  pathname: `/client/app/projects/${project.Id}/edit`,
                                  state: {
                                    project: project,
                                    selectedId: project.Id,
                                    label: t('project-details.target'),
                                    selectedOptions:
                                      project.Project_target_group__c,
                                    typeInput: 'autocompletefield',
                                    name: 'Project_target_group__c',
                                    options: targetOptions,
                                  },
                                });
                              }}
                            ></CardRow>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/*6*/}
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant="outlined"
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography
                              component="h6"
                              variant="h6"
                              className={classes.boxCardHeader}
                            >
                              {t('project-details.subsidie')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.mTop12 + ' ' + classes.mBottom12}
                        >
                          <CardRow
                            label={t('projectX.label18')}
                            value={project.Subsidy_needed__c || ''}
                            type="formattedNumber"
                            onClick={() => {
                              history.push({
                                pathname: `/client/app/projects/${project.Id}/edit`,
                                state: {
                                  project: project,
                                  selectedId: project.Id,
                                  label: t('projectX.label18'),
                                  value: project.Subsidy_needed__c,
                                  typeInput: 'textfield',
                                  name: 'Subsidy_needed__c',
                                  multiline: false,
                                  rows: '',
                                },
                              });
                            }}
                          ></CardRow>
                        </Grid>
                        {/* 7 TRL */}
                        {project.RecordTypeId === '0123X000001GJUGQA4' && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={
                                classes.mTop12 + ' ' + classes.mBottom12
                              }
                            >
                              <CardRow
                                label={t('labels.pre')}
                                value={project.TRL_Level__c || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('labels.pre'),
                                      value: project.TRL_Level__c,
                                      typeInput: 'selectfield',
                                      name: 'TRL_Level__c',
                                      options: trlOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.mTop12}
                            >
                              <CardRow
                                label={t('labels.post')}
                                value={project.Post_TRL__c || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('labels.post'),
                                      value: project.Post_TRL__c,
                                      typeInput: 'selectfield',
                                      name: 'Post_TRL__c',
                                      options: trlOptions,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                        {/* 8 International - Activities - Business */}
                        {(project.RecordTypeId === '0123X000001GJUCQA4' ||
                          project.RecordTypeId === '0123X000001GJUDQA4') && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.mTop12}
                            >
                              <CardRow
                                label={t('projectX.label19')}
                                value={whatActivities || ''}
                                onClick={() => {
                                  history.push({
                                    pathname: `/client/app/projects/${project.Id}/edit`,
                                    state: {
                                      project: project,
                                      selectedId: project.Id,
                                      label: t('project-details.themes'),
                                      selectedOptions:
                                        project.What_activities__c,
                                      typeInput: 'autocompletefield',
                                      name: 'What_activities__c',
                                      options:
                                        project.RecordTypeId ===
                                        '0123X000001GJUCQA4'
                                          ? internationalActivities
                                          : activitiesBusiness,
                                    },
                                  });
                                }}
                              ></CardRow>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={projectEditSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={t('dialog.edit_success')}
      />
    </>
  );
};

export default withRouter(ProjectX);
