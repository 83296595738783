import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Chip,
  Box,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { regUppercase, regDigit, regSpecialSign } from '../../JS/FormControls';

const ResetPasswordDetails = ({ password, setPassword, handleReset }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  React.useEffect(() => {
    const regPassword = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    setDisabled(!password.match(regPassword));
  }, [password]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.formContainer}
        alignItems="center"
      >
        <Grid container item xs={12}>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: 24 }}>
              <Typography color="textPrimary" variant="h6">
                {t('forgot_password.reset_password')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  padding: '24px 24px 0px 24px',
                  boxShadow: '0px 0px 0px 1px #E0E0E0',
                  borderRadius: 4,
                }}
              >
                <Grid item xs={12}>
                  <Box my={2}>
                    <TextField
                      id="password"
                      name="password"
                      label={t('forgot_password.new_password')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      autoComplete="password"
                      autoFocus={isLargeScreen}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.chipsContainer + ' ' + classes.formControl}
                >
                  <Grid container item xs={12} style={{ marginBottom: 12 }}>
                    <Chip
                      style={{ marginRight: 8, marginBottom: 8 }}
                      label={'8 ' + t('make_account.characters')}
                      icon={
                        password && password.length > 7 ? (
                          <DoneIcon color="secondary" className="icon-done" />
                        ) : password.length === 0 ? null : (
                          <ErrorOutlineIcon
                            color="error"
                            className="icon-error"
                          />
                        )
                      }
                    />
                    <Chip
                      style={{ marginRight: 8 }}
                      label={t('make_account.capital_letter')}
                      icon={
                        password && password.length > 0 ? (
                          regUppercase.test(password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                    <Chip
                      style={{ marginRight: 8 }}
                      label={t('make_account.digit')}
                      icon={
                        password && password.length > 0 ? (
                          regDigit.test(password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                    <Chip
                      style={{ marginBottom: 8 }}
                      label={t('make_account.special_sign')}
                      icon={
                        password && password.length > 0 ? (
                          regSpecialSign.test(password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '24px 0' }}
            >
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleReset}
                  disabled={disabled}
                >
                  {t('forgot_password.confirm')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPasswordDetails;
