import React, { useEffect, useCallback, useContext } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import EuroIcon from '@material-ui/icons/Euro';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { ReactComponent as DashboardGroup } from '../../assets/dashboardGroup.svg';
import { OnboardingContext } from '../../Contexts/Onboarding';
import { DashboardContext } from '../../Contexts/DashboardContext';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import NumberFormat from 'react-number-format';

const DashboardDetails = () => {
  const [userContext] = useContext(UserContext);
  const { newProjectSalesforceId, showWelcomeNewUser, setShowWelcomeNewUser } =
    useContext(OnboardingContext);
  const {
    newProjectAdded,
    setNewProjectAdded,
    projectAddedId,
    setNewProjectPathname,
  } = useContext(DashboardContext);
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [numberOfProjects, setNumberOfProjects] = React.useState(0);
  const [numberOfApplications, setNumberOfApplications] = React.useState(0);
  const [numberOfGrantedApplications, setNumberOfGrantedApplications] =
    React.useState(0);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowWelcomeNewUser(false);
    setNewProjectAdded(false);
  };

  const getProjects = useCallback(() => {
    setLoading(true);
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/project/getAllProjects',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((response) => {
        setNumberOfProjects(response.data.projects.length);
        axios({
          method: 'get',
          url: apiEndPoint + '/dashboard/getApplications',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        })
          .then((response) => {
            setLoading(false);
            setNumberOfApplications(response.data.applications);
            setNumberOfGrantedApplications(response.data.grantedApplications);
          })
          .catch((err) => setLoading(false));
      })
      .catch((err) => setLoading(false));
  }, [userContext]);

  useEffect(() => {
    getProjects();
    window.document.title = t('page_title.dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        <Grid
          container
          item
          justifyContent="center"
          alignContent="center"
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent={isLargeScreen ? 'space-between' : 'center'}
            className={classes.container}
          >
            <Grid item xs={12} md={6} className={classes.cardBox}>
              <Box mt={4} style={{ paddingBottom: 8 }}>
                <Typography
                  component="h4"
                  variant="h4"
                  align={isSmallScreen ? 'center' : 'center'}
                >
                  {t('dashboard.welcome_title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align={isSmallScreen ? 'center' : 'center'}
                >
                  {t('dashboard.welcome_subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} md={6} className={classes.cardBox}>
              {!isSmallScreen && (
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  style={{
                    paddingBottom: 56,
                    minWidth: 398,
                  }}
                >
                  <DashboardGroup />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.cardBox}>
              <Card
                className={classes.card}
                variant="outlined"
                style={(theme.custom.boxshadow, { marginRightt: 24 })}
              >
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  ></Typography>
                  <Grid container className={classes.rowContainer}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <PublishIcon className={classes.filledIconPrimary} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        {t('projects.title')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.rowContainer}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <PublishIcon color="primary" />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.pos}>
                        {t('projects.title')}: {numberOfProjects}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <EuroIcon color="primary" />
                    </Grid>
                    <Grid item>
                      <Typography component="p">
                        {t('subsidies.potential')}:{' '}
                        <NumberFormat
                          value={userContext.user.subsidyPotential}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          isNumericString
                          prefix={'€'}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => history.push('/client/app/projects')}
                  >
                    {t('dashboard.all_projects')}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon color="primary" />}
                    onClick={() => {
                      setNewProjectPathname('dashboard');
                      history.push('/client/newproject/choose');
                    }}
                  >
                    {t('projects.card_new')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} className={classes.cardBox}>
              <Card
                className={classes.card}
                variant="outlined"
                style={(theme.custom.boxshadow, { marginLeft: 'auto' })}
              >
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  ></Typography>
                  <Grid container className={classes.rowContainer}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <CompareArrowsIcon
                        className={classes.filledIconWarning}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        {t('dashboard.applications')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.rowContainer}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <CompareArrowsIcon
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.pos}>
                        {t('dashboard.applications')}: {numberOfApplications}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      className={classes.iconContainer}
                    >
                      <CheckIcon
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography component="p">
                        {t('dashboard.toekenning')}:{' '}
                        {numberOfGrantedApplications}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => history.push('/client/app/applications')}
                  >
                    {t('dashboard.my_applications')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={newProjectAdded}
        onClose={handleCloseSnackbar}
        message={
          <>
            <Grid container wrap="nowrap">
              <Grid item>{t('dialog.new_project_placed')}</Grid>
              <Grid
                item
                style={{
                  paddingLeft: 16,
                }}
              >
                <Link
                  to={`/client/app/projects/${projectAddedId}`}
                  onClick={(e) => {
                    setNewProjectAdded(false);
                    handleCloseSnackbar();
                  }}
                  style={{
                    color: theme.palette.secondary.main,
                    float: 'right',
                  }}
                >
                  {t('dialog.view')}
                </Link>
              </Grid>
            </Grid>
          </>
        }
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
              style={{ paddingTop: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showWelcomeNewUser}
        onClose={handleCloseSnackbar}
        message={
          <>
            <Grid container wrap="nowrap">
              <Grid item>{t('dialog.new_project_placed')}</Grid>
              <Grid
                item
                style={{
                  paddingLeft: 16,
                }}
              >
                <Link
                  to={`/client/app/projects/${newProjectSalesforceId}`}
                  onClick={(e) => {
                    setNewProjectAdded(false);
                    handleCloseSnackbar();
                  }}
                  style={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  {t('dialog.view')}
                </Link>
              </Grid>
            </Grid>
          </>
        }
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
              style={{ paddingTop: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default withRouter(DashboardDetails);
