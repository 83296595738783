import React from 'react';
import { Grid } from '@material-ui/core';
import BorderLinearProgress from './BorderLinearProgress';

const PreviewUploadFile = ({ meta }) => {
  const { name } = meta;
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 24, height: '100%', flex: 1 }}
      >
        <Grid item xs={6} style={{ paddingRight: 24 }}>
          <span
            style={{
              alignSelf: 'flex-start',
              margin: '10px 3%',
              wordBreak: 'break-all',
            }}
          >
            {name}
          </span>
        </Grid>
        <Grid item xs={5}>
          <BorderLinearProgress />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
};

export default PreviewUploadFile;
