import React, { useState, useContext, useEffect } from 'react';
import { useStyles } from '../Components/Login/styles';
import { UserContext } from '../Contexts/UserContext';
import { withRouter } from 'react-router-dom';
import BaseLayout from '../Layouts/BaseLayout';
import { Grid, Snackbar, IconButton } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import LoginSideComponent from '../Components/Login/LoginSideComponent';
import LoginDetails from '../Components/Login/LoginDetails';
import Loader from '../Components/uiComponents/Loader';
import { OnboardingContext } from '../Contexts/Onboarding';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const Login = ({ history }) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  // eslint-disable-next-line no-unused-vars
  const [userContext, setUserContext] = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    passReset,
    setPassReset,
    setNewProjectId,
    setSelectedSubsidies,
    setNewProjectSalesforceId,
    setReturningUser,
  } = useContext(OnboardingContext);
  const { t } = useTranslation();
  const [showError, setShowError] = React.useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
    setPassReset(false);
  };

  useEffect(() => {
    window.document.title = t('page_title.login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid container justifyContent="center" className={classes.root}>
              <Grid
                container
                item
                xs={12}
                alignContent="center"
                wrap="nowrap"
                style={{ maxHeight: 'calc(100vh - 72px)', overflowY: 'auto' }}
              >
                {isLargeScreen && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{
                      height: '100%',
                      width: 'max-content',
                    }}
                  >
                    <LoginSideComponent />
                  </Grid>
                )}
                <Grid container item justifyContent="center">
                  <LoginDetails
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    handleLogin={handleLogin}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              <Alert
                severity="error"
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('login.error')}
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: '10px',
                    top: '12px',
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Alert>
            </Snackbar>

            <Snackbar
              open={passReset}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
              className={classes.closeAlert}
            >
              <Alert
                onClose={() => {
                  setPassReset(false);
                }}
                severity="success"
              >
                {t('forgot_password.success_1')}
              </Alert>
            </Snackbar>
          </>
        }
      ></BaseLayout>
    </>
  );
  function handleLogin(e) {
    setLoading(true);
    e.preventDefault();
    var requestBody = {
      username: email,
      password: password,
    };
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/users/login',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data;
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', data.user);
          localStorage.setItem('firstProjectId', data.user.firstProjectId);
          setNewProjectId(data.user.firstProjectId);
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token, user: data.user };
          });
          setLoading(false);
          if (data.user.onboardingCompleted) {
            history.push('/client/app/dashboard');
          } else if (
            !data.user.onboardingCompleted &&
            data.user.signingStatus === 'Signed'
          ) {
            setNewProjectSalesforceId(data.user.firstProjectSalesforceId);
            setSelectedSubsidies(['dummy-subsidy']);
            history.push('/client/checkout');
          } else if (
            !data.user.onboardingCompleted &&
            data.user.signingStatus === 'Sent'
          ) {
            setNewProjectSalesforceId(data.user.firstProjectSalesforceId);
            setSelectedSubsidies(['dummy-subsidy']);
            history.push('/client/checkout');
          } else if (
            !data.user.onboardingCompleted &&
            data.user.firstProjectCreated &&
            data.user.firstProjectId
          ) {
            setNewProjectSalesforceId(data.user.firstProjectSalesforceId);
            setReturningUser(true);
            history.push('/client/selectsubsidies');
          } else {
            setReturningUser(true);
            history.push('/client/choose');
          }
        } else {
          setShowError(true);
          setLoading(false);
          setUserContext((oldValues) => {
            return { ...oldValues, token: null, user: null };
          });
        }
      })
      .catch((error) => {
        setShowError(true);
        setLoading(false);
      });
  }
};

export default withRouter(Login);
