import i18n from '../JS/i18n';

export const MenuItemss = () => {
  return [
    {
      href: '/client/app/dashboard',
      icon: 'DashboardIcon',
      title: i18n.t('dashboard.menu.navitem1'),
    },
    {
      href: '/client/app/projects',
      icon: 'PublishIcon',
      title: i18n.t('dashboard.menu.navitem2'),
    },
    {
      href: '/client/app/applications',
      icon: 'CompareArrowsIcon',
      title: i18n.t('dashboard.menu.navitem3'),
    },
  ];
};

export const UserItemss = () => {
  return [
    {
      id: 'account',
      href: '/client/app/account',
      icon: 'AccountCircleIcon',
      title: i18n.t('dashboard.my_account'),
    },
    {
      id: 'logout',
      icon: 'DashboardIcon',
      title: i18n.t('logout'),
    },
  ];
};
