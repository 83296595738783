/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import { useMediaQuery } from 'react-responsive';
import ProjectTypeProduct from '../Components/Onboarding/ProjectTypeProduct';
import ProjectTypeInternational from '../Components/Onboarding/ProjectTypeInternational';
import ProjectTypeInvestBusiness from '../Components/Onboarding/ProjectTypeInvestBusiness';
import ProjectTypeInvestEmployees from '../Components/Onboarding/ProjectTypeInvestEmployees';
import ProjectTypeSocial from '../Components/Onboarding/ProjectTypeSocial';
import ProjectSidebar from '../Components/Onboarding/ProjectSidebar';
import { ReactComponent as SupportAgent } from '../assets/support_agent_black_36dp.svg';
import { OnboardingContext } from '../Contexts/Onboarding';

import {
  focusOptionss,
  industryOptionsEn,
  industryOptionsNl,
  trlOptions,
  rdHoursOptions,
  rdCostOptions,
  newProductOptionss,
  rdActivitiesOptionss,
  countryList,
  internationalActivitiesEn,
  internationalActivitiesNl,
  activitiesBussinessEn,
  themeOptionsEn,
  targetOptionsEn,
  activitiesBussinessNl,
  themeOptionsNl,
  targetOptionsNl,
  yesNoOptions,
} from '../data/projectOptions';
import i18n from 'i18next';
import { useTheme } from '@material-ui/core/styles';

const ProjectDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [progressStep, setProgressStep] = useState(8);
  const [projectProgress, setProjectProgress] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const focusOptions = focusOptionss();
  const newProductOptions = newProductOptionss();
  const rdActivitiesOptions = rdActivitiesOptionss();

  const { projectType } = useContext(OnboardingContext);
  const center = Math.round((100 / 13) * progressStep);

  useEffect(() => {
    window.document.title = t('page_title.add_project');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === '0123X000001GJUGQA4' && (
              <ProjectTypeProduct
                trlOptions={trlOptions}
                rdHoursOptions={rdHoursOptions}
                rdCostOptions={rdCostOptions}
                rdActivitiesOptions={rdActivitiesOptions}
                industryOptions={
                  i18n.languages[0] === 'nl'
                    ? industryOptionsNl
                    : industryOptionsEn
                }
                newProductOptions={newProductOptions}
                focusOptions={focusOptions}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
              />
            )}
            {projectType === '0123X000001GJUCQA4' && (
              <ProjectTypeInternational
                countryList={countryList}
                internationalActivities={
                  i18n.languages[0] === 'nl'
                    ? internationalActivitiesNl
                    : internationalActivitiesEn
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
              />
            )}
            {projectType === '0123X000001GJUDQA4' && (
              <ProjectTypeInvestBusiness
                activitiesBusiness={
                  i18n.languages[0] === 'nl'
                    ? activitiesBussinessNl
                    : activitiesBussinessEn
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
              />
            )}
            {projectType === '0123X000001GJUEQA4' && (
              <ProjectTypeInvestEmployees
                yesNoOptions={yesNoOptions}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
              />
            )}
            {projectType === '0123X000001GJUFQA4' && (
              <ProjectTypeSocial
                themeOptions={
                  i18n.languages[0] === 'nl' ? themeOptionsNl : themeOptionsEn
                }
                targetOptions={
                  i18n.languages[0] === 'nl' ? targetOptionsNl : targetOptionsEn
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
              />
            )}
          </>
        }
        sidebar={
          <ProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        close={
          <>
            {isLargeScreen && (
              <>
                <SupportAgent
                  style={{
                    paddingRight: 8,
                    fill: theme.palette.secondary.main,
                  }}
                />
                <span>+31 (0)85 580 74 21</span>
              </>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {t('buttons.logout')}
            </Button>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default withRouter(ProjectDetails);
