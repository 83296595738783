import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const CustomDialog = (props) => {
  const { openDialog, setOpenDialog, title, content, confirm, handleConfirm } =
    props;
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleConfirm();
              if (props.handleClose) {
                props.handleClose();
              }
            }}
            color="primary"
          >
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
