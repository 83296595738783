import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as MoneyTree } from '../../assets/MoneytreeTwoColor.svg';
import NumberFormat from 'react-number-format';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import i18n from '../../JS/i18n';
import { useLocation } from 'react-router-dom';

function EnhancedTableHead() {
  const headCells = [
    {
      id: 'SubsidyId',
      width: '0%',
    },
    {
      id: 'SubsidyName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('subsidies.name'),
      width: '50%',
    },
    {
      id: 'SubsidyAmount',
      numeric: false,
      disablePadding: false,
      label: i18n.t('subsidies.potential'),
      width: '25%',
    },
    {
      id: 'SubsidySuccess',
      numeric: false,
      disablePadding: false,
      label: i18n.t('subsidies.success'),
      width: '25%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ width: headCell.width, fontWeight: 'bold' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const SubsidiesList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(false);

  let location = useLocation();

  function createData(
    SubsidyId,
    SubsidyName,
    SubsidyAmount,
    SubsidySuccess,
    SubsidyDescription,
  ) {
    return {
      SubsidyId,
      SubsidyName,
      SubsidyAmount,
      SubsidySuccess,
      SubsidyDescription,
    };
  }

  useEffect(() => {
    setLoading(true);
    let data = [];
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1,
    );
    var requestBody = {
      SalesforceId: id,
    };
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/project/getSubsidies',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.subsidies.forEach((item, i) => {
          data.push(
            createData(
              item.SubsidyId,
              item.SubsidyName,
              item.SubsidyAmount,
              item.successRate,
              item.SubsidyDescription,
            ),
          );
        });
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        className={classes.root}
        style={
          isSmallScreen
            ? { paddingTop: 72, position: 'relative' }
            : { paddingTop: 96, position: 'relative' }
        }
      >
        <div
          ref={topPageRef}
          id="anchor-div"
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>
        <Grid
          container
          item
          justifyContent="center"
          alignContent="flex-start"
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component="h6" variant="h6" align="center">
                  {t('subsidies.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {t('subsidies.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        alignContent="center"
                        container
                        item
                        xs={12}
                        md={isLargeScreen ? 7 : 6}
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('subsidies.card_title')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              {t('subsidies.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                        <MoneyTree
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.box + ' ' + classes.boxLastItem}
            >
              {!isSmallScreen ? (
                <Grid item xs={12}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table className={classes.table} aria-label="a dense table">
                      <EnhancedTableHead />
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.SubsidyId}>
                            <TableCell />
                            <TableCell>
                              <Tooltip
                                title={
                                  row.SubsidyDescription
                                    ? row.SubsidyDescription.replace(
                                        /(<([^>]+)>)/gi,
                                        '    ',
                                      )
                                    : ''
                                }
                                placement="top-start"
                              >
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                    display: 'block',
                                    overflow: 'hidden',
                                    fontSize: 16,
                                  }}
                                >
                                  {row.SubsidyName}
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ fontSize: 16 }}>
                              {row.SubsidyAmount > 0 ? (
                                <NumberFormat
                                  value={row.SubsidyAmount}
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  isNumericString
                                  prefix={'€'}
                                />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell style={{ fontSize: 16 }}>
                              {row.SubsidySuccess > 0
                                ? row.SubsidySuccess + '%'
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.boxTableSubsidies}>
                  {rows.map((row, index) => (
                    <Accordion
                      expanded={expanded === 'panel' + index}
                      onChange={handleExpanded('panel' + index)}
                      style={{
                        boxShadow: 'none',
                      }}
                      key={row.SubsidyId}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {row.SubsidyName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                          color="textSecondary"
                        >
                          {t('labels.description')}
                        </Typography>
                        <Typography style={{ marginBottom: 32 }}>
                          {row.SubsidyDescription
                            ? row.SubsidyDescription.replace(
                                /(<([^>]+)>)/gi,
                                '    ',
                              )
                            : '-'}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                          color="textSecondary"
                        >
                          {t('subsidies.potential')}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          style={{ marginBottom: 32 }}
                        >
                          {row.SubsidyAmount > 0 ? (
                            <NumberFormat
                              value={row.SubsidyAmount}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              isNumericString
                              prefix={'€'}
                            />
                          ) : (
                            '-'
                          )}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                          color="textSecondary"
                        >
                          {t('subsidies.success')}
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                          {row.SubsidySuccess > 0
                            ? row.SubsidySuccess + '%'
                            : '-'}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubsidiesList;
