import React from 'react';
import { withStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const loader = {
  bottom: 0,
  top: 0,
  position: 'fixed',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  zIndex: 9990,
};

const spinner = (theme) => ({
  transitionDelay: '100ms',
  color: theme.palette.primary.main,
});

const Loader = (props) => {
  return (
    <div style={loader}>
      <Backdrop
        open={props.loading}
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
      >
        <Fade in={props.loading} unmountOnExit>
          <CircularProgress disableShrink style={spinner} />
        </Fade>
      </Backdrop>
    </div>
  );
};
export default withStyles(spinner, { withTheme: true })(Loader);
