export function trimFileName(name, noOfChars = 12, ext = "") {
  if (name) {
    const ext = name
      .substring(name.lastIndexOf(".") + 1, name.length)
      .toLowerCase();
    let newName = name.replace("." + ext, "");
    if (name.length <= noOfChars) {
      // if file name length is less than 12, do not format
      // return same name
      return name;
    }
    newName =
      newName.substring(0, noOfChars) + (name.length > noOfChars ? "..." : "");
    return newName + "." + ext;
  }
}

export function cleanProjectBusiness(setActivities, setProjectBusiness) {
  setActivities([]);
  setProjectBusiness({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    accountId: "",
    _id: "",
  });
}
export function cleanProjectInternational(
  setProjectInt,
  setActive,
  setExpand,
  setActivitiesInt
) {
  setActive([]);
  setExpand([]);
  setActivitiesInt([]);
  setProjectInt({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    countriesActive: "",
    countriesExpanding: "",
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    accountId: "",
    _id: "",
  });
}
export function cleanProjectEmployees(setProjectEmployees) {
  setProjectEmployees({
    name: "",
    idea: "",
    problemStatement: "",
    learningWorkPrograms: "",
    investmentInEmployees: "",
    hiredWorkersAtDisadvantage: "",
    willHireWorkersAtDisadvantage: "",
    subsidyNeed: "",
    projectTypeId: "",
    accountId: "",
    _id: "",
  });
}
export function cleanProjectProduct(setProjectProduct, setIndustries) {
  setIndustries([]);
  setProjectProduct({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    noOfRnDEmployees: "",
    rndHours: "",
    rndCosts: "",
    activitiesCarriedOutBy: "",
    industrySector: "",
    newness: "",
    projectFocus: "",
    preTRLLevel: "",
    postTRLLevel: "",
    subsidyNeed: "",
    projectTypeId: "",
    accountId: "",
    _id: "",
  });
}
export function cleanProjectSocial(setProjectSocial, setThemes, setTarget) {
  setThemes([]);
  setTarget([]);
  setProjectSocial({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    projectThemes: "",
    projectTargetGroup: "",
    subsidyNeed: "",
    projectTypeId: "",
    accountId: "",
    _id: "",
  });
}
