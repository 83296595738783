import { lighten, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '64px 24px 40px 24px',
    [theme.breakpoints.down('767')]: {
      padding: '24px 16px 64px 16px',
    },
    '& > div': {
      height: '100%',
      maxWidth: 1080,
    },
  },
  container: {},
  containerNoSubsidies: {
    maxWidth: '450px !important',
  },
  section: {
    maxWidth: 1080,
    [theme.breakpoints.up('3200')]: {
      maxWidth: 1210,
    },
    [theme.breakpoints.up('3840')]: {
      maxWidth: 1450,
    },
    [theme.breakpoints.down('1279')]: {
      maxWidth: 800,
    },
  },
  subSection: {
    alignContent: 'flex-start',
    '& > div': {
      maxWidth: 450,
    },
  },
  rootUpload: {
    [theme.breakpoints.down('360')]: {
      padding: '24px 16px 128px 16px',
    },
  },
  uploadSection: {
    '& > div': {
      [theme.breakpoints.down('767')]: {},
    },
  },
  uploadContainer: {
    marginBottom: 48,
    [theme.breakpoints.down('1279')]: {
      maxWidth: 450,
    },
    [theme.breakpoints.down('767')]: {
      marginBottom: 24,
    },
    '& .dzu-previewStatusContainer progress': {
      color: theme.palette.secondary.main,
    },
  },
  specialButtons: {
    '& button': {
      [theme.breakpoints.down('360')]: {},
    },
  },
  typeSection: {
    '& fieldset': {
      padding: 12,
    },
    [theme.breakpoints.down('1279')]: {
      maxWidth: 450,
      '& fieldset': {
        marginBottom: 0,
      },
    },
  },
  cardBox: {
    '&:first-of-type': {
      padding: '0 12px 12px 0',
    },
    '&:nth-of-type(2)': {
      padding: '0 12px 12px 12px',
    },
    '&:nth-of-type(3)': {
      padding: '0 0 12px 12px',
    },
    '&:nth-of-type(4)': {
      padding: '12px 12px 0 0',
    },
    '&:nth-of-type(5)': {
      padding: '12px 12px 0 12px',
    },
  },
  cardBoxActive: {
    '& .MuiPaper-outlined': {
      borderColor: theme.palette.primary.main,
    },
  },
  title: {
    '& > div': {
      maxWidth: 450,
      [theme.breakpoints.down('1279')]: {
        margin: 'auto',
      },
    },
  },
  titleCheckout: {
    marginBottom: 48,
    [theme.breakpoints.down('1279')]: {
      maxWidth: 450,
    },
  },
  subTitle: {
    display: 'block',
    marginBottom: 16,
  },
  titlePlan: {
    marginBottom: 36,
    [theme.breakpoints.down('1279')]: {
      '& > h6': {
        maxWidth: 450,
        margin: 'auto',
      },
    },
  },
  planContainer: {
    [theme.breakpoints.down('1279')]: {
      '& > div': {
        justifyContent: 'center',
        marginBottom: 24,
      },
    },
  },
  planCardGridItem: {
    [theme.breakpoints.up('1380')]: {
      maxWidth: 'fit-content',
      '&:first-of-type': {
        marginRight: 'auto',
      },
      '&:last-of-type': {
        marginLeft: 'auto',
      },
    },
  },
  planCard: {
    position: 'relative',
    maxWidth: 328,
    boxShadow: 'none',
    borderRadius: '0px 0px 4px 4px',
    '& > div': {
      height: '100%',
    },
    '& .MuiCardContent-root': {
      padding: 0,
      paddingTop: 24,
      width: 328,
    },
    '& .planSection': {},
  },
  planCardActive: {
    borderRadius: '0px 0px 4px 4px',
    background: theme.palette.primary.main,
    borderTop: 0,
    border: 'none',
    '& .MuiTypography-root': {
      color: '#fff',
    },
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: theme.palette.secondary.main,
      content: '""',
      height: 4,
      borderRadius: 0,
    },
  },
  planSection: {
    padding: '0 24px 24px',
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 38,
      marginTop: 4,
      alignSelf: 'flex-start',
    },
  },
  lastSection: {
    marginBottom: 74,
  },
  planButton: {
    position: 'absolute',
    bottom: 0,
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    width: '100%',
    marginBottom: 48,
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('767')]: {
        maxWidth: 'calc(100vw - 32px)',
      },
    },
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  helperText: {
    '& > p': {
      marginLeft: 0,
    },
  },
  sectionMobile: {
    [theme.breakpoints.down('767')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#ffffff',
      zIndex: 999,
    },
  },
  buttons: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.down('1279')]: {
      margin: 'auto',
      maxWidth: 450,
    },
    [theme.breakpoints.down('1279')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {
      padding: '12px 0',
    },
    [theme.breakpoints.down('464')]: {
      padding: '12px 16px',
    },
  },

  button: {
    justifyContent: 'flex-end',
    padding: '24px 0',
    alignItems: 'center',
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('1279')]: {
      justifyContent: 'space-between',
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {
      padding: '12px 0',
    },
    [theme.breakpoints.down('464')]: {
      padding: '12px 16px',
    },
  },
  sectionButtons: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('1279')]: {
      margin: 'auto',
      maxWidth: 450,
    },
  },
  sectionMakeacccount: {},
  progressStepper: {
    display: 'flex',
    bottom: 0,
    width: '100%',
    '& > div': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      background: '#ffffff',
    },
  },
  /*** CHECKOUT ***/
  card: {
    marginBottom: 48,
    '& .MuiCardContent-root': {
      padding: '20px 24px 24px',
    },
  },
  signSide: {
    position: 'relative',
    '& > div:first-of-type ': {
      borderRadius: '4px 4px 0px 0px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  signButton: {
    padding: 14,
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '0px 0px 4px 4px',
    '& .MuiButton-startIcon': {
      marginRight: '8px !important',
    },

    '& button': {
      color: '#ffffff',
    },
    '& .MuiButton-root:hover': {},
  },
  signButtonBase: {
    '& .MuiButton-root': {
      display: 'none',
    },

    padding: 14,
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '0px 0px 4px 4px',

    '& .MuiButton-startIcon': {
      marginRight: '8px !important',
    },
  },
  searchIcon: {
    '& .MuiInputAdornment-root': {
      color: theme.palette.text.disabled,
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0)',
    },
  },
  contractImage: {
    [theme.breakpoints.down('1279')]: {
      maxWidth: '100%',
    },
  },
  divider: {
    marginBottom: 48,
    [theme.breakpoints.down('1279')]: {
      maxWidth: 450,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  autocompleteMultiple: {
    '& .MuiAutocomplete-endAdornment': {
      top: '14px !important',
    },
  },
  cardRoot: {
    padding: theme.spacing(3),
    width: '300px',
    cursor: 'pointer',
    height: '174px !important',
  },
  signDialog: {
    [theme.breakpoints.down('1028')]: {
      width: '100%',
    },
  },
  successDialog: {
    '& .MuiDialog-container > div': {
      height: '90%',
      maxHeight: 952,
      maxWidth: 968,
      width: '100%',
      [theme.breakpoints.down('768')]: {
        height: '100%',
      },
    },
  },
  activeIconState: {
    '&:hover': {
      color: theme.palette.primary.main + ' !important',
    },
  },
}));

export const useSidebarStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 72px)',
    paddingRight: 24,
    background: '#FAFAFA',
    boxShadow: '-1px 0px 0px #EEEEEE',
    position: 'fixed',
    top: 72,
    bottom: 0,
    right: 0,
    width: '25%',
  },

  chartContainer: {
    width: '132px',
    height: '132px',
    margin: '64px auto 0',
  },
  stepperContainer: {
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      //color: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    '& .MuiStepLabel-label:not(.MuiStepLabel-active)': {
      color: theme.palette.text.disabled,
    },
    '& .MuiStepIcon-root:not(.MuiStepIcon-active)': {
      color: '#9E9E9E',
    },
    '& .MuiStepLabel-completed': {
      color: theme.palette.text.disabled,
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      //color: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    '& .MuiStepConnector-line': {
      borderColor: 'rgba(0,0,0,0.12)',
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '64px 24px 40px 24px',
    [theme.breakpoints.down('1279')]: {},
    [theme.breakpoints.down('767')]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1080,
    margin: 'auto',
  },
  containerNoSubsidies: {
    maxWidth: 450,
    [theme.breakpoints.down('1279')]: {
      paddingBottom: 24,
    },
    [theme.breakpoints.down('767')]: {
      paddingBottom: 72,
    },
  },
  tableHead: {
    '&th': {
      width: '40%',
    },
  },
  header: {},
  content: {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 4,
    [theme.breakpoints.down('769')]: {
      marginBottom: 88,
    },
  },

  table: {
    minWidth: 750,
    [theme.breakpoints.down('769')]: {
      minWidth: 500,
    },
  },
  title: {
    marginBottom: 8,
  },
  total: {
    marginBottom: 24,
  },
  subTitle: {
    color: theme.palette.text.disabled,
    maxWidth: '50%',
    marginBottom: 24,
    [theme.breakpoints.down('1279')]: {
      maxWidth: '100%',
    },
  },
  sectionMobile: {
    [theme.breakpoints.down('767')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#ffffff',
      zIndex: 999,
    },
  },
  buttons: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.down('1279')]: {
      margin: 'auto',
      maxWidth: 450,
    },
    [theme.breakpoints.down('1279')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {
      padding: '12px 0',
    },
    [theme.breakpoints.down('464')]: {
      padding: '12px 16px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  contentMobile: {
    [theme.breakpoints.down('767')]: {
      marginBottom: 86,
    },
    '& .MuiAccordion-root': {
      '&:before': {},
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  boxTableSubsidies: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: '1',
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {},
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      borderTop: '1px #E0E0E0 solid',
      borderBottom: '1px #E0E0E0 solid',
    },
    [theme.breakpoints.down('767')]: {
      marginBottom: 86,
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  customWidthTooltip: {
    maxWidth: 600,
    width: 600,
  },
  customPositionTooltip: {
    top: '10px !important',
  },
}));

export const dropzone = {
  overflow: 'hidden',
  minHeight: 128,
  maxHeight: 450,
  maxWidth: 450,
  borderWidth: 1,
};

export const dropzoneLabel = {
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.54)',
  letterSpacing: '0.15px',
  fontFamily: 'Lato',
  lineHeight: '143%',
  padding: '44px 92px',
  textAlign: 'center',
};

export const preview = {
  padding: '42px 24px',
  borderBottom: 'none',
  '& progress': {
    color: 'green',
  },
};
