import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';
import { Grid, Typography, Card, CardContent, Box } from '@material-ui/core';
import { ReactComponent as FilesFolder } from '../../assets/FilesAndFolderTwoColor.svg';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import DropzoneBox from '../uiComponents/DropzoneBox';
import { default as File } from '../uiComponents/UploadedFile';

const DocumentsList = () => {
  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [userContext] = useContext(UserContext);
  const [projectType, setProjectType] = useState('');
  const [loading, setLoading] = useState(false);
  const [mongodbProjectId, setMongodbProjectId] = useState('');

  const [pitchdeckId, setPitchdeckId] = useState({ id: '', filename: '' });
  const [roadmapId, setRoadmapId] = useState({ id: '', filename: '' });
  const [financialModelId, setFinancialModelId] = useState({
    id: '',
    filename: '',
  });
  const [businessPlanId, setBusinessPlanId] = useState({
    id: '',
    filename: '',
  });
  const [budgetPlanId, setBudgetPlanId] = useState({ id: '', filename: '' });
  const [projectPlanId, setProjectPlanId] = useState({ id: '', filename: '' });
  const [quoteId, setQuoteId] = useState({ id: '', filename: '' });
  const [capexId, setCapexId] = useState({ id: '', filename: '' });

  let apiEndPoint =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_PROD;

  const getUploadParamsPitchdeck = ({ file }) => {
    const body = new FormData();
    body.append('pitchdeck', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsFinancialModel = ({ file }) => {
    const body = new FormData();
    body.append('financial_model', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsRoadmap = ({ file }) => {
    const body = new FormData();
    body.append('roadmap', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsBusinessPlan = ({ file }) => {
    const body = new FormData();
    body.append('business_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsBudgetPlan = ({ file }) => {
    const body = new FormData();
    body.append('budget_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsProjectPlan = ({ file }) => {
    const body = new FormData();
    body.append('project_plan', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsQuote = ({ file }) => {
    const body = new FormData();
    body.append('quote', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const getUploadParamsCapex = ({ file }) => {
    const body = new FormData();
    body.append('capex', file);
    body.append('projectId', mongodbProjectId);
    return { url: apiEndPoint + '/upload/', body };
  };

  const handleChangeStatusParamsPitchdeck = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setPitchdeckId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusRoadmap = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setRoadmapId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusFinancialModel = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFinancialModelId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusBusinessPlan = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setBusinessPlanId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusParamsBudgetPlan = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setBudgetPlanId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusParamsProjectPlan = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setProjectPlanId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusParamsQuote = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setQuoteId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const handleChangeStatusParamsCapex = ({ xhr, meta }, status) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setCapexId({
            id: result.fileUpload.fileId,
            filename: result.fileUpload.filename,
          });
        }
      };
    }
  };

  const deleteFile = (documentType) => {
    axios({
      method: 'post',
      url: apiEndPoint + '/upload/delete',
      data: { projectId: mongodbProjectId, documentType: documentType },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => {
      if (res.data.success) {
        if (documentType === 'pitchdeck') {
          setPitchdeckId({ id: '', filename: '' });
        } else if (documentType === 'roadmap') {
          setRoadmapId({ id: '', filename: '' });
        } else if (documentType === 'financial_model') {
          setFinancialModelId({ id: '', filename: '' });
        } else if (documentType === 'business_plan') {
          setBusinessPlanId({ id: '', filename: '' });
        } else if (documentType === 'budget_plan') {
          setBudgetPlanId({ id: '', filename: '' });
        } else if (documentType === 'project_plan') {
          setProjectPlanId({ id: '', filename: '' });
        } else if (documentType === 'quote') {
          setQuoteId({ id: '', filename: '' });
        } else if (documentType === 'capex') {
          setCapexId({ id: '', filename: '' });
        }
      } else {
        console.log('error', res);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    let id = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1,
    );
    var requestBody = {
      SalesforceId: id,
    };
    axios({
      method: 'post',
      url: apiEndPoint + '/project/getProjectDocuments',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setProjectType(res.data.projectType);
        setMongodbProjectId(res.data.projectId);
        if (res.data.projectDocuments.length > 0) {
          res.data.projectDocuments.forEach((projectDocument) => {
            if (projectDocument.documentType === 'pitchdeck') {
              setPitchdeckId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'roadmap') {
              setRoadmapId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'financial_model') {
              setFinancialModelId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'business_plan') {
              setBusinessPlanId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'budget_plan') {
              console.log('pitchdeck');
              setBudgetPlanId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'project_plan') {
              setProjectPlanId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'quote') {
              setQuoteId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            } else if (projectDocument.documentType === 'capex') {
              setCapexId({
                id: projectDocument.fileId,
                filename: projectDocument.filename,
              });
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [
    apiEndPoint,
    location,
    userContext,
    pitchdeckId.id,
    roadmapId.id,
    financialModelId.id,
    businessPlanId.id,
    budgetPlanId.id,
    projectPlanId.id,
    quoteId.id,
    capexId.id,
  ]);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        className={classes.root}
        style={
          isSmallScreen
            ? { paddingTop: 72, position: 'relative' }
            : { paddingTop: 96, position: 'relative' }
        }
      >
        <div
          ref={topPageRef}
          id="anchor-div"
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="flex-start"
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component="h6" variant="h6" align="center">
                  {t('documents.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {t('documents.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        alignContent="center"
                        container
                        item
                        xs={12}
                        md={isLargeScreen ? 7 : 6}
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('documents.card_title')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              {t('documents.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={isLargeScreen ? 5 : 6}>
                        <FilesFolder
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              {/* 1 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={pitchdeckId}
                    label={t('upload.label3')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsPitchdeck}
                    onChangeStatus={handleChangeStatusParamsPitchdeck}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {pitchdeckId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label3')}
                        </Typography>
                      </Box>
                      <File
                        name={pitchdeckId.filename}
                        id={pitchdeckId.id}
                        type={'pitchdeck'}
                        style={{ maxWidth: 'unset' }}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 2 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={roadmapId}
                    label={t('upload.label1')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsRoadmap}
                    onChangeStatus={handleChangeStatusRoadmap}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {roadmapId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label1')}
                        </Typography>
                      </Box>
                      <File
                        name={roadmapId.filename}
                        id={roadmapId.id}
                        style={{ maxWidth: 'unset' }}
                        type={'roadmap'}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 3 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={financialModelId}
                    label={t('upload.label2')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsFinancialModel}
                    onChangeStatus={handleChangeStatusFinancialModel}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {financialModelId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label2')}
                        </Typography>
                      </Box>
                      <File
                        name={financialModelId.filename}
                        id={financialModelId.id}
                        type={'financial_model'}
                        deleteFile={deleteFile}
                        style={{ maxWidth: 'unset' }}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 4 */}
              {projectType === '0123X000001GJUGQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={businessPlanId}
                    label={t('upload.label4')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsBusinessPlan}
                    onChangeStatus={handleChangeStatusBusinessPlan}
                    multiple={false}
                    previewComponent={null}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {businessPlanId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label4')}
                        </Typography>
                      </Box>
                      <File
                        name={businessPlanId.filename}
                        id={businessPlanId.id}
                        type={'business_plan'}
                        style={{ maxWidth: 'unset' }}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 5 */}
              {(projectType === '0123X000001GJUCQA4' ||
                projectType === '0123X000001GJUFQA4') && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={budgetPlanId}
                    label={t('upload.label6')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsBudgetPlan}
                    onChangeStatus={handleChangeStatusParamsBudgetPlan}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {budgetPlanId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label6')}
                        </Typography>
                      </Box>
                      <File
                        name={budgetPlanId.filename}
                        id={budgetPlanId.id}
                        type={'budget_plan'}
                        style={{ maxWidth: 'unset' }}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 6 plan */}
              {(projectType === '0123X000001GJUCQA4' ||
                projectType === '0123X000001GJUEQA4' ||
                projectType === '0123X000001GJUFQA4') && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={projectPlanId}
                    label={t('upload.label7')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsProjectPlan}
                    onChangeStatus={handleChangeStatusParamsProjectPlan}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {projectPlanId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label7')}
                        </Typography>
                      </Box>
                      <File
                        name={projectPlanId.filename}
                        id={projectPlanId.id}
                        type={'project_plan'}
                        deleteFile={deleteFile}
                        style={{ maxWidth: 'unset' }}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 7 */}
              {projectType === '0123X000001GJUDQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={quoteId}
                    label={t('upload.label8')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsQuote}
                    onChangeStatus={handleChangeStatusParamsQuote}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {quoteId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label8')}
                        </Typography>
                      </Box>
                      <File
                        name={quoteId.filename}
                        id={quoteId.id}
                        type={'quote'}
                        style={{ maxWidth: 'unset' }}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/* 8 */}
              {projectType === '0123X000001GJUDQA4' && (
                <Grid item xs={12}>
                  <DropzoneBox
                    file={capexId}
                    label={t('upload.label9')}
                    inputContent={t('upload.label5')}
                    getUploadParams={getUploadParamsCapex}
                    onChangeStatus={handleChangeStatusParamsCapex}
                    multiple={false}
                    maxFiles={'1'}
                    maxwidth={'unset'}
                  />
                  {capexId.id && (
                    <div>
                      <Box mb={2.5} mt={5.5}>
                        <Typography variant="overline" color="textSecondary">
                          {t('upload.label9')}
                        </Typography>
                      </Box>
                      <File
                        name={capexId.filename}
                        id={capexId.id}
                        style={{ maxWidth: 'unset' }}
                        type={'capex'}
                        deleteFile={deleteFile}
                      />
                    </div>
                  )}
                </Grid>
              )}
              {/**/}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentsList;
