import React, { createContext, useState } from "react";

export const NewProjectContext = createContext();

export const NewProjectProvider = ({ children }) => {
  const [projectProgress, setProjectProgress] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [projectType, setProjectType] = useState("");
  const [newProjectId, setNewProjectId] = useState("");
  // project details type product
  const [industries, setIndustries] = useState([]);
  // project type international
  const [active, setActive] = useState([]);
  const [expand, setExpand] = useState([]);
  const [activitiesInt, setActivitiesInt] = useState([]);
  // type business
  const [activities, setActivities] = useState([]);
  // project details type social
  const [themes, setThemes] = useState([]);
  const [target, setTarget] = useState([]);

  const [projectProduct, setProjectProduct] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    noOfRnDEmployees: "",
    rndHours: "",
    rndCosts: "",
    activitiesCarriedOutBy: "",
    industrySector: "",
    newness: "",
    projectFocus: "",
    preTRLLevel: "",
    postTRLLevel: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  const [projectInt, setProjectInt] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    countriesActive: "",
    countriesExpanding: "",
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  const [projectBusiness, setProjectBusiness] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });
  const [projectEmployees, setProjectEmployees] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    learningWorkPrograms: "",
    investmentInEmployees: "",
    hiredWorkersAtDisadvantage: "",
    willHireWorkersAtDisadvantage: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });
  const [projectSocial, setProjectSocial] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    projectThemes: "",
    projectTargetGroup: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  return (
    <NewProjectContext.Provider
      value={{
        projectProgress,
        setProjectProgress,
        percentage,
        setPercentage,
        projectType,
        setProjectType,
        projectProduct,
        setProjectProduct,
        projectInt,
        setProjectInt,
        projectBusiness,
        setProjectBusiness,
        projectEmployees,
        setProjectEmployees,
        projectSocial,
        setProjectSocial,
        newProjectId,
        setNewProjectId,
        industries,
        setIndustries,
        active,
        setActive,
        expand,
        setExpand,
        activitiesInt,
        setActivitiesInt,
        activities,
        setActivities,
        themes,
        setThemes,
        target,
        setTarget,
      }}
    >
      {children}
    </NewProjectContext.Provider>
  );
};
