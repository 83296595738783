/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseLayout from "../Layouts/TopBarLevel1Layout";
import NewProjectUploadTypeProduct from "../Components/NewProject/NewProjectUploadTypeProduct";
import NewProjectUploadTypeInternational from "../Components/NewProject/NewProjectUploadTypeInternational";
import NewProjectUploadTypeBusiness from "../Components/NewProject/NewProjectUploadTypeBusiness";
import NewProjectUploadTypeEmployees from "../Components/NewProject/NewProjectUploadTypeEmployees";
import NewProjectUploadTypeSocial from "../Components/NewProject/NewProjectUploadTypeSocial";
import NewProjectSidebar from "../Components/NewProject/NewProjectSidebar";
import { NewProjectContext } from "../Contexts/NewProject";

const NewProjectUploadDocuments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [progressStep, setProgressStep] = useState(2);
  const [projectProgress, setProjectProgress] = useState(2);
  const {
    projectType,
    newProjectId,
    setPercentage,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setProjectSocial,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
  } = useContext(NewProjectContext);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 4) * progressStep);

  const handleConfirm = () => {
    setProjectProgress(0);
    setPercentage(0);
    setProjectType("");
    setNewProjectId("");
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setProjectProduct({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      noOfRnDEmployees: "",
      rndHours: "",
      rndCosts: "",
      activitiesCarriedOutBy: "",
      industrySector: "",
      newness: "",
      projectFocus: "",
      preTRLLevel: "",
      postTRLLevel: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectInt({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      countriesActive: "",
      countriesExpanding: "",
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectBusiness({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectEmployees({
      name: "",
      idea: "",
      problemStatement: "",
      learningWorkPrograms: "",
      investmentInEmployees: "",
      hiredWorkersAtDisadvantage: "",
      willHireWorkersAtDisadvantage: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectSocial({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      projectThemes: "",
      projectTargetGroup: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    history.push("/client/app/dashboard");
  };

  useEffect(() => {
    window.document.title = t("page_title.upload");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === "0123X000001GJUGQA4" && (
              <NewProjectUploadTypeProduct
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === "0123X000001GJUCQA4" && (
              <NewProjectUploadTypeInternational
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === "0123X000001GJUDQA4" && (
              <NewProjectUploadTypeBusiness
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === "0123X000001GJUEQA4" && (
              <NewProjectUploadTypeEmployees
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === "0123X000001GJUFQA4" && (
              <NewProjectUploadTypeSocial
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t("dialog.quit_new_project")}
        title={t("projects.card_new")}
      ></BaseLayout>
    </>
  );
};

export default NewProjectUploadDocuments;
