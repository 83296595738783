import React, { createContext, useState } from "react";

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [quizProgress, setQuizProgress] = useState(0);
  const [projectProgress, setProjectProgress] = useState(0);
  const [subsidiesAmount, setSubsidiesAmount] = useState(0);
  const [subsidiesNumber, setSubsidiesNumber] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [projectType, setProjectType] = useState(
    localStorage.getItem("firstProjectType")
  );
  const [newProjectId, setNewProjectId] = useState(
    localStorage.getItem("firstProjectId")
  );
  const [newProjectSalesforceId, setNewProjectSalesforceId] = useState("");
  const [plan, setPlan] = useState(1);
  const [selectedSubsidies, setSelectedSubsidies] = useState([]);
  const [user, setUser] = useState({});
  const [returningUser, setReturningUser] = useState(false);
  const [showWelcomeNewUser, setShowWelcomeNewUser] = useState(false);
  const [passReset, setPassReset] = useState(false);

  const [authToken, setAuthToken] = useState("");
  const [scanDone, setScanDone] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);
  // subsidyscan question 3
  const [sectors, setSectors] = useState([]);
  // project details type product
  const [industries, setIndustries] = useState([]);
  // project type international
  const [active, setActive] = useState([]);
  const [expand, setExpand] = useState([]);
  const [activitiesInt, setActivitiesInt] = useState([]);
  // type business
  const [activities, setActivities] = useState([]);
  // project details type social
  const [themes, setThemes] = useState([]);
  const [target, setTarget] = useState([]);

  const [answers, setAnswers] = useState({
    Name: "SubsidyCloud Test 1",
    User_Type__c: "A User : Subsidy Seeker",
    Type: "Prospect",
    Rechtsvorm__c: "",
    acc_sme__c: "",
    ANBI_Status__c: "",
    acc_key_sectors__c: "",
    acc_province__c: "",
    Employees_Excluding_Founders__c: "",
    Investment_Needed__c: "",
  });

  const [projectProduct, setProjectProduct] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    noOfRnDEmployees: "",
    rndHours: "",
    rndCosts: "",
    activitiesCarriedOutBy: "",
    industrySector: "",
    newness: "",
    projectFocus: "",
    preTRLLevel: "",
    postTRLLevel: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  const [projectInt, setProjectInt] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    countriesActive: "",
    countriesExpanding: "",
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  const [projectBusiness, setProjectBusiness] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    activities: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });
  const [projectEmployees, setProjectEmployees] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    learningWorkPrograms: "",
    investmentInEmployees: "",
    hiredWorkersAtDisadvantage: "",
    willHireWorkersAtDisadvantage: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });
  const [projectSocial, setProjectSocial] = useState({
    name: "",
    idea: "",
    problemStatement: "",
    startDate: null,
    endDate: null,
    projectThemes: "",
    projectTargetGroup: "",
    subsidyNeed: "",
    projectTypeId: "",
    _id: "",
  });

  return (
    <OnboardingContext.Provider
      value={{
        quizProgress,
        setQuizProgress,
        projectProgress,
        setProjectProgress,
        percentage,
        setPercentage,
        answers,
        setAnswers,
        sectors,
        setSectors,
        subsidiesAmount,
        setSubsidiesAmount,
        subsidiesNumber,
        setSubsidiesNumber,
        scanDone,
        setScanDone,
        showGreeting,
        setShowGreeting,
        projectType,
        setProjectType,
        projectProduct,
        setProjectProduct,
        projectInt,
        setProjectInt,
        projectBusiness,
        setProjectBusiness,
        projectEmployees,
        setProjectEmployees,
        projectSocial,
        setProjectSocial,
        newProjectId,
        setNewProjectId,
        newProjectSalesforceId,
        setNewProjectSalesforceId,
        plan,
        setPlan,
        selectedSubsidies,
        setSelectedSubsidies,
        user,
        setUser,
        authToken,
        setAuthToken,
        industries,
        setIndustries,
        active,
        setActive,
        expand,
        setExpand,
        activitiesInt,
        setActivitiesInt,
        activities,
        setActivities,
        themes,
        setThemes,
        target,
        setTarget,
        returningUser,
        setReturningUser,
        showWelcomeNewUser,
        setShowWelcomeNewUser,
        passReset,
        setPassReset,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
