/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Box } from '@material-ui/core';

import DashboardDetails from '../Components/Dashboard/DashboardDetails';
import ProjectsList from '../Components/Projects/ProjectsList';
import SubsidiesList from '../Components/Projects/SubsidiesList';
import DocumentsList from '../Components/Projects/DocumentsList';
import ApplicationsList from '../Components/Applications/ApplicationsList';
import ProjectX from '../Components/Projects/ProjectX';
import MyAccount from '../Components/MyAccount/MyAccount';
import NavBar from './NavBar';
import EditProject from '../Components/Projects/EditProject';

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ display: 'content' }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <NavBar setTabValue={setTabValue} tabValue={tabValue} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route
              exact
              path="/client/app/dashboard"
              component={DashboardDetails}
            />
            <Route exact path="/client/app/projects">
              <ProjectsList />
            </Route>
            <Route exact path="/client/app/applications">
              <ApplicationsList />
            </Route>
            <Route exact path="/client/app/account">
              <MyAccount />
            </Route>
            <Route exact path={`/client/app/projects/:name/edit`}>
              <EditProject />
            </Route>
          </Switch>
          <TabPanel value={tabValue} index={0}>
            <Route exact path={`/client/app/projects/:name`}>
              <ProjectX />
            </Route>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SubsidiesList />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <DocumentsList />
          </TabPanel>
        </main>
      </div>
    </>
  );
};

export default withRouter(DashboardLayout);
