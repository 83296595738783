import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Progress } from '../../assets/ProgressTwoColor.svg';
import { ReactComponent as TimeTwo } from '../../assets/Time_TwoColor.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { applicationStatusOptions } from '../../data/applicationStatus';
import i18n from '../../JS/i18n';

function createData(
  SubsidyName,
  SubsidyAmount,
  Status,
  ExpertName,
  ApplicationDate,
) {
  return {
    SubsidyName,
    SubsidyAmount,
    Status,
    ExpertName,
    ApplicationDate,
  };
}

function EnhancedTableHead() {
  const headCells = [
    {
      id: 'SubsidyName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('application_list.subsidy_name'),
      width: '20%',
    },
    {
      id: 'SubsidyAmount',
      numeric: false,
      disablePadding: false,
      label: i18n.t('application_list.subsidy_amount'),
      width: '20%',
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: false,
      label: i18n.t('application_list.status'),
      width: '20%',
    },
    {
      id: 'ExpertName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('application_list.expert'),
      width: '20%',
    },
    {
      id: 'ApplicationDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('application_list.date'),
      width: '20%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ width: headCell.width, fontWeight: 'bold' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ApplicationList = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const statusTranslations = applicationStatusOptions();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    let data = [];
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/application/getApplications',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.applications.forEach((item, i) => {
          statusTranslations.forEach((status, i) => {
            if (item.Status === status.value) {
              item.Status = status.label;
            }
          });
          data.push(
            createData(
              item.SubsidyName,
              item.SubsidyAmount,
              item.Status,
              item.ExpertName,
              item.ApplicationDate,
            ),
          );
        });
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    window.document.title = t('page_title.applications');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root}>
        {rows.length > 0 ? (
          <Grid
            container
            item
            justifyContent="center"
            alignContent="flex-start"
            xs={12}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              className={classes.container}
            >
              <Grid item xs={12} className={classes.box}>
                <Box mb={2.5}>
                  <Typography component="h5" variant="h5" align="center">
                    {t('application_list.application_title')}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    {t('application_list.application_subtitle')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.box}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Card
                    className={classes.card}
                    variant="outlined"
                    style={(theme.custom.boxshadow, { marginRightt: 24 })}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid
                          alignContent="center"
                          container
                          item
                          xs={isLargeScreen ? 7 : 12}
                        >
                          <Grid item xs={12}>
                            <Box mb={1}>
                              <Typography>
                                {t(
                                  'application_list.subsidie_application_title',
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography variant="body2" color="textSecondary">
                                {t(
                                  'application_list.subsidie_application_subtitile',
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={isLargeScreen ? 5 : 12}>
                          <Progress
                            style={{
                              width: isLargeScreen ? 'auto' : '100%',
                              float: 'right',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.box}>
                {!isSmallScreen ? (
                  <Grid item xs={12}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        className={classes.table}
                        aria-label="a dense table"
                      >
                        <EnhancedTableHead />
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.SubsidyName}>
                              <TableCell>
                                <Tooltip
                                  title={row.SubsidyName}
                                  placement="top-start"
                                >
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      width: '200px',
                                      display: 'block',
                                      overflow: 'hidden',
                                      fontSize: 16,
                                    }}
                                  >
                                    {row.SubsidyName}
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell style={{ fontSize: 16 }}>
                                <NumberFormat
                                  value={row.SubsidyAmount}
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  isNumericString
                                  prefix={'€'}
                                />
                              </TableCell>
                              <TableCell style={{ fontSize: 16 }}>
                                {row.Status}
                              </TableCell>
                              <TableCell>
                                <Tooltip
                                  title={row.ExpertName}
                                  placement="top-start"
                                >
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      width: '100px',
                                      display: 'block',
                                      overflow: 'hidden',
                                      fontSize: 16,
                                    }}
                                  >
                                    {row.ExpertName}
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell style={{ fontSize: 16 }}>
                                {row.ApplicationDate}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.boxTableMobile}>
                    {rows.map((row, index) => (
                      <Accordion
                        expanded={expanded === 'panel' + index}
                        onChange={handleExpanded('panel' + index)}
                        style={{
                          boxShadow: 'none',
                        }}
                        key={row.SubsidyName}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            {row.SubsidyName}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            className={classes.secondaryHeading}
                            variant="overline"
                          >
                            {t('application_list.status')}
                          </Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            style={{ marginBottom: 32 }}
                          >
                            {row.Status}
                          </Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            variant="overline"
                          >
                            {t('application_list.expert')}
                          </Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            style={{ marginBottom: 32 }}
                          >
                            {row.ExpertName}
                          </Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            variant="overline"
                          >
                            {t('application_list.date')}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            {row.ApplicationDate}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              justifyContent="center"
              alignContent="center"
              xs={12}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                className={classes.container}
              >
                <Grid item xs={12} className={classes.box}>
                  <Box mb={1}>
                    <Typography component="h5" variant="h5" align="center">
                      {t('application_list.application_title')}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      {t('application_list.application_subtitle2')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.box}
                  justifyContent="center"
                >
                  <TimeTwo />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default withRouter(ApplicationList);
