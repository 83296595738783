import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import { useMediaQuery } from 'react-responsive';
import ProjectSidebar from '../Components/Onboarding/ProjectSidebar';
import ChooseProjectType from '../Components/Onboarding/ChooseProjectType';
import { OnboardingContext } from '../Contexts/Onboarding';
import { ReactComponent as SupportAgent } from '../assets/support_agent_black_36dp.svg';
import { useTheme } from '@material-ui/core/styles';

const ProjectType = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [progressStep, setProgressStep] = useState(7);
  const [projectProgress, setProjectProgress] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);
  const {
    user,
    projectType,
    setProjectType,
    showGreeting,
    setShowGreeting,
    returningUser,
    setReturningUser,
  } = useContext(OnboardingContext);

  useEffect(() => {
    window.document.title = t('page_title.choose_type');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            <ChooseProjectType
              projectType={projectType}
              setProjectType={setProjectType}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              progressStep={progressStep}
              setProgressStep={setProgressStep}
              projectProgress={projectProgress}
              setProjectProgress={setProjectProgress}
              center={`${center}%`}
              showGreeting={showGreeting}
              setShowGreeting={setShowGreeting}
              user={user}
              returningUser={returningUser}
              setReturningUser={setReturningUser}
            />
          </>
        }
        sidebar={
          <ProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        close={
          <>
            {isLargeScreen && (
              <>
                <SupportAgent
                  style={{
                    paddingRight: 8,
                    fill: theme.palette.secondary.main,
                  }}
                />
                <span>+31 (0)85 580 74 21</span>
              </>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {t('buttons.logout')}
            </Button>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default withRouter(ProjectType);
