import React from 'react';
import DashboardDetails from '../Components/Dashboard/DashboardDetails';
import DashboardLayout from '../Layouts/DashboardLayout';

const Dashboard = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout header="Dashboard" main={<DashboardDetails />} />
    </>
  );
};

export default Dashboard;
