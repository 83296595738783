import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BorderLinearProgress from './BorderLinearProgress';
import { trimFileName } from '../../JS/HelperFunctions';
import { useTheme } from '@material-ui/core/styles';

const dropzone = {
  overflow: 'hidden',
  minHeight: 128,
  maxHeight: 450,
  maxWidth: 450,
};

const file = {
  boxShadow: '0px 0px 0px 1px #E0E0E0',
  borderRadius: 4,
};

const UploadedFile = ({ name = '...', type, style, deleteFile }) => {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          ...file,
          ...dropzone,
          ...style,
          padding: 24,
          height: '100%',
          flex: 1,
        }}
      >
        <Grid item xs={6} style={{ paddingRight: 24 }}>
          <Tooltip title={name} placement="top-start">
            <span
              style={{
                alignSelf: 'flex-start',
                margin: '10px 3%',
                wordBreak: 'break-all',
                color: theme.palette.primary.main,
              }}
            >
              {trimFileName(name)}
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={5}>
          <BorderLinearProgress variant="determinate" value={100} />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="remove file"
            onClick={() => {
              deleteFile(type);
            }}
          >
            <CloseIcon
              style={{
                display: 'block',
                marginLeft: 'auto',
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadedFile;
