import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& > div': {
      [theme.breakpoints.down('767')]: {
        width: 'auto',
      },
    },
  },
  formContainer: {
    maxWidth: 450,
    margin: '64px 24px 40px 24px',
    [theme.breakpoints.down('1279')]: {},
    [theme.breakpoints.down('767')]: {
      maxWidth: 'auto',
      padding: 0,
      margin: '24px 16px 0px 16px',
    },
  },
  chipsContainer: {
    '& .MuiChip-root ': {
      marginRight: 8,
      background: '#F5F5F5',
    },
    '& svg.icon-error': {
      fill: '#F44336',
    },
    '& svg.icon-done': {
      fill: theme.palette.primary.main,
    },
    '& > div:first-of-type': {
      [theme.breakpoints.down('767')]: {
        marginBottom: 16,
      },
    },
  },
  closeAlert: {
    '& .MuiAlert-action': {
      paddingTop: 4,
      alignItems: 'flex-start !important',
    },
  },
  iconRight: {
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
}));
