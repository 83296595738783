import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('767')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  projectX: {
    marginTop: 48,
  },
  container: {
    maxWidth: 786,
  },
  box: {
    marginBottom: 24,
  },
  boxLastItem: {
    [theme.breakpoints.down('767')]: {
      marginBottom: 0,
    },
  },
  card: {
    width: '100%',
    '& .MuiCardContent-root': {
      padding: 24,
      [theme.breakpoints.down('767')]: {},
    },
    '& .MuiCardActions-root': {
      padding: '6px 24px',
      [theme.breakpoints.down('767')]: {
        padding: '6px 16px',
      },
    },
  },
  boxCardHeader: {},
  tableContainer: {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderBottom: 'none',
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:hover': {
        backgroundColor: '#fafafa',
      },
    },
  },
  table: {},
  mTop12: {
    marginTop: 20,
  },
  mBottom12: {
    marginBottom: 20,
  },
  boxTablePhone: {
    [theme.breakpoints.down('767')]: {
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      borderRadius: 4,
      marginBottom: 0,
    },
    '& .MuiAccordion-rounded': {
      '&:first-child': {},
      '&:last-child': {},
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
    },
  },
  boxTableSubsidies: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: '1',
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {},
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      borderTop: '1px #E0E0E0 solid',
      borderBottom: '1px #E0E0E0 solid',
    },
    '& *': {
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  },
  [theme.breakpoints.down('767')]: {
    marginBottom: 86,
  },
}));

export const editProjectStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('767')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  projectX: {
    marginTop: 48,
    [theme.breakpoints.down('767')]: {
      marginTop: 0,
    },
  },
  container: {
    maxWidth: 564,
    '& button:first-of-type': {
      marginRight: 12,
    },
  },
  box: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    padding: 24,
    marginBottom: 24,
    '& > div': {},
  },
  input: {
    '& .Mui-focused': {
      '& .MuiAutocomplete-popupIndicator': {},
    },
  },
  formControl: {
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('767')]: {
        maxWidth: 'calc(100vw - 32px)',
      },
    },
  },
}));
