/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseLayout from "../Layouts/TopBarLevel1Layout";
import NewProjectTypeProduct from "../Components/NewProject/NewProjectTypeProduct";
import NewProjectTypeInternational from "../Components/NewProject/NewProjectTypeInternational";
import NewProjectTypeInvestBusiness from "../Components/NewProject/NewProjectTypeInvestBusiness";
import NewProjectTypeInvestEmployees from "../Components/NewProject/NewProjectTypeInvestEmployees";
import NewProjectTypeSocial from "../Components/NewProject/NewProjectTypeSocial";
import NewProjectSidebar from "../Components/NewProject/NewProjectSidebar";
import { NewProjectContext } from "../Contexts/NewProject";

import {
  focusOptionss,
  industryOptionsEn,
  industryOptionsNl,
  trlOptions,
  rdHoursOptions,
  rdCostOptions,
  newProductOptionss,
  rdActivitiesOptionss,
  countryList,
  internationalActivitiesEn,
  internationalActivitiesNl,
  activitiesBussinessEn,
  themeOptionsEn,
  targetOptionsEn,
  activitiesBussinessNl,
  themeOptionsNl,
  targetOptionsNl,
  yesNoOptions,
} from "../data/projectOptions";
import i18n from "i18next";

const NewProjectDetails = () => {
  const { t } = useTranslation();
  const [progressStep, setProgressStep] = useState(1);
  const [projectProgress, setProjectProgress] = useState(1);
  const focusOptions = focusOptionss();
  const newProductOptions = newProductOptionss();
  const rdActivitiesOptions = rdActivitiesOptionss();
  const history = useHistory();

  const {
    projectType,
    setPercentage,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setProjectSocial,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
  } = useContext(NewProjectContext);
  const center = Math.round((100 / 4) * progressStep);

  const handleConfirm = () => {
    setProjectProgress(0);
    setPercentage(0);
    setProjectType("");
    setNewProjectId("");
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setProjectProduct({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      noOfRnDEmployees: "",
      rndHours: "",
      rndCosts: "",
      activitiesCarriedOutBy: "",
      industrySector: "",
      newness: "",
      projectFocus: "",
      preTRLLevel: "",
      postTRLLevel: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectInt({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      countriesActive: "",
      countriesExpanding: "",
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectBusiness({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectEmployees({
      name: "",
      idea: "",
      problemStatement: "",
      learningWorkPrograms: "",
      investmentInEmployees: "",
      hiredWorkersAtDisadvantage: "",
      willHireWorkersAtDisadvantage: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectSocial({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      projectThemes: "",
      projectTargetGroup: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    history.push("/client/app/dashboard");
  };

  useEffect(() => {
    window.document.title = t("page_title.add_project");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === "0123X000001GJUGQA4" && (
              <NewProjectTypeProduct
                trlOptions={trlOptions}
                rdHoursOptions={rdHoursOptions}
                rdCostOptions={rdCostOptions}
                rdActivitiesOptions={rdActivitiesOptions}
                industryOptions={
                  i18n.languages[0] === "nl"
                    ? industryOptionsNl
                    : industryOptionsEn
                }
                newProductOptions={newProductOptions}
                focusOptions={focusOptions}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
              />
            )}
            {projectType === "0123X000001GJUCQA4" && (
              <NewProjectTypeInternational
                countryList={countryList}
                internationalActivities={
                  i18n.languages[0] === "nl"
                    ? internationalActivitiesNl
                    : internationalActivitiesEn
                }
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
              />
            )}
            {projectType === "0123X000001GJUDQA4" && (
              <NewProjectTypeInvestBusiness
                activitiesBusiness={
                  i18n.languages[0] === "nl"
                    ? activitiesBussinessNl
                    : activitiesBussinessEn
                }
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
              />
            )}
            {projectType === "0123X000001GJUEQA4" && (
              <NewProjectTypeInvestEmployees
                yesNoOptions={yesNoOptions}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
              />
            )}
            {projectType === "0123X000001GJUFQA4" && (
              <NewProjectTypeSocial
                themeOptions={
                  i18n.languages[0] === "nl" ? themeOptionsNl : themeOptionsEn
                }
                targetOptions={
                  i18n.languages[0] === "nl" ? targetOptionsNl : targetOptionsEn
                }
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                projectProgress={projectProgress}
                setProjectProgress={setProjectProgress}
                center={`${center}%`}
                handleConfirm={handleConfirm}
              />
            )}
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t("dialog.quit_new_project")}
        title={t("projects.card_new")}
      ></BaseLayout>
    </>
  );
};

export default withRouter(NewProjectDetails);
