import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const ForgotPasswordDetails = ({ email, setEmail, handleLogin }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });

  const [disabled, setDisabled] = useState(true);
  React.useEffect(() => {
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setDisabled(!email.match(regEmail));
  }, [email]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.formContainer}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: 24 }}>
              <Typography color="textPrimary" variant="h6">
                {t('login.forgot_pass')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  padding: '24px 24px 24px 24px',
                  boxShadow: '0px 0px 0px 1px #E0E0E0',
                  borderRadius: 4,
                }}
              >
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      id="email"
                      name="email"
                      label={t('make_account.email')}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      autoFocus={isLargeScreen}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '24px 0' }}
            >
              <Grid item style={{ paddingRight: 24 }}>
                <Link href="/login" variant="body2">
                  {t('login.login')}
                </Link>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(e) => {
                    handleLogin(e);
                    setEmail('');
                  }}
                  disabled={disabled}
                >
                  {t('forgot_password.confirm')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordDetails;
