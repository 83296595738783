import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('767')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  projectX: {
    marginTop: 48,
  },
  container: {
    maxWidth: 786,
  },
  box: {
    marginBottom: 24,
  },
  card: {
    width: '100%',
    borderRadius: 8,
    '& .MuiCardContent-root': {
      padding: 24,
      [theme.breakpoints.down('767')]: {
        padding: 16,
      },
    },
    '& .MuiCardActions-root': {
      padding: '6px 24px',
      [theme.breakpoints.down('767')]: {
        padding: '6px 16px',
      },
    },
  },
  tableContainer: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 8,
  },
  table: {
    width: '100%',
  },
  mTop12: {
    marginTop: 20,
  },
  mBottom12: {
    marginBottom: 20,
  },
  boxTableMobile: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: 4,
    '& .MuiAccordion-root.Mui-expanded:before': {
      opacity: '1',
    },
    '& .MuiAccordionSummary-root': {
      padding: '8px 16px',
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {},
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      borderTop: '1px #E0E0E0 solid',
      borderBottom: '1px #E0E0E0 solid',
    },
  },
}));
