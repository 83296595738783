import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from '../Components/ForgotPassword/styles';
import { withRouter, useLocation } from 'react-router-dom';
import BaseLayout from '../Layouts/BaseLayout';
import { Grid, Snackbar, IconButton } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import ForgotPasswordSideComponent from '../Components/ForgotPassword/ForgotPasswordSideComponent';
import ResetPasswordDetails from '../Components/ForgotPassword/ResetPasswordDetails';
import Loader from '../Components/uiComponents/Loader';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from '../Contexts/Onboarding';

const ResetPassword = ({ history }) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = useState('');
  let location = useLocation();
  const [showError, setShowError] = React.useState(false);
  const { t } = useTranslation();
  const { setPassReset } = useContext(OnboardingContext);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const handleResetPassword = (e) => {
    setLoading(true);
    e.preventDefault();
    let token = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1,
    );
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/users/resetPassword',
      data: { password: password, resetPasswordToken: token },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          setPassReset(true);
          setLoading(false);
          history.push('/client/login');
        } else {
          setShowError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  };

  useEffect(() => {
    window.document.title = t('page_title.reset_pass');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid container justifyContent="center" className={classes.root}>
              <Grid
                container
                item
                xs={12}
                alignContent="center"
                wrap="nowrap"
                style={{ maxHeight: 'calc(100vh - 72px)', overflowY: 'auto' }}
              >
                {isLargeScreen && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{
                      height: '100%',
                      width: 'max-content',
                    }}
                  >
                    <ForgotPasswordSideComponent />
                  </Grid>
                )}
                <Grid container item justifyContent="center">
                  <ResetPasswordDetails
                    password={password}
                    setPassword={setPassword}
                    handleReset={handleResetPassword}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
            >
              <Alert
                severity="error"
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('forgot_password.error_1')}
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: 10,
                    top: 12,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Alert>
            </Snackbar>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default withRouter(ResetPassword);
