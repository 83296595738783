import React from 'react';
import { Grid } from '@material-ui/core';
import welcomeMat from '../../assets/welcomemat-image-x2.5.png';

const LoginSideComponent = () => {
  return (
    <>
      <Grid container item xs={12} style={{ height: '100%' }}>
        <img
          src={welcomeMat}
          alt="welcomeMat"
          style={{
            height: '100%',
            minHeight: 650,
          }}
        />
      </Grid>
    </>
  );
};

export default LoginSideComponent;
