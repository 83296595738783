import React from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ResponsiveEmbed from 'react-responsive-embed';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../Onboarding/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  signDialog: {
    [theme.breakpoints.down('1028')]: {
      //width: '100%',
    },

    [theme.breakpoints.down('414')]: {
      //width: '368px',
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div>{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    width: '968px',
    padding: theme.spacing(2),

    [theme.breakpoints.down('1028')]: {
      width: '92vw',
      '& > div': {
        height: '100%!important',
      },
      '& .signin': {
        width: '100vw !important',
      },
      '& .content-position': {
        width: '100vw !important',
      },
    },
    [theme.breakpoints.down('768')]: {
      width: '100vw',
      // to check
      height: '100%!important',
    },
    [theme.breakpoints.down('414')]: {
      width: '368px',
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const IframeDialog = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (
        event.data &&
        (event.data.source === 'react-devtools-content-script' ||
          event.data.source === 'react-devtools-bridge')
      ) {
        return;
      } else {
        var origin = event.origin || event.originalEvent.origin;
        var data = event.data;
        console.log(data, origin);
        switch (data) {
          case 'ESL:MESSAGE:REGISTER':
            event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
            break;
          case 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE':
            props.setOpenSignDialog(false);
            props.setOpenSuccessDialog(true);
            break;
          case 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE_REVIEWED':
            props.setOpenSignDialog(false);
            props.setOpenSuccessDialog(true);
            break;
          default:
            event.source.postMessage(data, origin);
            break;
        }
      }
    });
  });

  /*if (event.data && event.data.source === 'react-devtools-content-script') {
        return;
      } */

  /*    
  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      var origin = event.origin || event.originalEvent.origin;
      var data = event.data;
      console.log(data, origin);
      switch (data) {
        case 'ESL:MESSAGE:REGISTER':
          event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
          break;
        case 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE':
          props.setOpenSignDialog(false);
          props.setOpenSuccessDialog(true);
          break;
        case 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE_REVIEWED':
          props.setOpenSignDialog(false);
          props.setOpenSuccessDialog(true);
          break;
        default:
          event.source.postMessage(data, origin);
          break;
      }
    });
  });
*/

  return (
    <>
      <Dialog
        className={classes.signDialog}
        onClose={props.handleSignDialog}
        maxWidth={false}
        disableBackdropClick={true}
        aria-labelledby="customized-dialog-title"
        open={props.openSignDialog}
        style={styles.signDialog}
        fullScreen={isSmallScreen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.handleSignDialog}
        >
          {t('labels.sign')}
        </DialogTitle>
        <DialogContent>
          <ResponsiveEmbed
            src={props.signUrl}
            allowFullScreen
          ></ResponsiveEmbed>
        </DialogContent>
        <DialogActions>
          <Button disabled>{t('buttons.done')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.openSuccessDialog}
        //open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.successDialog}
        fullScreen={isSmallScreen}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">{t('labels.sign')}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleSuccessDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#f4f4f4' }}>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <Grid container item xs={12}>
              <Grid container justifyContent="center" item xs={12}>
                <IconButton
                  aria-label="check"
                  variant="contained"
                  className={classes.checkIcon}
                  style={{
                    cursor: 'auto',
                    textAlign: 'center',
                    backgroundColor: theme.palette.secondary.main,
                    color: '#ffffff',
                  }}
                >
                  <CheckIcon
                    style={{
                      width: 128,
                      height: 128,
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box mt={3.5} mb={3}>
                    <Typography
                      variant={'h6'}
                      align="center"
                      color="textPrimary"
                    >
                      {t('dialog.success_sign')}
                    </Typography>
                  </Box>
                  <Typography
                    variant={'body2'}
                    color="textSecondary"
                    align="center"
                  >
                    {t('dialog.success_sign_text_1')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    color="textSecondary"
                    align="center"
                  >
                    {t('dialog.success_sign_text_2')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 14 }}>
          <Button
            onClick={props.handleSuccessDialog}
            color="primary"
            variant="contained"
            autoFocus
          >
            {t('buttons.done')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IframeDialog;
