import React, { useState, useEffect, useContext } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { useHistory, withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormLabel,
  Button,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import nlLocale from "date-fns/locale/nl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { theme as bdtheme } from "../../JS/BrightDesignTheme";
import { newProjectDoughnutData } from "../../data/picklistOptions";
import NivoDoughnut from "../uiComponents/NivoDoughnut";
import { useMediaQuery } from "react-responsive";
import { useStyles } from "../Onboarding/styles";
import { ReactComponent as SadFace } from "../../assets/Sad_face_TwoColor.svg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  handleChange,
  NumberFormatCustom,
  NumberFormatPositives,
} from "../../JS/FormControls";
import { NewProjectContext } from "../../Contexts/NewProject";
import { DashboardContext } from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import axios from "axios";
import Loader from "../uiComponents/Loader";
import NoResultsDialog from "../uiComponents/NoResultsDialog";

const LargeTooltip = withStyles({
  tooltip: {
    width: 610,
    maxWidth: "unset",
  },
})(Tooltip);

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const localeCancelLabelMap = {
  en: "Cancel",
  nl: "Annuleren",
};
const localeClearLabelMap = {
  en: "Clear",
  nl: "Verwijderen",
};

const NewProjectTypeProduct = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const { t } = useTranslation();
  const theme = useTheme();
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [openEndDate, setopenEndDate] = React.useState(false);

  const COLORS = [bdtheme.palette.primary.main, "rgba(224, 224, 224, 1)"];
  const data = newProjectDoughnutData(props.progressStep);
  const {
    projectProduct,
    setProjectProduct,
    projectType,
    setNewProjectId,
    industries,
    setIndustries,
    newProjectId,
  } = useContext(NewProjectContext);
  const { setProjectAddedId } = useContext(DashboardContext);
  const [loading, setLoading] = React.useState(false);
  const [userContext] = useContext(UserContext);
  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);
  const [focusValues, setFocusValues] = useState(props.focusOptions);
  const [vals, setVals] = useState([]);

  const handleChangeFocus = (e, opt) => {
    opt.checked = e.target.checked;
    setFocusValues([...focusValues]);
    if (e.target.checked) {
      vals.push(opt.value);
    } else {
      vals.splice(vals.indexOf(opt.value), 1);
    }
    setVals(vals);
    setProjectProduct({
      ...projectProduct,
      projectFocus: [...vals].join(";"),
    });
  };

  const [locale, setLocale] = useState("en");
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);

  const ROUTES = ["/client/newproject/choose", "/client/newproject/upload"];

  const handleNext = () => {
    setLoading(true);
    let vals = industries.map((a) => a.value);
    projectProduct.industrySector = vals.join(";");

    let body = {
      Project: projectProduct,
    };
    var requestBodyCreate = {
      Project: projectProduct,
      _id: newProjectId,
    };

    var apiEndPoint =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: "post",
      url: apiEndPoint + "/projectscan/projectscan",
      data: body,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (res.data.subsidies.length > 0) {
          axios({
            method: "post",
            url: apiEndPoint + "/project/addProject",
            data: requestBodyCreate,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userContext.token}`,
            },
          })
            .then((res) => {
              setLoading(false);
              setNewProjectId(res.data.projectId);
              setProjectAddedId(res.data.projectId);
              props.setProgressStep(props.progressStep + 1);
              history.push(ROUTES[1]);
            })
            .catch((error) => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          setNosubsidies(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectProduct.name &&
      projectProduct.idea &&
      projectProduct.problemStatement &&
      projectProduct.startDate != null &&
      projectProduct.endDate != null &&
      projectProduct.noOfRnDEmployees &&
      projectProduct.rndHours &&
      projectProduct.rndCosts &&
      projectProduct.activitiesCarriedOutBy &&
      industries.length > 0 &&
      projectProduct.newness &&
      projectProduct.projectFocus &&
      projectProduct.preTRLLevel &&
      projectProduct.postTRLLevel &&
      projectProduct.subsidyNeed &&
      projectProduct.projectTypeId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectProduct, industries]);

  useEffect(() => {
    setProjectProduct({
      ...projectProduct,
      projectTypeId: projectType,
      accountId: userContext.user.salesforceAccountId,
    });

    let vals1;
    if (projectProduct.industrySector) {
      let selVals = projectProduct.industrySector.split(";");
      vals1 = props.industryOptions.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setIndustries(vals1);
    }

    if (projectProduct.projectFocus) {
      let selectedVals = projectProduct.projectFocus.split(";");
      focusValues.map((val) => {
        if (selectedVals.length > 0 && selectedVals.indexOf(val.value) !== -1) {
          val.checked = true;
        } else {
          val.checked = false;
        }
        return val;
      });
      setVals(selectedVals);
    }
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Trl = () => {
    return (
      <>
        <Typography>{t("trl_options.title")}</Typography>
        <br />
        <Typography>{t("trl_options.trl_1")}</Typography>
        <Typography>{t("trl_options.trl_2")}</Typography>
        <Typography>{t("trl_options.trl_3")}</Typography>
        <Typography>{t("trl_options.trl_4")}</Typography>
        <Typography>{t("trl_options.trl_5")}</Typography>
        <Typography>{t("trl_options.trl_6")}</Typography>
        <Typography>{t("trl_options.trl_7")}</Typography>
        <Typography>{t("trl_options.trl_8")}</Typography>
        <Typography>{t("trl_options.trl_9")}</Typography>
      </>
    );
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justify="center" className={classes.root}>
        <Grid container item xs={12} justify="center" alignContent="center">
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            {/*<>TITLE</>*/}
            <Grid
              container
              item
              xs={12}
              justify="center"
              className="title-section"
            >
              <Grid item xs={12} lg={6} className={classes.title}>
                <Box mb={1}>
                  <Typography variant="h6">
                    {t("project-details.title")}
                  </Typography>
                </Box>
                <Box pb={3}>
                  <Typography variant="body2" className={classes.textDisabled}>
                    {t("project-details.subtitle")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
            {/*<>PROJECT</>*/}
            <Grid
              container
              item
              xs={12}
              lg={6}
              justify={isLarge ? "flex-start" : "center"}
              className={classes.subSection}
            >
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    className={classes.subTitle}
                  >
                    {t("project-details.project")}
                  </Typography>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="name"
                    name="name"
                    label={t("labels.project_name")}
                    variant="outlined"
                    helperText={t("helper_text.project_name")}
                    type="text"
                    fullWidth
                    autoFocus={isLargeScreen}
                    autoComplete="off"
                    value={projectProduct.name}
                    onChange={(e) => {
                      handleChange(e, projectProduct, setProjectProduct);
                    }}
                    inputProps={{
                      maxLength: 150,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="description1"
                    name="idea"
                    label={t("projectX.label2")}
                    variant="outlined"
                    multiline
                    rows={5}
                    helperText={t("helper_text.description1")}
                    fullWidth
                    autoComplete="off"
                    value={projectProduct.idea}
                    onChange={(e) => {
                      handleChange(e, projectProduct, setProjectProduct);
                    }}
                    inputProps={{
                      maxLength: 1000,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="description2"
                    name="problemStatement"
                    label={t("projectX.label3")}
                    variant="outlined"
                    multiline
                    rows={5}
                    helperText={t("helper_text.description2")}
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={projectProduct.problemStatement}
                    onChange={(e) => {
                      handleChange(e, projectProduct, setProjectProduct);
                    }}
                    inputProps={{
                      maxLength: 1000,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isLarge && (
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            )}
            {/*<>DATES</>*/}
            <Grid
              container
              item
              xs={12}
              lg={6}
              justify={isLarge ? "flex-end" : "center"}
              className={classes.subSection}
            >
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    className={classes.subTitle}
                  >
                    {t("project-details.period")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[locale]}
                  >
                    <KeyboardDatePicker
                      className={classes.helperText + " " + classes.formControl}
                      onClick={() => setOpenStartDate(true)}
                      onClose={() => setOpenStartDate(false)}
                      open={openStartDate}
                      clearable
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      id="startDate"
                      name="startDate"
                      label={t("labels.start_date")}
                      cancelLabel={localeCancelLabelMap[locale]}
                      clearLabel={localeClearLabelMap[locale]}
                      helperText={t("helper_text.start_date")}
                      fullWidth
                      onChange={(date) =>
                        setProjectProduct({
                          ...projectProduct,
                          startDate: date,
                        })
                      }
                      value={projectProduct.startDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[locale]}
                  >
                    <KeyboardDatePicker
                      className={classes.helperText + " " + classes.formControl}
                      onClick={() => setopenEndDate(true)}
                      onClose={() => setopenEndDate(false)}
                      open={openEndDate}
                      clearable
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      id="endDate"
                      name="endDate"
                      label={t("labels.end_date")}
                      cancelLabel={localeCancelLabelMap[locale]}
                      clearLabel={localeClearLabelMap[locale]}
                      helperText={t("helper_text.end_date")}
                      fullWidth
                      onChange={(date) =>
                        setProjectProduct({
                          ...projectProduct,
                          endDate: date,
                        })
                      }
                      value={projectProduct.endDate}
                      minDate={projectProduct.startDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                {/*<>RESEARCH</>*/}
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitle}
                    >
                      {t("project-details.randd")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.helperText + " " + classes.formControl}
                      id="randdEmployees"
                      name="noOfRnDEmployees"
                      label={"R&D " + t("labels.employees")}
                      variant="outlined"
                      helperText={t("helper_text.employees")}
                      fullWidth
                      autoComplete="off"
                      value={projectProduct.noOfRnDEmployees}
                      onChange={(e) => {
                        handleChange(e, projectProduct, setProjectProduct);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatPositives,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      label={"R&D " + t("labels.hours")}
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <InputLabel id="randdHours-label" shrink={true}>
                        {"R&D " + t("labels.hours")}
                      </InputLabel>
                      <Select
                        id="randdHours"
                        name="rndHours"
                        input={
                          <OutlinedInput
                            notched
                            label={"R&D " + t("labels.hours")}
                          />
                        }
                        value={projectProduct.rndHours}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.rdHoursOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      label={"R&D " + t("labels.costs")}
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <InputLabel id="randdCost-label" shrink={true}>
                        {"R&D " + t("labels.costs")}
                      </InputLabel>
                      <Select
                        id="randdCost"
                        name="rndCosts"
                        input={
                          <OutlinedInput
                            notched
                            label={"R&D " + t("labels.costs")}
                          />
                        }
                        value={projectProduct.rndCosts}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.rdCostOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {t("project-details.randd_activity")}
                      </FormLabel>
                      <RadioGroup
                        aria-label={"randdActivity"}
                        name={"activitiesCarriedOutBy"}
                        value={projectProduct.activitiesCarriedOutBy}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.rdActivitiesOptions.map((tile, i) => (
                          <FormControlLabel
                            key={i}
                            value={tile.value}
                            control={<Radio />}
                            label={tile.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {!isLarge && (
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              )}
              {/*<>MARKET</>*/}
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-end" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitle}
                    >
                      {t("project-details.market")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="industries"
                      value={industries}
                      options={props.industryOptions}
                      getOptionLabel={(option) => option.label}
                      defaultValue={[]}
                      className={
                        classes.formControl + " " + classes.autocompleteMultiple
                      }
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t("labels.industry")}
                          placeholder=""
                          className={classes.helperText}
                          helperText={t("helper_text.select2")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(e, values) => {
                        setIndustries(values);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      label={t("project-details.new")}
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <InputLabel id="newProduct-label" shrink={true}>
                        {t("project-details.new")}
                      </InputLabel>
                      <Select
                        id="newProduct"
                        name="newness"
                        input={
                          <OutlinedInput
                            notched
                            label={t("project-details.new")}
                          />
                        }
                        value={projectProduct.newness}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.newProductOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">{"Focus"}</FormLabel>
                      <FormGroup>
                        {focusValues.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                checked={option.checked}
                                onChange={(e) => handleChangeFocus(e, option)}
                                name={option.label}
                              />
                            }
                            label={option.label}
                          />
                        ))}
                      </FormGroup>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitle}
                    >
                      {t("project-details.subsidie")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <TextField
                        id="amount"
                        name="subsidyNeed"
                        variant="outlined"
                        type="currency"
                        autoComplete="off"
                        placeholder="€"
                        label={t("labels.subsidie")}
                        value={projectProduct.subsidyNeed}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      <FormHelperText id="outlined-amount-helper-text">
                        {t("helper_text.subsidie")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      label={t("labels.pre")}
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <InputLabel id="trlPre-label" shrink={true}>
                        {t("labels.pre")}
                      </InputLabel>
                      <Select
                        id="trlPre"
                        name="preTRLLevel"
                        input={
                          <OutlinedInput notched label={t("labels.pre")} />
                        }
                        value={projectProduct.preTRLLevel}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.trlOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {isLargeScreen && (
                        <LargeTooltip
                          title={<Trl />}
                          aria-label="info"
                          placement="top-start"
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: theme.palette.text.secondary,
                              position: "absolute",
                              top: 14,
                              right: "-36px",
                            }}
                            className={classes.activeIconState}
                          />
                        </LargeTooltip>
                      )}
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      label={t("labels.post")}
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <InputLabel id="trlPost-label" shrink={true}>
                        {t("labels.post")}
                      </InputLabel>
                      <Select
                        id="trlPost"
                        name="postTRLLevel"
                        input={
                          <OutlinedInput notched label={t("labels.post")} />
                        }
                        value={projectProduct.postTRLLevel}
                        onChange={(e) => {
                          handleChange(e, projectProduct, setProjectProduct);
                        }}
                      >
                        {props.trlOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {isLargeScreen && (
                        <LargeTooltip
                          title={<Trl />}
                          aria-label="info"
                          placement="top-start"
                        >
                          <InfoOutlinedIcon
                            style={{
                              color: theme.palette.text.secondary,
                              position: "absolute",
                              top: 14,
                              right: "-36px",
                            }}
                            className={classes.activeIconState}
                          />
                        </LargeTooltip>
                      )}
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
          </Grid>
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justify="center"
            className={classes.section + " " + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justify={isLargeScreen ? "space-between" : "flex-end"}
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t("buttons.back")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t("buttons.next")}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t("select_subsidies.no_subsidies")}
        text={t("select_subsidies.no_subsidies_text")}
        image={
          <>
            <SadFace />
          </>
        }
        alignment="center"
        cancel={t("buttons.cancel_2")}
        confirm={t("dialog.to_dashboard")}
        handleConfirm={props.handleConfirm}
      />
    </>
  );
};

export default withRouter(NewProjectTypeProduct);
