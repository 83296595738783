import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseLayout from "../Layouts/TopBarLevel1Layout";
import NewProjectSidebar from "../Components/NewProject/NewProjectSidebar";
import NewProjectSubsidiesList from "../Components/NewProject/NewProjectSubsidiesList";
import { NewProjectContext } from "../Contexts/NewProject";

const NewProjectSubsidies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [progressStep, setProgressStep] = useState(3);
  const [projectProgress, setProjectProgress] = useState(3);
  const center = Math.round((100 / 4) * progressStep);
  const {
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setProjectSocial,
    setPercentage,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
  } = useContext(NewProjectContext);

  const handleConfirm = () => {
    setProjectProgress(0);
    setPercentage(0);
    setProjectType("");
    setNewProjectId("");
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setProjectProduct({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      noOfRnDEmployees: "",
      rndHours: "",
      rndCosts: "",
      activitiesCarriedOutBy: "",
      industrySector: "",
      newness: "",
      projectFocus: "",
      preTRLLevel: "",
      postTRLLevel: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectInt({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      countriesActive: "",
      countriesExpanding: "",
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectBusiness({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      activities: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectEmployees({
      name: "",
      idea: "",
      problemStatement: "",
      learningWorkPrograms: "",
      investmentInEmployees: "",
      hiredWorkersAtDisadvantage: "",
      willHireWorkersAtDisadvantage: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    setProjectSocial({
      name: "",
      idea: "",
      problemStatement: "",
      startDate: null,
      endDate: null,
      projectThemes: "",
      projectTargetGroup: "",
      subsidyNeed: "",
      projectTypeId: "",
      accountId: "",
      _id: "",
    });
    history.push("/client/app/dashboard");
  };

  useEffect(() => {
    window.document.title = t("page_title.select_subsidies");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseLayout
        main={
          <>
            <NewProjectSubsidiesList
              projectProgress={projectProgress}
              setProjectProgress={setProjectProgress}
              progressStep={progressStep}
              setProgressStep={setProgressStep}
              center={`${center}%`}
            />
          </>
        }
        sidebar={
          <NewProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        dialog
        handleConfirm={handleConfirm}
        text={t("dialog.quit_new_project")}
        title={t("projects.card_new")}
      ></BaseLayout>
    </>
  );
};

export default NewProjectSubsidies;
