import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Divider,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckIcon from '@material-ui/icons/Check';
import {
  planOptionss,
  feeOptionss,
  supportOptionss,
} from '../../data/planOptions';
import { useStyles } from './styles';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';

const ChooseYourPlan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep);

  const planOptions = planOptionss();
  const feeOptions = feeOptionss();
  const supportOptions = supportOptionss();

  const ROUTES = ['/client/selectsubsidies', '/client/checkout'];

  const handleNext = () => {
    props.setProgressStep(props.progressStep + 1);
    history.push(ROUTES[1]);
  };

  const handleBack = () => {
    props.setProgressStep(props.progressStep - 1);
    history.push(ROUTES[0]);
  };

  return (
    <>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
        >
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            {/*<>TITLE</>*/}
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className="title-section"
            >
              <Grid item xs={12} className={classes.titlePlan}>
                <Typography variant="h6">
                  {t('plan.title') || 'Plan'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className="planSection"
              style={{}}
            >
              <Grid container item xs={12} className={classes.planContainer}>
                {planOptions.map((option, i) => (
                  <Grid
                    container
                    item
                    xs={12}
                    lg={4}
                    className={classes.planCardGridItem}
                    key={option.title}
                  >
                    <Card
                      variant="outlined"
                      className={
                        props.plan === i
                          ? classes.planCard + ' ' + classes.planCardActive
                          : classes.planCard
                      }
                    >
                      <CardContent style={{}}>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.planSection}>
                            <Typography
                              style={
                                props.plan === i
                                  ? {
                                      color: '#fff',
                                      fontWeight: 'bold',
                                      fontSize: 34,
                                    }
                                  : { fontWeight: 'bold', fontSize: 34 }
                              }
                              color={props.plan === i ? '' : 'secondary'}
                            >
                              {planOptions[i].title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider
                              style={
                                props.plan === i
                                  ? {
                                      backgroundColor: 'rgba(255,255,255,0.38)',
                                    }
                                  : {}
                              }
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.planSection}
                          >
                            <Grid item xs={12}>
                              <Box my={2.5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {t('plan.fee_title')}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={7}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  €1.000 - €49.999
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {feeOptions[i].fee1}%
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={7}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  €50.000 - €149.999
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {feeOptions[i].fee2}%
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={7}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  €150.000 - €349.999
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {feeOptions[i].fee3}%
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={7}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  &gt; €350.000
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {t('plan.tarief')}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider
                                style={
                                  props.plan === i
                                    ? {
                                        backgroundColor:
                                          'rgba(255,255,255,0.38)',
                                        marginTop: 24,
                                      }
                                    : { marginTop: 24 }
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={
                              classes.planSection + ' ' + classes.lastSection
                            }
                          >
                            <List>
                              {supportOptions[i].options.map((item, index) => (
                                <ListItem key={item}>
                                  <ListItemIcon>
                                    <CheckIcon
                                      color={
                                        props.plan === i
                                          ? 'secondary'
                                          : 'primary'
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={item}
                                    primaryTypographyProps={{
                                      variant: 'body2',
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            className={
                              classes.planSection + ' ' + classes.planButton
                            }
                          >
                            <IconButton
                              disableFocusRipple={true}
                              disableRipple={true}
                              onClick={() => {
                                props.setPlan(i);
                              }}
                            >
                              {props.plan === i ? (
                                <CheckCircleRoundedIcon
                                  color="secondary"
                                  style={{ height: 40, width: 40 }}
                                />
                              ) : (
                                <>
                                  <RadioButtonUncheckedRoundedIcon
                                    style={{ height: 40, width: 40 }}
                                  />
                                </>
                              )}
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className={classes.section + ' ' + classes.sectionMobile}
            >
              <Grid container item xs={12} className={classes.sectionButtons}>
                <Grid item xs={12}>
                  <Box mt={!isSmallScreen ? 6 : 0}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.buttons}
                  style={
                    isLargeScreen
                      ? {}
                      : {
                          flexWrap: 'nowrap',
                          justifyContent: 'space-between',
                        }
                  }
                >
                  <>
                    {!isLargeScreen && (
                      <Grid item>
                        <div style={{ height: 40, width: 40 }}>
                          <NivoDoughnut
                            data={data}
                            center={props.center}
                            colors={COLORS}
                            smallFont
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      justifyContent={
                        isLargeScreen ? 'space-between' : 'flex-end'
                      }
                    >
                      <Button
                        style={{ marginRight: 16 }}
                        startIcon={<KeyboardArrowLeftIcon />}
                        onClick={handleBack}
                      >
                        {t('buttons.back')}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={handleNext}
                      >
                        {t('buttons.next')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* LOGOUT */}

      <ConfirmationDialog
        openDialog={props.openDialog}
        setOpenDialog={props.setOpenDialog}
        title={t('logout')}
        text={t('dialog.text2')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
      />
    </>
  );
};

export default ChooseYourPlan;
