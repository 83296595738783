import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../Components/uiComponents/CustomDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    height: 72,
    [theme.breakpoints.down('767')]: {
      height: 64,
    },
  },
  appbar: {
    zIndex: '5',
    height: 72,
    boxShadow: 'none !important',
    borderBottom: '1px solid #e0e0e0',
    '& .MuiTypography-root': {
      color: '#fff',
    },
    [theme.breakpoints.down('767')]: {
      height: 64,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    height: '100%',
    '&.MuiToolbar-gutters': {
      paddingLeft: 8,
      paddingRight: 24,
      [theme.breakpoints.down('767')]: {
        paddingLeft: 0,
        paddingRight: 16,
      },
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 48,
      [theme.breakpoints.down('767')]: {
        marginLeft: 8,
      },
    },
  },
  main: {
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('767')]: {
      minHeight: 'calc(100% - 64px)',
      overflowY: 'auto',
    },
  },
}));

const TopBarLevel1Layout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleBackButton = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <AppBar color="primary" className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
              <Typography
                variant="h6"
                noWrap
                style={
                  isLargeScreen
                    ? { paddingLeft: 16, paddingRight: 24, fontWeight: 'bold' }
                    : { paddingLeft: 16, paddingRight: 16, fontWeight: 'bold' }
                }
              >
                <IconButton
                  //color="primary"
                  size="small"
                  style={
                    isLargeScreen
                      ? { marginRight: 24, color: '#fff' }
                      : { marginRight: 16, color: '#fff' }
                  }
                  onClick={handleBackButton}
                >
                  <ArrowBackIcon
                    fontSize={isLargeScreen ? 'large' : 'default'}
                  />
                </IconButton>
                {props.title}
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12} className={classes.main} lg={props.sidebar ? 9 : 12}>
          {props.main}
        </Grid>
        {props.sidebar && isLargeScreen && (
          <Grid item xs={12} lg={3} className={classes.sidebar}>
            {props.sidebar}
          </Grid>
        )}
        {props.dialog && (
          <Grid item xs={12} className={classes.dialog}>
            <CustomDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              title={t('buttons.cancel')}
              text={props.text}
              cancel={t('buttons.cancel_2')}
              handleBackButton={props.handleBackButton}
              confirm={t('buttons.continue')}
              handleConfirm={props.handleConfirm}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TopBarLevel1Layout;
