import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const CustomDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    title,
    text,
    cancel,
    confirm,
    handleConfirm,
  } = props;
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={'body1'} color="textPrimary" component="span">
              {text}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              if (props.handleClose) {
                props.handleClose();
              }
            }}
            color="primary"
          >
            {cancel}
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleConfirm();
              if (props.handleClose) {
                props.handleClose();
              }
            }}
            color="primary"
          >
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
