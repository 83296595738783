import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Divider,
  Box,
  Typography,
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTableStyles as useStyles } from '../Onboarding/styles';
import i18n from '../../JS/i18n';
import { useMediaQuery } from 'react-responsive';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NumberFormat from 'react-number-format';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { useTranslation } from 'react-i18next';
import { NewProjectContext } from '../../Contexts/NewProject';
import { DashboardContext } from '../../Contexts/DashboardContext';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';

function createData(
  SubsidyId,
  SubsidyName,
  SubsidyAmount,
  successRate,
  SubsidyDescription,
) {
  return {
    SubsidyId,
    SubsidyName,
    SubsidyAmount,
    successRate,
    SubsidyDescription,
  };
}

function EnhancedTableHead() {
  const headCells = [
    {
      id: 'SubsidyId',
      width: '0%',
    },
    {
      id: 'SubsidyName',
      numeric: false,
      disablePadding: true,
      label: i18n.t('select_subsidies.subsidies'),
      width: '50%',
    },
    {
      id: 'SubsidyAmount',
      numeric: true,
      disablePadding: false,
      label: i18n.t('select_subsidies.amount'),
      width: '20%',
    },
    {
      id: 'successRate',
      numeric: true,
      disablePadding: false,
      label: i18n.t('select_subsidies.success'),
      width: '20%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ width: headCell.width, fontWeight: 'bold' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const SubsidiesList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep);
  const {
    newProjectId,
    setPercentage,
    setProjectProgress,
    setProjectType,
    setProjectProduct,
    setProjectInt,
    setProjectBusiness,
    setProjectEmployees,
    setProjectSocial,
    setNewProjectId,
    setIndustries,
    setActive,
    setExpand,
    setActivitiesInt,
    setActivities,
    setThemes,
    setTarget,
  } = useContext(NewProjectContext);
  const { setNewProjectAdded, newProjectPathname } =
    useContext(DashboardContext);
  const [userContext] = useContext(UserContext);
  const ROUTES = [
    '/client/newproject/upload',
    `/client/app/${newProjectPathname}`,
  ];

  const handleNext = () => {
    setProjectProgress(0);
    setPercentage(0);
    setProjectType('');
    setNewProjectId('');
    setIndustries([]);
    setActive([]);
    setExpand([]);
    setActivitiesInt([]);
    setActivities([]);
    setThemes([]);
    setTarget([]);
    setProjectProduct({
      Project_Name__c: '',
      Idea__c: '',
      Problem_Statement__c: '',
      Start_Date__c: null,
      End_Date__c: null,
      No_of_R_D_employees_on_the_project__c: '',
      R_D_Hours_of_the_Project__c: '',
      R_D_Cost_of_the_Project__c: '',
      Activities_carried_out_by__c: '',
      Industry_Sector__c: '',
      Newness__c: '',
      Project_Focus__c: '',
      TRL_Level__c: '',
      Post_TRL__c: '',
      Subsidy_needed__c: '',
      RecordTypeId: '',
      Account__c: '',
      Id: '',
    });
    setProjectInt({
      Project_Name__c: '',
      Idea__c: '',
      Problem_Statement__c: '',
      Start_Date__c: null,
      End_Date__c: null,
      Where_are_you_internationally_active__c: '',
      Where_countries_do_you_plan_to_expand__c: '',
      What_activities__c: '',
      Subsidy_needed__c: '',
      RecordTypeId: '',
      Account__c: '',
      Id: '',
    });
    setProjectBusiness({
      Project_Name__c: '',
      Idea__c: '',
      Problem_Statement__c: '',
      Start_Date__c: null,
      What_activities__c: '',
      Subsidy_needed__c: '',
      RecordTypeId: '',
      Account__c: '',
      Id: '',
    });
    setProjectEmployees({
      Project_Name__c: '',
      Idea__c: '',
      Problem_Statement__c: '',
      Learning_Work_Programs__c: '',
      Invest_in_Employees__c: '',
      Hired_workers_at_disadvatage__c: '',
      Planning_to_hire_workers_at_disadvantage__c: '',
      Subsidy_needed__c: '',
      RecordTypeId: '',
      Account__c: '',
      Id: '',
    });
    setProjectSocial({
      Project_Name__c: '',
      Idea__c: '',
      Problem_Statement__c: '',
      Start_Date__c: null,
      End_Date__c: null,
      Project_themes__c: '',
      Project_target_group__c: '',
      Subsidy_needed__c: '',
      RecordTypeId: '',
      Account__c: '',
      Id: '',
    });
    setNewProjectAdded(true);
    history.push(ROUTES[1]);
  };
  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setLoading(true);
    let data = [];
    var requestBody = {
      ProjectId: newProjectId,
    };
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/projectscan/showPotential',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.subsidies.forEach((item, i) => {
          data.push(
            createData(
              item.SubsidyId,
              item.SubsidyName,
              item.SubsidyAmount,
              item.successRate,
              item.SubsidyDescription
                ? item.SubsidyDescription.replace(/(<([^>]+)>)/gi, '    ')
                : '',
            ),
          );
        });
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div
        className={classes.root}
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container className={classes.container}>
          <Grid container item xs={12} className={classes.header}>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="h6"
              >
                {t('new_project_subsidies.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                variant="body2"
                id="tableSubtitle"
              >
                {t('new_project_subsidies.subtitle')}
              </Typography>
              <Typography
                className={classes.total}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {rows.length} {t('select_subsidies.matched')}
              </Typography>
            </Grid>
          </Grid>
          {/* DESKTOP */}
          {!isSmallScreen ? (
            <Grid
              item
              xs={12}
              className={classes.content}
              style={rows.length <= 10 ? { borderBottom: 'none' } : {}}
            >
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead classes={classes} rowCount={rows.length} />
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.SubsidyId}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Tooltip
                                classes={{
                                  tooltip: classes.customWidthTooltip,
                                  popper: classes.customPositionTooltip,
                                }}
                                title={
                                  row.SubsidyDescription
                                    ? row.SubsidyDescription.substring(
                                        3,
                                        row.SubsidyDescription.length - 4,
                                      )
                                    : 'No description available'
                                }
                                placement="top-start"
                              >
                                <Typography className={classes.subsidyTitle}>
                                  {row.SubsidyName}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 16 }}>
                              {row.SubsidyAmount > 0 && (
                                <NumberFormat
                                  value={row.SubsidyAmount}
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  isNumericString
                                  prefix={'€'}
                                />
                              )}
                              {row.SubsidyAmount <= 0 && ' - '}
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: 16 }}>
                              {(row.successRate <= 0 ||
                                row.successRate == null) &&
                                ' - '}
                              {row.successRate > 0 &&
                                row.successRate &&
                                row.successRate + '%'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows.length > 10 && (
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.boxTableSubsidies}>
              {rows.map((row, index) => {
                return (
                  <>
                    <Accordion
                      expanded={expanded === 'panel' + index}
                      onChange={handleExpanded('panel' + index)}
                      style={{
                        boxShadow: 'none',
                      }}
                      key={row.SubsidyId}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {row.SubsidyName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                        >
                          {t('labels.description')}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          style={{ marginBottom: 32 }}
                        >
                          {row.SubsidyDescription
                            ? row.SubsidyDescription.substring(
                                3,
                                row.SubsidyDescription.length - 4,
                              )
                            : ''}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                        >
                          {t('subsidies.potential')}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          style={{ marginBottom: 32 }}
                        >
                          {row.SubsidyAmount > 0 ? (
                            <NumberFormat
                              value={row.SubsidyAmount}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              isNumericString
                              prefix={'€'}
                            />
                          ) : (
                            '-'
                          )}
                        </Typography>
                        <Typography
                          className={classes.secondaryHeading}
                          variant="overline"
                        >
                          {t('subsidies.success')}
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                          {row.successRate > 0 ? row.successRate + '%' : '-'}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
            </Grid>
          )}
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Box mt={!isSmallScreen ? 6 : 0}>
                  <Divider />
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={
                      isLargeScreen ? 'space-between' : 'flex-end'
                    }
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      label="Vorige"
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t('buttons.back')}
                    </Button>

                    <Button
                      color="secondary"
                      variant="contained"
                      label="Volgende"
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                    >
                      {t('buttons.checkout')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SubsidiesList;
