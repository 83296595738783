import React, { useState, useEffect, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Button,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import nlLocale from "date-fns/locale/nl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { theme as bdtheme } from "../../JS/BrightDesignTheme";
import { doughnutData } from "../../data/picklistOptions";
import NivoDoughnut from "../uiComponents/NivoDoughnut";
import { useMediaQuery } from "react-responsive";
import { useStyles } from "./styles";
import { ReactComponent as SadFace } from "../../assets/Sad_face_TwoColor.svg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { handleChange, NumberFormatCustom } from "../../JS/FormControls";
import {
  cleanProjectBusiness,
  cleanProjectEmployees,
  cleanProjectProduct,
  cleanProjectSocial,
} from "../../JS/HelperFunctions";
import { OnboardingContext } from "../../Contexts/Onboarding";
import { UserContext } from "../../Contexts/UserContext";
import axios from "axios";
import Loader from "../uiComponents/Loader";
import ReactGA from "react-ga";
import ConfirmationDialog from "../uiComponents/ConfirmationDialog";
import NoResultsScreen from "../uiComponents/NoResultsScreen";

const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const localeCancelLabelMap = {
  en: "Cancel",
  nl: "Annuleren",
};
const localeClearLabelMap = {
  en: "Clear",
  nl: "Verwijderen",
};

const ProjectTypeInternational = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, "rgba(224, 224, 224, 1)"];
  const data = doughnutData(props.progressStep);

  const { openDialog, setOpenDialog } = props;
  const {
    projectType,
    projectInt,
    setProjectInt,
    setNewProjectId,
    active,
    setActive,
    expand,
    setExpand,
    activitiesInt,
    setActivitiesInt,
    setActivities,
    setProjectBusiness,
    setProjectEmployees,
    setProjectProduct,
    setIndustries,
    setProjectSocial,
    setThemes,
    setTarget,
  } = useContext(OnboardingContext);

  const [loading, setLoading] = useState(false);
  const [userContext] = useContext(UserContext);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setopenEndDate] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  const [locale, setLocale] = useState("en");
  const selectLocale = React.useCallback((locale) => {
    setLocale(i18n.languages[0]);
  }, []);

  const ROUTES = ["/client/choose", "/client/upload"];

  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError(false);
  };

  const handleNext = () => {
    setLoading(true);

    let vals1 = active.map((a) => a.value);
    let vals2 = expand.map((a) => a.value);
    let vals3 = activitiesInt.map((a) => a.value);

    projectInt.countriesActive = vals1.join(";");
    projectInt.countriesExpanding = vals2.join(";");
    projectInt.activities = vals3.join(";");

    let body = {
      Project: projectInt,
    };

    var apiEndPoint =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: "post",
      url: apiEndPoint + "/projectscan/projectscan",
      data: body,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (res.data.subsidies.length > 0) {
          axios({
            method: "post",
            url: apiEndPoint + "/projectscan/addProject",
            data: body,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userContext.token}`,
            },
          })
            .then((res) => {
              setLoading(false);
              setNewProjectId(res.data.projectId);
              ReactGA.event({
                category: "Onboarding",
                action: "Project Completed",
              });
              cleanProjectSocial(setProjectSocial, setThemes, setTarget);
              cleanProjectEmployees(setProjectEmployees);
              cleanProjectProduct(setProjectProduct, setIndustries);
              cleanProjectBusiness(setActivities, setProjectBusiness);
              history.push(ROUTES[1]);
            })
            .catch((error) => {
              setLoading(false);
              setShowError(true);
            });
        } else {
          setLoading(false);
          setNosubsidies(true);
          setDisabled(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      setNosubsidies(false);
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectInt.name &&
      projectInt.idea &&
      projectInt.problemStatement &&
      projectInt.startDate != null &&
      projectInt.endDate != null &&
      active.length > 0 &&
      expand.length > 0 &&
      activitiesInt.length > 0 &&
      projectInt.subsidyNeed &&
      projectInt.projectTypeId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectInt, active, expand, activitiesInt]);

  useEffect(() => {
    setProjectInt({
      ...projectInt,
      projectTypeId: projectType,
    });

    let vals1, vals2, vals3;
    if (projectInt.countriesActive) {
      let selVals = projectInt.countriesActive.split(";");
      vals1 = props.countryList.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setActive(vals1);
    }

    if (projectInt.countriesExpanding) {
      let selVals = projectInt.countriesExpanding.split(";");
      vals2 = props.countryList.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setExpand(vals2);
    }

    if (projectInt.activities) {
      let selVals = projectInt.activities.split(";");
      vals3 = props.internationalActivities.filter(
        (opt) => selVals.indexOf(opt.value) !== -1
      );
      setActivitiesInt(vals3);
    }
    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justify="center" className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignContent="center"
          className={
            nosubsidies
              ? classes.container + " " + classes.containerNoSubsidies
              : classes.container
          }
        >
          {!nosubsidies ? (
            <>
              {/*<>SECTION</>*/}
              <Grid container item xs={12} className={classes.section}>
                {/*<>TITLE</>*/}
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  className="title-section"
                >
                  <Grid item xs={12} lg={6} className={classes.title}>
                    <Box mb={1}>
                      <Typography variant="h6">
                        {t("project-details.title")}
                      </Typography>
                    </Box>
                    <Box pb={3}>
                      <Typography
                        variant="body2"
                        className={classes.textDisabled}
                      >
                        {t("project-details.subtitle")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}></Grid>
                </Grid>
                {/*<>PROJECT</>*/}
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  justify={isLarge ? "flex-start" : "center"}
                  className={classes.subSection}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        className={classes.subTitle}
                      >
                        {t("project-details.project")}
                      </Typography>
                      <TextField
                        className={
                          classes.helperText + " " + classes.formControl
                        }
                        id="name"
                        name="name"
                        label={t("labels.project_name")}
                        variant="outlined"
                        helperText={t("helper_text.project_name")}
                        type="text"
                        fullWidth
                        autoFocus={isLargeScreen}
                        autoComplete="off"
                        value={projectInt.name}
                        onChange={(e) => {
                          handleChange(e, projectInt, setProjectInt);
                        }}
                        inputProps={{
                          maxLength: 150,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={
                          classes.helperText + " " + classes.formControl
                        }
                        id="description1"
                        name="idea"
                        label={t("projectX.label2")}
                        variant="outlined"
                        multiline
                        rows={5}
                        helperText={t("helper_text.description1")}
                        fullWidth
                        autoComplete="off"
                        value={projectInt.idea}
                        onChange={(e) => {
                          handleChange(e, projectInt, setProjectInt);
                        }}
                        inputProps={{
                          maxLength: 1000,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={
                          classes.helperText + " " + classes.formControl
                        }
                        id="description2"
                        name="problemStatement"
                        label={t("projectX.label3")}
                        variant="outlined"
                        multiline
                        rows={5}
                        helperText={t("helper_text.description2")}
                        type="text"
                        fullWidth
                        autoComplete="off"
                        value={projectInt.problemStatement}
                        onChange={(e) => {
                          handleChange(e, projectInt, setProjectInt);
                        }}
                        inputProps={{
                          maxLength: 1000,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isLarge && (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                )}
                {/*<>DATES</>*/}
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  justify={isLarge ? "flex-end" : "center"}
                  className={classes.subSection}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        className={classes.subTitle}
                      >
                        {t("project-details.period")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeMap[locale]}
                      >
                        <KeyboardDatePicker
                          onClick={() => setOpenStartDate(true)}
                          onClose={() => setOpenStartDate(false)}
                          open={openStartDate}
                          className={
                            classes.helperText + " " + classes.formControl
                          }
                          clearable
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="startDate"
                          name="startDate"
                          label={t("labels.start_date")}
                          cancelLabel={localeCancelLabelMap[locale]}
                          clearLabel={localeClearLabelMap[locale]}
                          helperText={t("helper_text.start_date")}
                          fullWidth
                          onChange={(date) =>
                            setProjectInt({
                              ...projectInt,
                              startDate: date,
                            })
                          }
                          value={projectInt.startDate}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeMap[locale]}
                      >
                        <KeyboardDatePicker
                          onClick={() => setopenEndDate(true)}
                          onClose={() => setopenEndDate(false)}
                          open={openEndDate}
                          className={
                            classes.helperText + " " + classes.formControl
                          }
                          clearable
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="endDate"
                          name="endDate"
                          label={t("labels.end_date")}
                          cancelLabel={localeCancelLabelMap[locale]}
                          clearLabel={localeClearLabelMap[locale]}
                          helperText={t("helper_text.end_date")}
                          fullWidth
                          onChange={(date) =>
                            setProjectInt({ ...projectInt, endDate: date })
                          }
                          value={projectInt.endDate}
                          minDate={projectInt.startDate}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
              {/*<>SECTION</>*/}
              <Grid container item xs={12} className={classes.section}>
                <Grid container item xs={12}>
                  {/*<> ACTIVE </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justify={isLarge ? "flex-start" : "center"}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="textSecondary"
                          className={classes.subTitle}
                        >
                          {t("project-details.active_countries")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={active}
                          id="activeCountries"
                          options={props.countryList}
                          getOptionLabel={(option) => option.label}
                          defaultValue={[]}
                          className={
                            classes.formControl +
                            " " +
                            classes.autocompleteMultiple
                          }
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={t(
                                "project-details.active_countries_label"
                              )}
                              placeholder=""
                              className={classes.helperText}
                              helperText={t("helper_text.select2")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e, values) => {
                            setActive(values);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<> EXPAND </>*/}
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justify={isLarge ? "flex-end" : "center"}
                    className={classes.subSection}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        className={classes.subTitle}
                      >
                        {t("project-details.expand_countries")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        value={expand}
                        id="expandCountries"
                        options={props.countryList}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          " " +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("project-details.expand_countries_label")}
                            placeholder=""
                            className={classes.helperText}
                            helperText={t("helper_text.select2")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setExpand(values);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Grid>
              </Grid>
              {/*<>SECTION</>*/}
              <Grid container item xs={12} className={classes.section}>
                <Grid container alignItems="flex-start" item xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justify={isLarge ? "flex-start" : "center"}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="textSecondary"
                          className={classes.subTitle}
                        >
                          {t("project-details.subsidie")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          className={
                            classes.helperText + " " + classes.formControl
                          }
                        >
                          <TextField
                            id="amount"
                            name="subsidyNeed"
                            variant="outlined"
                            type="currency"
                            autoComplete="off"
                            placeholder="€"
                            label={t("labels.subsidie")}
                            value={projectInt.subsidyNeed}
                            onChange={(e) => {
                              handleChange(e, projectInt, setProjectInt);
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                          <FormHelperText id="outlined-amount-helper-text">
                            {t("helper_text.subsidie")}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justify={isLarge ? "flex-end" : "center"}
                    className={classes.subSection}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          className={classes.subTitle}
                          style={isLarge ? {} : { display: "none" }}
                        >
                          &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={activitiesInt}
                          id="activities"
                          options={props.internationalActivities}
                          getOptionLabel={(option) => option.label}
                          defaultValue={[]}
                          className={
                            classes.formControl +
                            " " +
                            classes.autocompleteMultiple
                          }
                          disableCloseOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={t("labels.activities")}
                              placeholder=""
                              className={classes.helperText}
                              helperText={t("helper_text.select2")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e, values) => {
                            setActivitiesInt(values);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                item
                xs={12}
                className={classes.box}
                justify="center"
              >
                <NoResultsScreen
                  title={t("select_subsidies.no_subsidies")}
                  subtitle={t("select_subsidies.no_subsidies_text")}
                  content={<SadFace />}
                  titleStyle={{ paddingBottom: 8, justifyContent: "center" }}
                  subtitleStyle={{ paddingBottom: 8, justifyContent: "center" }}
                  contentStyle={{ paddingBottom: 0, justifyContent: "center" }}
                />
              </Grid>
            </>
          )}
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justify="center"
            className={classes.section + " " + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justify={isLargeScreen ? "space-between" : "flex-end"}
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t("buttons.back")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t("buttons.next")}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={showError}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ top: 80 }}
      >
        <Alert severity="error">
          {t("dialog.generic_error")}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
            style={{ marginLeft: 10 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>

      {/* LOGOUT */}

      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t("logout")}
        text={t("dialog.text2")}
        cancel={t("buttons.close")}
        confirm={t("buttons.continue")}
      />
    </>
  );
};

export default withRouter(ProjectTypeInternational);
