import i18n from '../JS/i18n';

export const projectTypeOptionss = () => {
  return [
    {
      id: '0123X000001GJUGQA4',
      label: i18n.t('choose_project.type1'),
      subLabel: i18n.t('choose_project.type1_text'),
    },
    {
      id: '0123X000001GJUCQA4',
      label: i18n.t('choose_project.type2'),
      subLabel: i18n.t('choose_project.type2_text'),
    },
    {
      id: '0123X000001GJUDQA4',
      label: i18n.t('choose_project.type3'),
      subLabel: i18n.t('choose_project.type3_text'),
    },
    {
      id: '0123X000001GJUEQA4',
      label: i18n.t('choose_project.type4'),
      subLabel: i18n.t('choose_project.type4_text'),
    },
    {
      id: '0123X000001GJUFQA4',
      label: i18n.t('choose_project.type5'),
      subLabel: i18n.t('choose_project.type5_text'),
    },
  ];
};
