import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import { useMediaQuery } from 'react-responsive';
import ProjectSidebar from '../Components/Onboarding/ProjectSidebar';
import ChooseYourPlan from '../Components/Onboarding/ChooseYourPlan';
import { OnboardingContext } from '../Contexts/Onboarding';
import { ReactComponent as SupportAgent } from '../assets/support_agent_black_36dp.svg';
import { useTheme } from '@material-ui/core/styles';

const ChoosePlan = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [progressStep, setProgressStep] = useState(11);
  const [projectProgress, setProjectProgress] = useState(4);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);
  const { projectType, setProjectType, plan, setPlan, selectedSubsidies } =
    useContext(OnboardingContext);

  React.useEffect(() => {
    window.document.title = t('page_title.plan');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedSubsidies.length === 0) {
    return <Redirect to="/client/selectsubsidies" />;
  }

  return (
    <>
      <BaseLayout
        main={
          <>
            <ChooseYourPlan
              projectType={projectType}
              setProjectType={setProjectType}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              progressStep={progressStep}
              setProgressStep={setProgressStep}
              projectProgress={projectProgress}
              setProjectProgress={setProjectProgress}
              plan={plan}
              setPlan={setPlan}
              center={`${center}%`}
            />
          </>
        }
        sidebar={
          <ProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        close={
          <>
            {isLargeScreen && (
              <>
                <SupportAgent
                  style={{
                    paddingRight: 8,
                    fill: theme.palette.secondary.main,
                  }}
                />
                <span>+31 (0)85 580 74 21</span>
              </>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {t('buttons.logout')}
            </Button>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default ChoosePlan;
