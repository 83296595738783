import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 24px',
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('767')]: {
      minHeight: 'calc(100% - 64px)',
      padding: '24px 16px',
    },
  },
  container: {
    maxWidth: 1056,
  },
  box: {},
  cardBox: {
    maxWidth: 516,
    '&:first-of-type': {
      '& > div': {
        padding: '0 12px 12px 0',
      },
      marginRight: 'auto',
      [theme.breakpoints.down('1279')]: {},
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        paddingRight: 0,
        paddingBottom: 12,
      },
    },
    '&:nth-of-type(2)': {
      '& > div': { padding: '0 0 12px 12px' },

      marginLeft: 'auto',
      [theme.breakpoints.down('1279')]: {},
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        paddingLeft: 0,
        paddingTop: 12,
        '& > div': {
          paddingBottom: '0px !important',
          [theme.breakpoints.down('400')]: {
            marginLeft: '-20px',
          },
        },
      },
    },
    '&:nth-of-type(3)': {
      '& > div': { padding: '12px 12px 0 0' },

      marginRight: 'auto',
      [theme.breakpoints.down('1279')]: {},
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        paddingRight: 0,
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    '&:nth-of-type(4)': {
      '& > div': { padding: '12px 0 0 12px' },

      marginLeft: 'auto',
      [theme.breakpoints.down('1279')]: {},
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        paddingLeft: 0,
        paddingTop: 12,
      },
    },
  },
  card: {
    maxWidth: 516,
    width: '100%',
    '& .MuiCardContent-root': {
      padding: 24,
      [theme.breakpoints.down('767')]: {
        padding: 16,
      },
    },
    '& .MuiCardActions-root': {
      padding: 24,
      [theme.breakpoints.down('767')]: {
        padding: 16,
      },
    },
  },
  rowContainer: {
    paddingBottom: 16,
  },
  filledIconPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 40,
    height: 40,
    padding: 6,
    color: '#ffffff',
  },
  filledIconWarning: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    width: 40,
    height: 40,
    padding: 6,
    color: '#ffffff',
  },
  iconContainer: {
    width: 40,
    paddingRight: 16,
  },
}));
