import React from 'react';
import { Typography } from '@material-ui/core';

const DialogText = (props) => {
  return (
    <>
      <Typography style={{ marginBottom: 20 }}>{props.text1}</Typography>
      <Typography style={{ marginBottom: 20 }}>{props.text2}</Typography>
      <Typography>{props.text3}</Typography>
    </>
  );
};

export default DialogText;
