import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { UserContext } from '../../Contexts/UserContext';
import Loader from './Loader';
import axios from 'axios';

const ConfirmationDialog = (props) => {
  const history = useHistory();
  const {
    openDialog,
    setOpenDialog,
    title,
    text,
    cancel,
    confirm,
    handleClose,
  } = props;
  const [userContext, setUserContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const logoutHandler = () => {
    setLoading(true);
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/users/logout',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        setOpenDialog(false);
        localStorage.setItem('token', null);
        localStorage.setItem('user', null);
        localStorage.setItem('logout', Date.now());
        setUserContext((oldValues) => {
          return { ...oldValues, user: null, token: null };
        });
        setLoading(false);
        history.push('/client/login');
      })
      .catch((error) => {
        setOpenDialog(false);
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Dialog
        open={openDialog}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={'body1'} color="textPrimary" component="span">
              {text}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            {cancel}
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleClose
                ? (window.location = 'https://itanks.eu/')
                : logoutHandler();
            }}
            color="primary"
            autoFocus
          >
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
