import React, { useContext } from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  InputLabel,
  OutlinedInput,
  Typography,
  Divider,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loader from '../uiComponents/Loader';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';
import CustomDialog from './CustomDialog';
import DialogText from './DialogText';
import { OnboardingContext } from '../../Contexts/Onboarding';
import axios from 'axios';
import ReactGA from 'react-ga';

import {
  sectorOptionsNl,
  sectorOptionsEn,
  rechtsvormOptionss,
  mkbOptionss,
  provinceOptions,
  amountOptions,
} from '../../data/picklistOptions';
import { NumberFormatPositives } from '../../JS/FormControls';

const CTAQuestions = ({
  center,
  progressStep,
  setProgressStep,
  answers,
  setAnswers,
  openDialog,
  setOpenDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const mkbOptions = mkbOptionss();
  const rechtsvormOptions = rechtsvormOptionss();
  const ROUTES = [
    '/client/subsidyscan/1',
    '/client/subsidyscan/2',
    '/client/subsidyscan/3',
    '/client/subsidyscan/4',
    '/client/subsidyscan/5',
    '/client/subsidyscan/6',
    '/client/makeaccount',
  ];
  const history = useHistory();
  const path = useLocation().pathname;
  const [loading, setLoading] = React.useState(false);
  const [disqualify, setDisqualify] = React.useState(false);
  const [sectorOptions, setSectorOptions] = React.useState([]);
  const {
    setSubsidiesAmount,
    setSubsidiesNumber,
    sectors,
    setSectors,
    setScanDone,
  } = useContext(OnboardingContext);
  const currentLanguage = i18n.languages[0];
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(progressStep);

  const handleNext = () => {
    ROUTES.forEach((route, i) => {
      if (route === path) {
        history.push(ROUTES[i + 1]);
      }
    });
  };

  const handleSubmit = () => {
    if (
      answers.Rechtsvorm__c === '1MANS = Eenmanszaak' &&
      (answers.Employees_Excluding_Founders__c === '0' ||
        answers.Employees_Excluding_Founders__c === '1')
    ) {
      setDisqualify(true);
      ReactGA.event({
        category: 'Onboarding',
        action: 'Disqualified',
        label: answers.Rechtsvorm__c	
      });
    } else if (answers.Investment_Needed__c === '<20K') {
      setDisqualify(true);
      ReactGA.event({
        category: 'Onboarding',
        action: 'Disqualified',
        label: answers.Rechtsvorm__c	
      });
    } else {
      setLoading(true);
      var requestBody = { Account: answers };
      var apiEndPoint =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_ENDPOINT
          : process.env.REACT_APP_API_ENDPOINT_PROD;
      axios({
        method: 'post',
        url: apiEndPoint + '/subsidyscan/showPotential',
        data: requestBody,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          setLoading(false);
          setScanDone(true);
          setSubsidiesNumber(res.data.subsidies);
          setSubsidiesAmount(res.data.amount);
          setProgressStep(progressStep + 1);
          history.push('/client/makeaccount');
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleBack = () => {
    if (progressStep !== 0) {
      history.goBack();
      setProgressStep(progressStep - 1);
    }
  };

  React.useEffect(() => {
    ROUTES.forEach((route, i) => {
      if (route === path) {
        setProgressStep(i);
        window.document.title = t('page_title.subsidyscan') + (i + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  React.useEffect(() => {
    if (currentLanguage === 'nl') {
      setSectorOptions(sectorOptionsNl);
    } else {
      setSectorOptions(sectorOptionsEn);
    }
    ReactGA.event({
      category: 'Onboarding',
      action: 'Scan Started',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (sectors.length > 0) {
      let sectorValues = sectors.map((a) => a.value);
      setAnswers({
        ...answers,
        acc_key_sectors__c: sectorValues.join(';'),
      });
    } else {
      setAnswers({
        ...answers,
        acc_key_sectors__c: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  React.useEffect(() => {
    ROUTES.forEach((route, i) => {
      if (path === route) {
        if (document.getElementById('q' + (i + 1))) {
          document.getElementById('q' + (i + 1)).classList.add('fade-enter');
          document
            .getElementById('q' + (i + 1))
            .classList.remove('fade-enter-active');
          setTimeout(() => {
            document
              .getElementById('q' + (i + 1))
              .classList.remove('fade-enter');
            document
              .getElementById('q' + (i + 1))
              .classList.add('fade-enter-active');
          }, 1);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        className={classes.root}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Switch>
              <Route exact path={ROUTES[0]}>
                <Grid
                  container
                  alignItems={'center'}
                  className={'question-content'}
                >
                  <Grid item xs={12} className={classes.question} id="q1">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      className={classes.title}
                    >
                      {t('questions.question1')}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      label="Rechtsvorm"
                      className={classes.formControl}
                    >
                      <InputLabel id="rechtsvorm-label" shrink={true}>
                        {t('labels.label1')}
                      </InputLabel>
                      <Select
                        id="rechtsvorm"
                        name="Rechtsvorm__c"
                        input={
                          <OutlinedInput notched label={t('labels.label1')} />
                        }
                        value={answers.Rechtsvorm__c}
                        onChange={(event) => {
                          setAnswers({
                            ...answers,
                            Rechtsvorm__c: event.target.value,
                          });
                        }}
                      >
                        {rechtsvormOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} className={classes.actions}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={
                        progressStep > 0 ? classes.buttons : classes.button
                      }
                    >
                      <>
                        {!isLargeScreen && (
                          <Grid item>
                            <div style={{ height: 40, width: 40 }}>
                              <NivoDoughnut
                                data={data}
                                center={center}
                                colors={COLORS}
                                smallFont
                              />
                            </div>
                          </Grid>
                        )}
                        <Grid item>
                          {progressStep > 0 && (
                            <Button
                              disableRipple
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                          )}
                          <Button
                            color="secondary"
                            variant="contained"
                            endIcon={<KeyboardArrowRightIcon />}
                            onClick={handleNext}
                            disabled={!answers.Rechtsvorm__c}
                          >
                            {t('buttons.next')}
                          </Button>
                        </Grid>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Route>
              {answers.Rechtsvorm__c ? (
                <Route exact path={ROUTES[1]}>
                  <Grid
                    container
                    alignItems={'center'}
                    className={'question-content'}
                  >
                    <Grid item xs={12} className={classes.question} id="q2">
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        className={classes.title}
                      >
                        {answers.Rechtsvorm__c === 'STICH = Stichting'
                          ? t('questions.question2_1')
                          : t('questions.question2_2')}
                      </Typography>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup
                          aria-label={'mkb'}
                          name={'acc_sme__c'}
                          value={
                            answers.Rechtsvorm__c === 'STICH = Stichting'
                              ? answers.ANBI_Status__c
                              : answers.acc_sme__c
                          }
                          onChange={(event) => {
                            if (answers.Rechtsvorm__c === 'STICH = Stichting') {
                              setAnswers({
                                ...answers,
                                ANBI_Status__c: event.target.value,
                                acc_sme__c: 'No',
                              });
                            } else {
                              setAnswers({
                                ...answers,
                                acc_sme__c: event.target.value,
                              });
                            }
                          }}
                        >
                          {mkbOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} className={classes.actions}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.buttons}
                        style={
                          isLargeScreen
                            ? {}
                            : {
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <>
                          {!isLargeScreen && (
                            <Grid item>
                              <div style={{ height: 40, width: 40 }}>
                                <NivoDoughnut
                                  data={data}
                                  center={center}
                                  colors={COLORS}
                                  smallFont
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            justifyContent={
                              isLargeScreen ? 'space-between' : 'flex-end'
                            }
                          >
                            <Button
                              style={{ marginRight: 16 }}
                              disableRipple
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Button
                              name="ANBI_Status__c"
                              color="secondary"
                              variant="contained"
                              endIcon={<KeyboardArrowRightIcon />}
                              onClick={handleNext}
                              disabled={
                                answers.Rechtsvorm__c === 'STICH = Stichting'
                                  ? !answers.ANBI_Status__c
                                  : !answers.acc_sme__c
                              }
                            >
                              {t('buttons.next')}
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Route>
              ) : (
                <Redirect to="/client/subsidyscan/1" />
              )}
              {answers.ANBI_Status__c || answers.acc_sme__c ? (
                <Route exact path={ROUTES[2]}>
                  <Grid
                    container
                    alignItems={'center'}
                    className={'question-content'}
                  >
                    <Grid item xs={12} className={classes.question} id="q3">
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        className={classes.title}
                      >
                        {t('questions.question3')}
                      </Typography>
                      <Autocomplete
                        multiple
                        value={sectors}
                        id="sectors"
                        options={sectorOptions}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        className={
                          classes.formControl +
                          ' ' +
                          classes.autocompleteMultiple
                        }
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Sectors"
                            placeholder=""
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, values) => {
                          setSectors(values);
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} className={classes.actions}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.buttons}
                        style={
                          isLargeScreen
                            ? {}
                            : {
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <>
                          {!isLargeScreen && (
                            <Grid item>
                              <div style={{ height: 40, width: 40 }}>
                                <NivoDoughnut
                                  data={data}
                                  center={center}
                                  colors={COLORS}
                                  smallFont
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            justifyContent={
                              isLargeScreen ? 'space-between' : 'flex-end'
                            }
                          >
                            <Button
                              style={{ marginRight: 16 }}
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<KeyboardArrowRightIcon />}
                              onClick={handleNext}
                              disabled={!answers.acc_key_sectors__c}
                            >
                              {t('buttons.next')}
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Route>
              ) : (
                <Redirect to="/client/subsidyscan/1" />
              )}
              {answers.acc_key_sectors__c ? (
                <Route exact path={ROUTES[3]}>
                  <Grid
                    container
                    alignItems={'center'}
                    className={'question-content'}
                  >
                    <Grid item xs={12} className={classes.question} id="q4">
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        className={classes.title}
                      >
                        {t('questions.question4')}
                      </Typography>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="province-label" shrink={true}>
                          {t('labels.label3')}
                        </InputLabel>
                        <Select
                          id="province"
                          name="acc_province__c"
                          value={answers.acc_province__c}
                          input={
                            <OutlinedInput notched label={t('labels.label3')} />
                          }
                          onChange={(event) => {
                            setAnswers({
                              ...answers,
                              acc_province__c: event.target.value,
                            });
                          }}
                        >
                          {provinceOptions.map((option, i) => (
                            <MenuItem key={i} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} className={classes.actions}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.buttons}
                        style={
                          isLargeScreen
                            ? {}
                            : {
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <>
                          {!isLargeScreen && (
                            <Grid item>
                              <div style={{ height: 40, width: 40 }}>
                                <NivoDoughnut
                                  data={data}
                                  center={center}
                                  colors={COLORS}
                                  smallFont
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            justifyContent={
                              isLargeScreen ? 'space-between' : 'flex-end'
                            }
                          >
                            <Button
                              style={{ marginRight: 16 }}
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<KeyboardArrowRightIcon />}
                              onClick={handleNext}
                              disabled={!answers.acc_province__c}
                            >
                              {t('buttons.next')}
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Route>
              ) : (
                <Redirect to="/client/subsidyscan/1" />
              )}
              {answers.acc_province__c ? (
                <Route exact path={ROUTES[4]}>
                  <Grid
                    container
                    alignItems={'center'}
                    className={'question-content'}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.question}
                      id="q5"
                    >
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        className={classes.title}
                      >
                        {t('questions.question5')}
                      </Typography>
                      <TextField
                        className={classes.formControl}
                        id="employees"
                        name="Employees_Excluding_Founders__c"
                        label={t('labels.label4')}
                        variant="outlined"
                        fullWidth
                        autoFocus={isLargeScreen}
                        value={answers.Employees_Excluding_Founders__c}
                        onChange={(event) => {
                          setAnswers({
                            ...answers,
                            Employees_Excluding_Founders__c: event.target.value,
                          });
                        }}
                        InputProps={{
                          inputComponent: NumberFormatPositives,
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} className={classes.actions}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.buttons}
                        style={
                          isLargeScreen
                            ? {}
                            : {
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <>
                          {!isLargeScreen && (
                            <Grid item>
                              <div style={{ height: 40, width: 40 }}>
                                <NivoDoughnut
                                  data={data}
                                  center={center}
                                  colors={COLORS}
                                  smallFont
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            justifyContent={
                              isLargeScreen ? 'space-between' : 'flex-end'
                            }
                          >
                            <Button
                              style={{ marginRight: 16 }}
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<KeyboardArrowRightIcon />}
                              onClick={handleNext}
                              disabled={
                                !answers.Employees_Excluding_Founders__c ||
                                parseInt(
                                  answers.Employees_Excluding_Founders__c,
                                ) < 0
                              }
                            >
                              {t('buttons.next')}
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Route>
              ) : (
                <Redirect to="/client/subsidyscan/1" />
              )}
              {answers.Employees_Excluding_Founders__c ? (
                <Route exact path={ROUTES[5]}>
                  <Grid
                    container
                    alignItems={'center'}
                    className={'question-content'}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.question}
                      id="q6"
                    >
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                          className={classes.title}
                        >
                          {t('questions.question6')}
                        </Typography>
                      </Grid>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup
                          color="primary"
                          aria-label={'amount'}
                          name={'Investment_Needed__c'}
                          value={answers.Investment_Needed__c}
                          onChange={(event) => {
                            setAnswers({
                              ...answers,
                              Investment_Needed__c: event.target.value,
                            });
                          }}
                        >
                          {amountOptions.map((tile, i) => (
                            <FormControlLabel
                              key={i}
                              value={tile.value}
                              control={<Radio />}
                              label={tile.label}
                              color="textPrimary"
                            ></FormControlLabel>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} className={classes.actions}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.buttons}
                        style={
                          isLargeScreen
                            ? {}
                            : {
                                flexWrap: 'nowrap',
                                justifyContent: 'space-between',
                              }
                        }
                      >
                        <>
                          {!isLargeScreen && (
                            <Grid item>
                              <div style={{ height: 40, width: 40 }}>
                                <NivoDoughnut
                                  data={data}
                                  center={center}
                                  colors={COLORS}
                                  smallFont
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            justifyContent={
                              isLargeScreen ? 'space-between' : 'flex-end'
                            }
                          >
                            <Button
                              style={{ marginRight: 16 }}
                              startIcon={<KeyboardArrowLeftIcon />}
                              onClick={handleBack}
                            >
                              {t('buttons.back')}
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<KeyboardArrowRightIcon />}
                              onClick={handleSubmit}
                              disabled={!answers.Investment_Needed__c}
                            >
                              {t('buttons.next')}
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Route>
              ) : (
                <Redirect to="/client/subsidyscan/1" />
              )}
            </Switch>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('buttons.cancel')}
        text={t('dialog.text1')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
        handleClose={true}
      />

      {/* Pre-Qualification */}
      <CustomDialog
        openDialog={disqualify}
        setOpenDialog={setOpenDialog}
        title={t('disqualify.title')}
        content={
          <DialogText
            text1={t('disqualify.sub_titile')}
            text2={t('disqualify.sub_title_2')}
            text3={t('disqualify.sub_title_3')}
          />
        }
        confirm={t('disqualify.action')}
        handleConfirm={() => {
          window.location =
            'https://subsidycloud.com/nl/de-optimale-financieringsmix-voor-je-onderneming-een-handleiding/';
        }}
      />
    </>
  );
};

export default withRouter(CTAQuestions);
