import i18n from '../JS/i18n';

export const applicationStatusOptions = () => {
    return [
        {
            label: i18n.t('application_status.potential'),
            value: 'Potentieel',
        },
        {
            label: i18n.t('application_status.potential'),
            value: 'Recommended',
        },
        {
            label: i18n.t('application_status.concept'),
            value: 'Concept',
        },
        {
            label: i18n.t('application_status.concept'),
            value: 'Klaar voor review',
        },
        {
            label: i18n.t('application_status.concept'),
            value: 'Wacht op akkoord',
        },
        {
            label: i18n.t('application_status.concept'),
            value: 'Klaar voor indienen',
        },
        {
            label: i18n.t('application_status.applied'),
            value: 'Ingediend',
        },
        {
            label: i18n.t('application_status.applied'),
            value: 'Vormvrij',
        },
        {
            label: i18n.t('application_status.applied'),
            value: 'In behandeling',
        },
        {
            label: i18n.t('application_status.granted'),
            value: 'Beschikt',
        },
        {
            label: i18n.t('application_status.granted'),
            value: 'Geëffectueerd',
        },
        {
            label: i18n.t('application_status.granted'),
            value: 'Afgerond',
        },
        {
            label: i18n.t('application_status.rejected'),
            value: 'Afgewezen',
        },
        {
            label: i18n.t('application_status.rejected'),
            value: 'Ingetrokken',
        },
        {
            label: i18n.t('application_status.nopotential'),
            value: 'Geen potentieel',
        },
        {
            label: i18n.t('application_status.granted'),
            value: 'Ruling',
        },
        {
            label: i18n.t('application_status.granted'),
            value: 'Ruling afgerond',
        },

    ];
};

