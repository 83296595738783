import React, { useState, useEffect } from 'react';
import { useStyles } from '../Components/ForgotPassword/styles';
import { withRouter } from 'react-router-dom';
import BaseLayout from '../Layouts/BaseLayout';
import { Grid, Snackbar, IconButton } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import ForgotPasswordSideComponent from '../Components/ForgotPassword/ForgotPasswordSideComponent';
import ForgotPasswordDetails from '../Components/ForgotPassword/ForgotPasswordDetails';
import Loader from '../Components/uiComponents/Loader';
import i18n from '../JS/i18n';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const ForgotPassword = ({ history }) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const [showSuccess, setShowSuceess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuceess(false);
    setShowError(false);
  };

  useEffect(() => {
    window.document.title = t('page_title.forgot_pass');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <BaseLayout
        main={
          <>
            <Grid container justifyContent="center" className={classes.root}>
              <Grid
                container
                item
                xs={12}
                alignContent="center"
                wrap="nowrap"
                style={{ maxHeight: 'calc(100vh - 72px)', overflowY: 'auto' }}
              >
                {isLargeScreen && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{
                      height: '100%',
                      width: 'max-content',
                    }}
                  >
                    <ForgotPasswordSideComponent />
                  </Grid>
                )}
                <Grid container item justifyContent="center">
                  <ForgotPasswordDetails
                    email={email}
                    setEmail={setEmail}
                    handleLogin={handleForgotPassword}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showError}
              onClose={handleCloseSnackbar}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ top: 80 }}
            >
              <Alert
                severity="error"
                style={{ position: 'relative', paddingRight: 46 }}
              >
                {t('forgot_password.error')}
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                  style={{
                    marginLeft: 10,
                    position: 'absolute',
                    right: '10px',
                    top: '12px',
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={showSuccess}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message={t('forgot_password.success')}
            />
          </>
        }
      ></BaseLayout>
    </>
  );
  function handleForgotPassword(e) {
    setLoading(true);
    e.preventDefault();
    var requestBody = {
      username: email,
      language: i18n.languages[0],
    };
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/users/sendResetPasswordMail',
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          setShowSuceess(true);
          setLoading(false);
        } else {
          setShowError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setShowError(true);
        setLoading(false);
      });
  }
};

export default withRouter(ForgotPassword);
