import React, { useState, useEffect, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormLabel,
  Button,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { theme as bdtheme } from "../../JS/BrightDesignTheme";
import { newProjectDoughnutData } from "../../data/picklistOptions";
import NivoDoughnut from "../uiComponents/NivoDoughnut";
import { useMediaQuery } from "react-responsive";
import { useStyles } from "../Onboarding/styles";
import { ReactComponent as SadFace } from "../../assets/Sad_face_TwoColor.svg";
import { useTranslation } from "react-i18next";
import { handleChange, NumberFormatCustom } from "../../JS/FormControls";
import { NewProjectContext } from "../../Contexts/NewProject";
import { DashboardContext } from "../../Contexts/DashboardContext";
import { UserContext } from "../../Contexts/UserContext";
import axios from "axios";
import { mkbOptionss } from "../../data/picklistOptions";
import Loader from "../uiComponents/Loader";
import NoResultsDialog from "../uiComponents/NoResultsDialog";

const NewProjectTypeInvestEmployees = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isLarge = useMediaQuery({ query: "(min-width: 1280px)" });

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, "rgba(224, 224, 224, 1)"];
  const data = newProjectDoughnutData(props.progressStep);

  const {
    projectEmployees,
    setProjectEmployees,
    projectType,
    setNewProjectId,
    newProjectId,
  } = useContext(NewProjectContext);
  const { setProjectAddedId } = useContext(DashboardContext);
  const [loading, setLoading] = React.useState(false);
  const [userContext] = useContext(UserContext);

  const radioOptions = mkbOptionss();
  const [disabled, setDisabled] = useState(true);
  const [nosubsidies, setNosubsidies] = useState(false);

  const ROUTES = ["/client/newproject/choose", "/client/newproject/upload"];

  const handleNext = () => {
    setLoading(true);

    let body = {
      Project: projectEmployees,
    };

    var requestBodyCreate = {
      Project: projectEmployees,
      _id: newProjectId,
    };

    var apiEndPoint =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: "post",
      url: apiEndPoint + "/projectscan/projectscan",
      data: body,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (res.data.subsidies.length > 0) {
          axios({
            method: "post",
            url: apiEndPoint + "/project/addProject",
            data: requestBodyCreate,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userContext.token}`,
            },
          })
            .then((res) => {
              setLoading(false);
              setNewProjectId(res.data.projectId);
              setProjectAddedId(res.data.projectId);
              props.setProgressStep(props.progressStep + 1);
              history.push(ROUTES[1]);
            })
            .catch((error) => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          setNosubsidies(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    if (props.progressStep !== 0) {
      history.push(ROUTES[0]);
    }
  };

  useEffect(() => {
    if (
      projectEmployees.name &&
      projectEmployees.idea &&
      projectEmployees.problemStatement &&
      projectEmployees.learningWorkPrograms &&
      projectEmployees.investmentInEmployees &&
      projectEmployees.hiredWorkersAtDisadvantage &&
      projectEmployees.willHireWorkersAtDisadvantage &&
      projectEmployees.subsidyNeed &&
      projectEmployees.projectTypeId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectEmployees]);

  useEffect(() => {
    setProjectEmployees({
      ...projectEmployees,
      projectTypeId: projectType,
      accountId: userContext.user.salesforceAccountId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container justify="center" className={classes.root}>
        <Grid container item xs={12} justify="center" alignContent="center">
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            {/*<>TITLE</>*/}
            <Grid
              container
              item
              xs={12}
              justify="center"
              className="title-section"
            >
              <Grid item xs={12} lg={6} className={classes.title}>
                <Box mb={1}>
                  <Typography variant="h6">
                    {t("project-details.title")}
                  </Typography>
                </Box>
                <Box pb={3}>
                  <Typography variant="body2" className={classes.textDisabled}>
                    {t("project-details.subtitle")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
            {/*<>PROJECT</>*/}
            <Grid
              container
              item
              xs={12}
              lg={6}
              justify={isLarge ? "flex-start" : "center"}
              className={classes.subSection}
            >
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    className={classes.subTitle}
                  >
                    {t("project-details.project")}
                  </Typography>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="name"
                    name="name"
                    label={t("labels.project_name")}
                    variant="outlined"
                    helperText={t("helper_text.project_name")}
                    type="text"
                    fullWidth
                    autoFocus={isLargeScreen}
                    autoComplete="off"
                    value={projectEmployees.name}
                    //onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e, projectEmployees, setProjectEmployees);
                    }}
                    inputProps={{
                      maxLength: 150,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="description1"
                    name="idea"
                    label={t("projectX.label2")}
                    variant="outlined"
                    multiline
                    rows={5}
                    helperText={t("helper_text.description1")}
                    fullWidth
                    autoComplete="off"
                    value={projectEmployees.idea}
                    onChange={(e) => {
                      handleChange(e, projectEmployees, setProjectEmployees);
                    }}
                    inputProps={{
                      maxLength: 1000,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.helperText + " " + classes.formControl}
                    id="description2"
                    name="problemStatement"
                    label={t("projectX.label3")}
                    variant="outlined"
                    multiline
                    rows={5}
                    helperText={t("helper_text.description2")}
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={projectEmployees.problemStatement}
                    onChange={(e) => {
                      handleChange(e, projectEmployees, setProjectEmployees);
                    }}
                    inputProps={{
                      maxLength: 1000,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            <Grid container item xs={12}>
              {/*<> SUBSECTION </>*/}
              <Grid
                container
                item
                xs={12}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    className={classes.subTitle}
                  >
                    {t("project-details.activities")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {t("project-details.investEmployees_1")}
                      </FormLabel>
                      <RadioGroup
                        name={"learningWorkPrograms"}
                        value={projectEmployees.learningWorkPrograms}
                        onChange={(e) => {
                          handleChange(
                            e,
                            projectEmployees,
                            setProjectEmployees
                          );
                        }}
                      >
                        {radioOptions.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/*<> SUBSECTION </>*/}
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-end" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {t("project-details.investEmployees_2")}
                      </FormLabel>
                      <RadioGroup
                        name={"investmentInEmployees"}
                        value={projectEmployees.investmentInEmployees}
                        onChange={(e) => {
                          handleChange(
                            e,
                            projectEmployees,
                            setProjectEmployees
                          );
                        }}
                      >
                        {radioOptions.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/*<> SUBSECTION </>*/}
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {t("project-details.investEmployees_3")}
                      </FormLabel>
                      <RadioGroup
                        name={"hiredWorkersAtDisadvantage"}
                        value={projectEmployees.hiredWorkersAtDisadvantage}
                        onChange={(e) => {
                          handleChange(
                            e,
                            projectEmployees,
                            setProjectEmployees
                          );
                        }}
                      >
                        {radioOptions.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          ></FormControlLabel>
                        ))}
                      </RadioGroup>
                      <FormHelperText>
                        {t("helper_text.select1")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/*<> SUBSECTION </>*/}
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-end" : "center"}
                className={classes.subSection}
              >
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">
                      {t("project-details.investEmployees_4")}
                    </FormLabel>
                    <RadioGroup
                      name={"willHireWorkersAtDisadvantage"}
                      value={projectEmployees.willHireWorkersAtDisadvantage}
                      onChange={(e) => {
                        handleChange(e, projectEmployees, setProjectEmployees);
                      }}
                    >
                      {radioOptions.map((option, i) => (
                        <FormControlLabel
                          key={i}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        ></FormControlLabel>
                      ))}
                    </RadioGroup>
                    <FormHelperText>{t("helper_text.select1")}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>
          {/*<>SECTION</>*/}
          <Grid container item xs={12} className={classes.section}>
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify={isLarge ? "flex-start" : "center"}
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className={classes.subTitle}
                    >
                      {t("project-details.subsidie")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.helperText + " " + classes.formControl}
                    >
                      <TextField
                        id="amount"
                        name="subsidyNeed"
                        variant="outlined"
                        type="currency"
                        autoComplete="off"
                        placeholder="€"
                        label={t("labels.subsidie")}
                        value={projectEmployees.subsidyNeed}
                        onChange={(e) => {
                          handleChange(
                            e,
                            projectEmployees,
                            setProjectEmployees
                          );
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      <FormHelperText id="outlined-amount-helper-text">
                        {t("helper_text.subsidie")}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify="center"
                className={classes.subSection}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*<>BUTTONS SECTION</>*/}
          <Grid
            container
            item
            xs={12}
            justify="center"
            className={classes.section + " " + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.buttons}
                style={
                  isLargeScreen
                    ? {}
                    : {
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }
                }
              >
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justify={isLargeScreen ? "space-between" : "flex-end"}
                  >
                    <Button
                      style={{ marginRight: 16 }}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={handleBack}
                    >
                      {t("buttons.back")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                      onClick={handleNext}
                      disabled={disabled}
                    >
                      {t("buttons.next")}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NoResultsDialog
        openDialog={nosubsidies}
        setOpenDialog={setNosubsidies}
        title={t("select_subsidies.no_subsidies")}
        text={t("select_subsidies.no_subsidies_text")}
        image={
          <>
            <SadFace />
          </>
        }
        alignment="center"
        cancel={t("buttons.cancel_2")}
        confirm={t("dialog.to_dashboard")}
        handleConfirm={props.handleConfirm}
      />
    </>
  );
};

export default withRouter(NewProjectTypeInvestEmployees);
