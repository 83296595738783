import React from 'react';
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const margin = { top: 0, right: 0, bottom: 0, left: 0 };

const styles = {
  root: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: margin.right,
    bottom: 0,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: '#0B1A46',
    textAlign: 'center',
    // This is important to preserve the chart interactivity
    pointerEvents: 'none',
  },
  small: {
    fontSize: 12,
  },
  totalLabel: {
    fontSize: 14,
  },
};

const theme = {
  background: 'transparent',
  axis: {
    fontSize: '14px',
    tickColor: '#eee',
    ticks: {
      line: {
        stroke: '#555555',
      },
      text: {
        fill: '#ffffff',
      },
    },
    legend: {
      text: {
        fill: '#aaaaaa',
      },
    },
  },
  grid: {
    line: {
      stroke: '#555555',
    },
  },
};
const NivoDoughnut = ({ data, center, colors, smallFont }) => {
  return (
    <>
      <div style={styles.root}>
        <ResponsivePie
          margin={margin}
          data={data}
          innerRadius={0.7727}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          theme={theme}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          colors={colors}
          animate
          isInteractive={false}
          //legends={legends}
        />
        <div
          style={
            smallFont ? { ...styles.overlay, ...styles.small } : styles.overlay
          }
        >
          <span>{center}</span>
        </div>
      </div>
    </>
  );
};

export default NivoDoughnut;
