/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as ITanksLogo } from "../assets/iTanks_logo_topbar_onwhite.svg";
import { ReactComponent as ITanksLogoOnDark } from "../assets/iTanks_logo_topbar_ondark.svg";
import { ReactComponent as Logout } from "../assets/logout_black_24dp.svg";
import { UserContext } from "../Contexts/UserContext";
import Loader from "../Components/uiComponents/Loader";
import axios from "axios";

import {
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PublishIcon from "@material-ui/icons/Publish";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { MenuItemss, UserItemss } from "./MenuItems";
import { useMediaQuery } from "react-responsive";
import ProfileMenu from "../Components/uiComponents/ProfileMenu";
import CustomDialog from "../Components/uiComponents/CustomDialog";

const NavBar = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleChangeTab = (event, newValue) => {
    props.setTabValue(newValue);
  };
  const [userContext, setUserContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [projectMode, setProjectMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(false);
  const [backProjectId, setBackProjectId] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);

  const MenuItems = MenuItemss();
  const UserItems = UserItemss();

  const logoutHandler = () => {
    setLoading(true);
    let apiEndPoint =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: "get",
      url: apiEndPoint + "/users/logout",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        localStorage.setItem("token", null);
        localStorage.setItem("user", null);
        localStorage.setItem("logout", Date.now());
        setUserContext((oldValues) => {
          return { ...oldValues, user: null, token: null };
        });
        setLoading(false);
        history.push("/client/login");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ display: "content" }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location.pathname === "/client/app/projects") {
      setTitle(t("dashboard.menu.navitem2"));
      setProjectMode(false);
      setEditMode(false);
    } else if (location.pathname === "/client/app/applications") {
      setTitle(t("dashboard.menu.navitem3"));
      setProjectMode(false);
      setEditMode(false);
    } else if (location.pathname === "/client/app/account") {
      setTitle(t("dashboard.my_account"));
      setProjectMode(false);
      setEditMode(false);
    } else if (location.pathname.includes("/edit")) {
      let name;
      if (location.state) {
        name = location.state.label;
      } else {
        name = t("dashboard.menu.navitem2");
      }
      let pathname = location.pathname;
      let url = pathname.slice(0, -5);
      let id = url.substring(url.lastIndexOf("/") + 1);
      setBackProjectId(id);
      setTitle(name);
      setProjectMode(true);
      setEditMode(true);
    } else if (location.pathname.includes("/client/app/projects/")) {
      if (location.state) {
        let name = location.state.name;
        setTitle(name);
      } else {
        let apiEndPoint =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;
        let id = location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        );
        var requestBody = {
          projectId: id,
        };
        axios({
          method: "post",
          url: apiEndPoint + "/project/getProjectName",
          withCredentials: true,
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userContext.token}`,
          },
        }).then((res) => {
          setTitle(res.data.name);
        });
      }
      setProjectMode(true);
      setEditMode(false);
    } else {
      setTitle("");
      setProjectMode(false);
      setEditMode(false);
    }
    props.setTabValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar
          className={classes.toolbarContent}
          style={!title ? { paddingLeft: 0 } : null}
        >
          {isLargeScreen ? (
            !editMode ? (
              <Typography
                variant="h6"
                noWrap
                style={
                  !projectMode
                    ? { paddingLeft: 24, paddingRight: 24 }
                    : { paddingLeft: 24, paddingRight: 24 }
                }
              >
                {projectMode && (
                  <IconButton
                    style={{ marginRight: 24, color: "#fff" }}
                    size="small"
                    component={Link}
                    to="/client/app/projects"
                  >
                    <ArrowBackIcon fontSize="large" />
                  </IconButton>
                )}
                {title}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ paddingLeft: 24, paddingRight: 24 }}
                >
                  {projectMode && (
                    <IconButton
                      style={{ marginRight: 24, color: "#fff" }}
                      //color="primary"
                      size="small"
                      component={Link}
                      to={{ pathname: `/client/app/projects/${backProjectId}` }}
                    >
                      <ArrowBackIcon fontSize="large" />
                    </IconButton>
                  )}
                  {title}
                </Typography>
              </>
            )
          ) : (
            <>
              {title ? (
                <>
                  <Typography
                    variant="h6"
                    noWrap
                    style={
                      !projectMode && !editMode
                        ? { paddingLeft: 24 }
                        : { paddingLeft: 16, paddingRight: 16 }
                    }
                  >
                    {projectMode && !editMode && (
                      <IconButton
                        //color="primary"
                        size="small"
                        style={{ marginRight: 16, color: "#fff" }}
                        component={Link}
                        to="/client/app/projects"
                      >
                        <ArrowBackIcon fontSize="medium" />
                      </IconButton>
                    )}
                    {projectMode && editMode && (
                      <IconButton
                        //color="primary"
                        size="small"
                        style={{ marginRight: 16, color: "#fff" }}
                        component={Link}
                        to={{
                          pathname: `/client/app/projects/${backProjectId}`,
                        }}
                      >
                        <ArrowBackIcon fontSize="medium" />
                      </IconButton>
                    )}
                    {title}
                  </Typography>
                  {!projectMode && !editMode && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="end"
                      className={classes.menuButton}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <ITanksLogoOnDark className={classes.imageLogo} />
                  </div>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="end"
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
          {isLargeScreen && (
            <ProfileMenu
              classUser={classes.user}
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handlelogout={logoutHandler}
              user={userContext.user}
            />
          )}
        </Toolbar>
        {!editMode && projectMode && (
          <>
            <Tabs
              className={classes.projectTabs}
              value={props.tabValue}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="secondary"
            >
              <Tab label={t("projectX.tab1")} {...a11yProps(0)} />
              <Tab label={t("projectX.tab2")} {...a11yProps(1)} />
              <Tab label={t("projectX.tab3")} {...a11yProps(2)} />
            </Tabs>
          </>
        )}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isLargeScreen ? "persistent" : "temporary"}
        classes={{
          paper: clsx(
            classes.drawer,
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
        open={isLargeScreen ? true : open}
        anchor={isLargeScreen ? "left" : "left"}
        ModalProps={{
          onBackdropClick: handleDrawerClose,
          onEscapeKeyDown: handleDrawerClose,
        }}
      >
        {isLargeScreen ? (
          <div className={classes.toolbar}>
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <ITanksLogo className={classes.imageLogo} />
            </div>
          </div>
        ) : (
          <div className={classes.drawerHeader}>
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <ITanksLogo className={classes.imageLogo} />
            </div>
            <IconButton onClick={handleDrawerClose} color="inherit">
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
        )}
        <List className={classes.menuList}>
          {MenuItems.map((item, index) => (
            <ListItem
              key={item.title}
              component={Link}
              to={item.href}
              onClick={() => {
                handleDrawerClose();
              }}
              selected={location.pathname.startsWith(item.href)}
            >
              <ListItemIcon>
                {index % 3 === 0 && <DashboardIcon />}
                {index % 3 === 1 && <PublishIcon />}
                {index % 3 === 2 && <CompareArrowsIcon />}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ color: "textSecondary" }}
              />
            </ListItem>
          ))}
        </List>
        {!isLargeScreen && (
          <>
            <Divider />
            <List className={classes.menuList}>
              {UserItems.map((item, index) => (
                <ListItem
                  key={item.title}
                  component={item.id !== "logout" ? Link : "div"}
                  to={item.href}
                  onClick={() => {
                    index === 1 ? setOpenDialog(true) : handleDrawerClose();
                  }}
                  selected={location.pathname.startsWith(item.href)}
                >
                  <ListItemIcon>
                    {index % 2 === 0 && <AccountCircleIcon />}
                    {index % 2 === 1 && (
                      <Logout style={{ fill: theme.palette.text.secondary }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{ color: "textSecondary" }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Drawer>

      <CustomDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleConfirm={logoutHandler}
        title={t("logout")}
        text={t("dialog.text3")}
        cancel={t("buttons.close")}
        confirm={t("buttons.continue")}
      />
    </>
  );
};

export default NavBar;
