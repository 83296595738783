/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import CTAQuestions from '../Components/Subsidyscan/CTAQuestions';
import CTASidebar from '../Components/Subsidyscan/CTASidebar';
import { OnboardingContext } from '../Contexts/Onboarding';

const Subsidyscan = () => {
  const { t } = useTranslation();
  const [progressStep, setProgressStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);
  const { answers, setAnswers } = useContext(OnboardingContext);
  return (
    <>
      <BaseLayout
        main={
          <CTAQuestions
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            answers={answers}
            setAnswers={setAnswers}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            center={`${center}%`}
          />
        }
        sidebar={
          <CTASidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            center={`${center}%`}
          />
        }
        close={
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenDialog(true)}
          >
            {t('buttons.close')}
          </Button>
        }
      ></BaseLayout>
    </>
  );
};

export default Subsidyscan;
