import { useCallback, useContext, useEffect } from 'react';
import { UserContext } from './Contexts/UserContext';
import './JS/i18n';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import GlobalStyles from './JS/globalStyles';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { OnboardingProvider } from './Contexts/Onboarding';
import { NewProjectProvider } from './Contexts/NewProject';
import { DashboardProvider } from './Contexts/DashboardContext';
import { theme } from './JS/BrightDesignTheme';
import Subsidyscan from './Pages/Subsidyscan';
import NotFound from './Pages/NotFound';
import Report from './Pages/Report';
import Login from './Pages/Login';
import ProjectDetails from './Pages/ProjectDetails';
import Checkout from './Pages/Checkout';
import UploadDocuments from './Pages/UploadDocuments';
import ProjectType from './Pages/ProjectType';
import ChoosePlan from './Pages/ChoosePlan';
import SelectSubsidies from './Pages/SelectSubsidies';
import Dashboard from './Pages/Dashboard';
import NewProjectType from './Pages/NewProjectType';
import NewProjectDetails from './Pages/NewProjectDetails';
import NewProjectUploadDocuments from './Pages/NewProjectUploadDocuments';
import NewProjectSubsidies from './Pages/NewProjectSubsidies';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import useHotjar from 'react-use-hotjar';
import axios from 'axios';
import ReactGA from 'react-ga';

function App() {
  let location = useLocation();
  const [userContext, setUserContext] = useContext(UserContext);
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_HOTJAR_ID
        : process.env.REACT_APP_HOTJAR_ID_PROD,
      6,
      false,
      console.info,
    );
  }, [initHotjar]);

  useEffect(() => {
    let trackingId =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_GA_ID
        : process.env.REACT_APP_GA_ID_PROD;

    ReactGA.initialize(trackingId, {
      debug: false,
    });
    ReactGA.pageview(location.pathname);
    if (userContext.user !== null) {
      ReactGA.set({ dimension1: userContext.user._id });
      ReactGA.set({ userId: userContext.user._id });
    }
    /*ReactGA.initialize(trackingId, {
      debug: true,
      gaOptions: {
        userId: userId,
      },
    });*/
  }, [location, userContext]);

  const verifyUser = useCallback(() => {
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'post',
      url: apiEndPoint + '/users/refreshToken',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data;
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', data.user);
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token, user: data.user };
          });
        } else {
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
          setUserContext((oldValues) => {
            return { ...oldValues, token: null, user: null };
          });
        }
        setTimeout(verifyUser, 60 * 60 * 1000);
      })
      .catch((err) => {
        localStorage.setItem('token', null);
        localStorage.setItem('user', null);
        setUserContext((oldValues) => {
          return { ...oldValues, token: null, user: null };
        });
      });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  const history = useHistory();
  const syncLogout = useCallback(
    (event) => {
      console.log('syncLogout', event.key);
      if (event.key === 'logout') {
        history.push('/client/login');
      }
    },
    [history],
  );

  useEffect(() => {
    window.addEventListener('storage', syncLogout);
    return () => {
      window.removeEventListener('storage', syncLogout);
    };
  }, [syncLogout]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="App">
        <OnboardingProvider>
          <Switch>
            <Redirect exact from="/" to="/client/subsidyscan/1" />
            <Redirect exact from="/login" to="/client/login" />
            <Redirect
              exact
              from="/client/dashboard"
              to="/client/app/dashboard"
            />
            <Redirect
              exact
              from="/client/projecten"
              to="/client/app/projecten"
            />
            <Redirect
              exact
              from="/client/applications"
              to="/client/app/applications"
            />
            <Route path="/client/subsidyscan" component={Subsidyscan} />
            <Route path="/client/makeaccount" component={Report} />
            <Route path="/client/login" component={Login} />
            <Route path="/client/forgotpassword" component={ForgotPassword} />
            <Route path="/client/reset" component={ResetPassword} />

            {userContext.token ? (
              <>
                <Route path="/client/choose" component={ProjectType} />
                <Route path="/client/addproject" component={ProjectDetails} />
                <Route path="/client/upload" component={UploadDocuments} />
                <Route
                  path="/client/selectsubsidies"
                  component={SelectSubsidies}
                />
                <Route path="/client/plan" component={ChoosePlan} />
                <Route path="/client/checkout" component={Checkout} />
                <DashboardProvider>
                  <Route path="/client/app/dashboard" component={Dashboard} />
                  <NewProjectProvider>
                    <Route
                      exact
                      path="/client/newproject/choose"
                      component={NewProjectType}
                    />
                    <Route
                      path="/client/newproject/addproject"
                      component={NewProjectDetails}
                    />
                    <Route
                      path="/client/newproject/upload"
                      component={NewProjectUploadDocuments}
                    />
                    <Route
                      path="/client/newproject/subsidies"
                      component={NewProjectSubsidies}
                    />
                  </NewProjectProvider>
                  <Route path="/client/app/projects" component={Dashboard} />
                  <Route
                    path="/client/app/applications"
                    component={Dashboard}
                  />
                  <Route path="/client/app/account" component={Dashboard} />
                </DashboardProvider>
              </>
            ) : (
              <>
                <Redirect to="/client/login" />
              </>
            )}
            <Route component={NotFound} />
          </Switch>
        </OnboardingProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
