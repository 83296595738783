import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Chip,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { OnboardingContext } from '../../Contexts/Onboarding';
import { UserContext } from '../../Contexts/UserContext';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import axios from 'axios';
import Loader from '../uiComponents/Loader';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';
import CustomSnackbar from './CustomSnackbar';
import ReactGA from 'react-ga';
import { Experiment, Variant } from 'react-optimize';

import {
  handleChange,
  checkValidation,
  regEmail,
  regPassword,
  regUppercase,
  regDigit,
  regSpecialSign,
} from '../../JS/FormControls';

const MakeAccount = ({
  center,
  progressStep,
  setProgressStep,
  openDialog,
  setOpenDialog,
  subsidiesAmount,
  subsidiesNumber,
  answers,
  scanDone,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [contact, setContact] = useState({
    fname: '',
    lname: '',
    company: '',
    email: '',
    password: '',
    phone: '',
    agreement: false,
  });
  const { user, setUser, setAnswers, setShowGreeting } =
    useContext(OnboardingContext);

  // eslint-disable-next-line
  const [isSubmitting, setIsSubmitting] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [userContext, setUserContext] = useContext(UserContext);

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(progressStep);

  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const ROUTES = ['/client/choose'];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const handleNext = () => {
    setLoading(true);
    const genericErrorMessage = 'Something went wrong! Please try again later.';
    let account = answers;
    account.AccountSource = 'Partner';
    account.Subsource__c = 'Associate Partner';
    account.Conversion_Medium__c = 'White Label';
    account.Referral_Code__c = 'SC0105';

    var requestBody = {
      Account: account,
      Contact: user,
      password: contact.password,
      potential: subsidiesAmount,
      phone: contact.phone,
    };
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/subsidyscan/createAccount',
      data: requestBody,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        setIsSubmitting(false);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', res.data.user);
        setUserContext((oldValues) => {
          return { ...oldValues, token: res.data.token, user: res.data.user };
        });
        setUser(res.data.user);
        setShowGreeting(true);
        setProgressStep(progressStep + 1);
        ReactGA.event({
          category: 'Onboarding',
          action: 'Account Created',
        });
        setLoading(false);
        history.push(ROUTES[0]);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setError(genericErrorMessage);
        setShowError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setProgressStep(6);
    ReactGA.event({
      category: 'Onboarding',
      action: 'Scan Completed',
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabled(!checkValidation(contact, regPassword, regEmail));
    setUser({
      ...user,
      FirstName: contact.fname,
      LastName: contact.lname,
      Email: contact.email,
    });
    setAnswers({
      ...answers,
      Name: contact.company,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.makeAccount}>
        <Grid container justifyContent="center" item xs={12}>
          <Grid
            container
            item
            xs={12}
            className={classes.content}
            alignItems="center"
          >
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent={isLargeScreen ? 'flex-start' : 'center'}
                alignItems="center"
                className={classes.leftSide}
              >
                <Grid container item xs={12} className={classes.leftSideCard}>
                  <Grid container item xs={12}>
                    <Grid container item xs={12} justifyContent="center">
                      <Typography
                        variant="h4"
                        align="center"
                        style={{ color: '#fff' }}
                      >
                        <Box pt={10.5} pb={4}>
                              {t('make_account.title_1')}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      style={{ alignContent: 'baseline' }}
                    >
                      <Grid container item xs={12} justifyContent="center">
                        <Typography
                          variant="h3"
                          align="center"
                          color="secondary"
                          //style={{ color: theme.palette.secondary.main }}
                        >
                          <Box fontWeight="fontWeightBold" pb={0}>
                            <NumberFormat
                              value={subsidiesAmount}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              isNumericString
                              prefix={'€'}
                            />
                          </Box>
                              <Box fontWeight="fontWeightBold" pb={0}>
                                {t('make_account.available')}
                              </Box>
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography
                          variant="h5"
                          align="center"
                          style={{ color: '#fff' }}
                        >
                          <Box pb={4}>
                                {subsidiesNumber}{' '}
                                {t('select_subsidies.matched')} / 1400{' '}
                                {t('make_account.subtitle')}
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                      <Typography
                        variant="caption"
                        align="center"
                        style={{ color: '#fff' }}
                      >
                        {t('make_account.text_1')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent={isLargeScreen ? 'flex-end' : 'center'}
                alignItems="center"
                className={classes.rightSide}
              >
                <Grid item xs={12}>
                  <Grid container item xs={12}>
                    <Box pb={3}>
                      <Typography color="textPrimary" variant="overline">
                        {t('make_account.form_title')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={classes.formControlMakeaccount}
                      id="fname"
                      name="fname"
                      label={t('make_account.first_name')}
                      variant="outlined"
                      fullWidth
                      autoFocus={isLargeScreen}
                      autoComplete="off"
                      value={contact.fname}
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={classes.formControlMakeaccount}
                      id="lname"
                      name="lname"
                      label={t('make_account.last_name')}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      value={contact.lname}
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={classes.formControlMakeaccount}
                      id="phone"
                      name="phone"
                      label={t('labels.mobile') + ' / ' + t('labels.phone')}
                      variant="outlined"
                      type="number"
                      fullWidth
                      autoComplete="off"
                      value={contact.phone}
                      placeholder="0612345678"
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={classes.formControlMakeaccount}
                      id="company"
                      name="company"
                      label={t('make_account.company')}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      value={contact.company}
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={classes.formControlMakeaccount}
                      id="email"
                      name="email"
                      label={t('make_account.email')}
                      variant="outlined"
                      type="email"
                      fullWidth
                      autoComplete="off"
                      value={contact.email}
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      className={
                        classes.formControlMakeaccount +
                        ' ' +
                        classes.formControlPass
                      }
                      id="password"
                      name="password"
                      label={t('make_account.password')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      autoComplete="off"
                      value={contact.password}
                      onChange={(e) => {
                        handleChange(e, contact, setContact);
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={
                      classes.formControlMakeaccount +
                      ' ' +
                      classes.chipsContainer
                    }
                  >
                    <Chip
                      style={{ marginBottom: 8 }}
                      label={'8 ' + t('make_account.characters')}
                      icon={
                        contact.password.length > 7 ? (
                          <DoneIcon color="secondary" className="icon-done" />
                        ) : contact.password.length === 0 ? null : (
                          <ErrorOutlineIcon
                            color="error"
                            className="icon-error"
                          />
                        )
                      }
                    />
                    <Chip
                      style={{ marginBottom: 8 }}
                      label={t('make_account.capital_letter')}
                      icon={
                        contact.password.length > 0 ? (
                          regUppercase.test(contact.password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                    <Chip
                      style={{ marginBottom: 8 }}
                      label={t('make_account.digit')}
                      icon={
                        contact.password.length > 0 ? (
                          regDigit.test(contact.password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                    <Chip
                      label={t('make_account.special_sign')}
                      icon={
                        contact.password.length > 0 ? (
                          regSpecialSign.test(contact.password) ? (
                            <DoneIcon color="secondary" className="icon-done" />
                          ) : (
                            <ErrorOutlineIcon
                              color="error"
                              className="icon-error"
                            />
                          )
                        ) : null
                      }
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormControlLabel
                      className={classes.formControlMakeaccount}
                      control={
                        <Checkbox
                          checked={contact.agreement}
                          onChange={(e) => {
                            handleChange(e, contact, setContact);
                          }}
                          name={'agreement'}
                        />
                      }
                      label={
                        <Typography
                          color="textPrimary"
                          variant="body2"
                          component="span"
                          display={'inline'}
                        >
                          {t('make_account.agreement')}{' '}
                          <Typography
                            variant="body2"
                            component="span"
                            display={'inline'}
                            color="primary"
                          >
                            <Link
                              href={t('make_account.conditions_link')}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'underline' }}
                            >
                              {t('make_account.conditions')}
                            </Link>
                          </Typography>{' '}
                          {t('make_account.and')}{' '}
                          <Typography
                            variant="body2"
                            component="span"
                            display={'inline'}
                            color="primary"
                          >
                            <Link
                              href={t('make_account.privacy_link')}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'underline' }}
                            >
                              {t('make_account.privacy')}
                            </Link>
                          </Typography>
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid container item xs={12} className={classes.actions}>
                    <>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.sectionMakeacccount}
                      >
                        <Grid item xs={12}>
                          <Box>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid container item xs={12} className={classes.button}>
                          <>
                            {!isLargeScreen && (
                              <Grid item>
                                <div style={{ height: 40, width: 40 }}>
                                  <NivoDoughnut
                                    data={data}
                                    center={center}
                                    colors={COLORS}
                                    smallFont
                                  />
                                </div>
                              </Grid>
                            )}
                            <Grid item>
                              <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<KeyboardArrowRightIcon />}
                                onClick={handleNext}
                                disabled={disabled}
                              >
                                {t('buttons.next')}
                              </Button>
                            </Grid>
                          </>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('buttons.cancel')}
        text={t('dialog.text1')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
        handleClose={true}
      />

      <CustomSnackbar
        showSnackbar={showError}
        handleCloseSnackbar={handleCloseSnackbar}
        text1={t('dialog.account_exists')}
        text2={t('sign_in')}
        text3={t('dialog.another_email')}
        action={`/client/login`}
      />
    </>
  );
};

export default withRouter(MakeAccount);
