import React from 'react';
import { Fade } from 'react-reveal';

const NotFound = () => {
  return (
    <Fade>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>404 Not found</h1>
      </div>
    </Fade>
  );
};

export default NotFound;
