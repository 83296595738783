import i18n from '../JS/i18n';

export const planOptionss = () => {
  return [
    {
      id: '0',
      title: i18n.t('plan.plan1'),
      subtitle: i18n.t('plan.plan1_title'),
    },
    {
      id: '1',
      title: i18n.t('plan.plan2'),
      subtitle: i18n.t('plan.plan2_title'),
    },
    {
      id: '2',
      title: i18n.t('plan.plan3'),
      subtitle: i18n.t('plan.plan3_title'),
    },
  ];
};

export const feeOptionss = () => {
  return [
    {
      id: '0',
      fee1: '20',
      fee2: '17.5',
      fee3: '15',
      fee4: 'tarief',
    },
    {
      id: '1',
      fee1: '15',
      fee2: '14',
      fee3: '13',
      fee4: 'tarief',
    },
    {
      id: '2',
      fee1: '14',
      fee2: '12.5',
      fee3: '11',
      fee4: 'tarief',
    },
    {
      id: '3',
      fee1: '20',
      fee2: '17.5',
      fee3: '15',
      fee4: i18n.t('plan.tarief'),
    },
  ];
};

export const supportOptionss = () => {
  return [
    {
      id: '0',
      options: [i18n.t('plan.option1'), i18n.t('plan.option2')],
    },
    {
      id: '1',
      options: [
        i18n.t('plan.option3'),
        i18n.t('plan.option4'),
        i18n.t('plan.option5'),
        i18n.t('plan.option6'),
        i18n.t('plan.option7'),
      ],
    },
    {
      id: '2',
      options: [
        i18n.t('plan.option3'),
        i18n.t('plan.option4'),
        i18n.t('plan.option5'),
        i18n.t('plan.option8'),
        i18n.t('plan.option9'),
        i18n.t('plan.option10'),
        i18n.t('plan.option7'),
      ],
    },
  ];
};
