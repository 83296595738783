import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Divider,
  Box,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
  Button,
  Snackbar,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useStyles } from './styles';
import { projectTypeOptionss } from '../../data/projectTypeOptions';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../uiComponents/ConfirmationDialog';

const ChooseProjectType = (props) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();

  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setShowGreeting(false);
    props.setReturningUser(false);
  };

  const handleChange = (option) => {
    props.setProjectType(option.id);
    localStorage.setItem('firstProjectType', option.id);
  };
  const history = useHistory();
  const ROUTES = ['/client/addproject'];
  const handleNext = () => {
    history.push(ROUTES[0]);
  };
  const projectTypeOptions = projectTypeOptionss();

  return (
    <>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
        >
          <Grid container item xs={12} className={classes.section}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className="title-section"
            >
              <Grid item xs={12} lg={12} className={classes.typeSection}>
                <Box mb={1}>
                  <Typography variant="h6">
                    {t('choose_project.title')}
                  </Typography>
                </Box>
                <Box pb={6}>
                  <Typography variant="body2" className={classes.textDisabled}>
                    {t('choose_project.subtitle')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {isLargeScreen && (
            <Grid container item xs={12} className={classes.section}>
              {projectTypeOptions.map((option, i) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  onClick={() => handleChange(option)}
                  key={i}
                  className={
                    option.id === props.projectType
                      ? classes.cardBox + ' ' + classes.cardBoxActive
                      : classes.cardBox
                  }
                >
                  <Card variant="outlined">
                    <CardContent
                      style={{
                        padding: '24px',
                        height: '180px',
                        cursor: 'pointer',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={9}>
                          <Typography variant="h6">{option.label}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          {option.id === props.projectType ? (
                            <CheckCircleRoundedIcon
                              color="secondary"
                              style={{ float: 'right' }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              className={classes.textDisabled}
                            >
                              {option.subLabel}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {!isLargeScreen && (
            <Grid
              container
              item
              xs={12}
              justifyContent={isLargeScreen ? '' : 'center'}
              className={classes.section}
            >
              <Grid item xs={12} className={classes.typeSection}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label={'projectTypeOptions'}
                    name={'projectTypeOptions'}
                    value={props.projectType}
                    onChange={(event) => {
                      props.setProjectType(event.target.value);
                      localStorage.setItem(
                        'firstProjectType',
                        event.target.value,
                      );
                    }}
                  >
                    {projectTypeOptions.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        value={option.id}
                        control={<Radio />}
                        label={option.label}
                      ></FormControlLabel>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.section + ' ' + classes.sectionMobile}
          >
            <Grid container item xs={12} className={classes.sectionButtons}>
              <Grid item xs={12}>
                <Box mt={!isSmallScreen ? 6 : 0}>
                  <Divider />
                </Box>
              </Grid>
              <Grid container item xs={12} className={classes.button}>
                <>
                  {!isLargeScreen && (
                    <Grid item>
                      <div style={{ height: 40, width: 40 }}>
                        <NivoDoughnut
                          data={data}
                          center={props.center}
                          colors={COLORS}
                          smallFont
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      label="Volgende"
                      endIcon={<KeyboardArrowRightIcon />}
                      disabled={!props.projectType}
                      onClick={handleNext}
                    >
                      {t('buttons.next')}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.showGreeting || props.returningUser}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={
          props.returningUser
            ? t('choose_project.welcome_back_message')
            : t('choose_project.welcome_message')
        }
      />

      {/* LOGOUT */}

      <ConfirmationDialog
        openDialog={props.openDialog}
        setOpenDialog={props.setOpenDialog}
        title={t('logout')}
        text={t('dialog.text2')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
      />
    </>
  );
};

export default ChooseProjectType;
