import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Accordion,
  AccordionSummary,
  Snackbar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Startup } from '../../assets/StartupTwoColor.svg';

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import { DashboardContext } from '../../Contexts/DashboardContext';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';

const ProjectsList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const history = useHistory();
  const { t } = useTranslation();
  const {
    newProjectAdded,
    setNewProjectAdded,
    projectAddedId,
    setNewProjectPathname,
  } = useContext(DashboardContext);
  const [userContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNewProjectAdded(false);
  };

  function createData(ProjectId, ProjectName, ProjectType, ProjectAmount) {
    return {
      ProjectId,
      ProjectName,
      ProjectType,
      ProjectAmount,
    };
  }

  const labelTypes = {
    '0123X000001GJUGQA4': t('choose_project.type1'),
    '0123X000001GJUCQA4': t('choose_project.type2'),
    '0123X000001GJUDQA4': t('choose_project.type3'),
    '0123X000001GJUEQA4': t('choose_project.type4'),
    '0123X000001GJUFQA4': t('choose_project.type5'),
  };

  useEffect(() => {
    setLoading(true);
    let data = [];
    var apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'get',
      url: apiEndPoint + '/project/getAllProjects',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        res.data.projects.forEach((item, i) => {
          data.push(
            createData(
              item.Id,
              item.Project_Name__c,
              item.RecordTypeId,
              item.Subsidy_needed__c,
            ),
          );
        });
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    window.document.title = t('page_title.projects');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topPageRef = React.useRef();

  useEffect(() => {
    document.getElementById('anchor-div').scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Grid container className={classes.root} style={{ position: 'relative' }}>
        <div
          ref={topPageRef}
          id="anchor-div"
          style={{ position: 'absolute', top: '-120px', left: '0' }}
        ></div>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="flex-start"
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            className={classes.container}
          >
            <Grid item xs={12} className={classes.box}>
              <Box mb={2.5}>
                <Typography component="h5" variant="h5" align="center">
                  {t('projects.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {t('projects.subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} className={classes.box}>
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  variant="outlined"
                  style={(theme.custom.boxshadow, { marginRight: 24 })}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={isLargeScreen ? 7 : 12}
                        alignContent="center"
                      >
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Typography>{t('projects.card_title')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              {t('projects.card_subtitle')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={isLargeScreen ? 5 : 12}>
                        <Startup
                          style={{
                            width: isLargeScreen ? 'auto' : '100%',
                            float: 'right',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        setNewProjectPathname('projects');
                        history.push('/client/newproject/choose');
                      }}
                    >
                      {t('projects.card_new')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.box + ' ' + classes.boxTablePhone}
            >
              <Grid item xs={12}>
                {!isSmallScreen ? (
                  <>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        className={classes.table}
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ width: '27%', fontWeight: 'bold' }}
                            >
                              {t('projects.name')}
                            </TableCell>
                            <TableCell
                              style={{ width: '42%', fontWeight: 'bold' }}
                            >
                              {t('projects.type')}
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {t('projects.needed')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.ProjectId}>
                              <TableCell
                                style={{ padding: 0, maxWidth: '214px' }}
                              >
                                <Link
                                  to={{
                                    pathname: `/client/app/projects/${row.ProjectId}`,
                                    state: { name: row.ProjectName },
                                  }}
                                  style={{
                                    padding: 16,
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <Tooltip
                                    title={row.ProjectName}
                                    placement="top-start"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {row.ProjectName}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                              <TableCell
                                style={{ padding: 0, maxWidth: '322px' }}
                              >
                                <Link
                                  to={{
                                    pathname: `/client/app/projects/${row.ProjectId}`,
                                    state: { name: row.ProjectName },
                                  }}
                                  style={{
                                    padding: 16,
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <Tooltip
                                    title={labelTypes[row.ProjectType]}
                                    placement="top-start"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ width: '100%' }}
                                    >
                                      {labelTypes[row.ProjectType]}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                              <TableCell style={{ padding: 0 }}>
                                <Link
                                  to={{
                                    pathname: `/client/app/projects/${row.ProjectId}`,
                                    state: { name: row.ProjectName },
                                  }}
                                  style={{
                                    padding: 16,
                                    display: 'inline-block',
                                    fontSize: 16,
                                  }}
                                >
                                  <NumberFormat
                                    value={row.ProjectAmount}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    isNumericString
                                    prefix={'€'}
                                  />
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <>
                    {rows.map((row, index) => {
                      return (
                        <>
                          <Accordion
                            key={row.ProjectId}
                            expanded={false}
                            onChange={(event) => {
                              event.stopPropagation();
                            }}
                            style={{ border: 'none', boxShadow: 'none' }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <Link
                                  to={{
                                    pathname: `/client/app/projects/${row.ProjectId}`,
                                    state: { name: row.ProjectName },
                                  }}
                                  style={{ display: 'flex' }}
                                >
                                  <KeyboardArrowRight color="primary" />
                                </Link>
                              }
                            >
                              <Grid container key={row.ProjectId}>
                                <Grid container item xs={11}>
                                  <Typography
                                    component={Link}
                                    to={{
                                      pathname: `/client/app/projects/${row.ProjectId}`,
                                      state: { name: row.ProjectName },
                                    }}
                                    style={{ padding: 0 }}
                                  >
                                    {row.ProjectName}
                                  </Typography>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={1}
                                  alignContent="center"
                                  justifyContent="flex-end"
                                >
                                  <IconButton
                                    color="primary"
                                    size="small"
                                  ></IconButton>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                          </Accordion>
                        </>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={newProjectAdded}
        onClose={handleCloseSnackbar}
        message={
          <>
            <Grid container wrap="nowrap">
              <Grid item>{t('dialog.new_project_placed')}</Grid>
              <Grid item>
                <Link
                  to={`/client/app/projects/${projectAddedId}`}
                  onClick={(e) => {
                    setNewProjectAdded(false);
                    handleCloseSnackbar();
                  }}
                  style={{
                    marginLeft: 20,
                    color: theme.palette.secondary.main,
                    float: 'right',
                  }}
                >
                  {t('dialog.view')}
                </Link>
              </Grid>
            </Grid>
          </>
        }
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
              style={{ paddingTop: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default withRouter(ProjectsList);
