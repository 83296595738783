import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { useSidebarStyles as useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const ProjectSidebar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const steps = [
    t('project-sidebar.step1'),
    t('project-sidebar.step2'),
    t('project-sidebar.step3'),
    t('project-sidebar.step4'),
    t('project-sidebar.step5'),
    t('project-sidebar.step6'),
  ];

  function getStepContent(step) {
    switch (step) {
      case 2:
        return t('project-sidebar.step3_1');
      default:
        return '';
    }
  }
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(props.progressStep);

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid container alignContent="flex-start">
        <Grid item xs={12}>
          <div className={classes.chartContainer}>
            <NivoDoughnut data={data} center={props.center} colors={COLORS} />
          </div>
          <Box mt={2} mb={5}>
            <Typography variant="body1" align="center" color="textSecondary">
              {t('cta_sidebar.completed')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={0}
          justifyContent="center"
          className={classes.stepperContainer}
        >
          <Stepper
            activeStep={props.projectProgress}
            orientation="vertical"
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography variant="caption">
                    {getStepContent(index)}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectSidebar;
