/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import BaseLayout from '../Layouts/BaseLayout';
import { useMediaQuery } from 'react-responsive';
import UploadTypeProduct from '../Components/Onboarding/UploadTypeProduct';
import UploadTypeInternational from '../Components/Onboarding/UploadTypeInternational';
import UploadTypeBusiness from '../Components/Onboarding/UploadTypeBusiness';
import UploadTypeEmployees from '../Components/Onboarding/UploadTypeEmployees';
import UploadTypeSocial from '../Components/Onboarding/UploadTypeSocial';
import ProjectSidebar from '../Components/Onboarding/ProjectSidebar';
import { ReactComponent as SupportAgent } from '../assets/support_agent_black_36dp.svg';
import { OnboardingContext } from '../Contexts/Onboarding';
import { useTheme } from '@material-ui/core/styles';

const UploadDocuments = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [progressStep, setProgressStep] = useState(9);
  const [projectProgress, setProjectProgress] = useState(2);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const { projectType, newProjectId } = useContext(OnboardingContext);
  const [openDialog, setOpenDialog] = useState(false);
  const center = Math.round((100 / 13) * progressStep);

  useEffect(() => {
    window.document.title = t('page_title.upload');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!newProjectId) {
    return <Redirect to="/choose" />;
  }

  return (
    <>
      <BaseLayout
        main={
          <>
            {projectType === '0123X000001GJUGQA4' && (
              <UploadTypeProduct
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === '0123X000001GJUCQA4' && (
              <UploadTypeInternational
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === '0123X000001GJUDQA4' && (
              <UploadTypeBusiness
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === '0123X000001GJUEQA4' && (
              <UploadTypeEmployees
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
            {projectType === '0123X000001GJUFQA4' && (
              <UploadTypeSocial
                center={`${center}%`}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                progressStep={progressStep}
                setProgressStep={setProgressStep}
                newProjectId={newProjectId}
              />
            )}
          </>
        }
        sidebar={
          <ProjectSidebar
            progressStep={progressStep}
            setProgressStep={setProgressStep}
            projectProgress={projectProgress}
            setProjectProgress={setProjectProgress}
            center={`${center}%`}
          />
        }
        close={
          <>
            {isLargeScreen && (
              <>
                <SupportAgent
                  style={{
                    paddingRight: 8,
                    fill: theme.palette.secondary.main,
                  }}
                />
                <span>+31 (0)85 580 74 21</span>
              </>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {t('buttons.logout')}
            </Button>
          </>
        }
      ></BaseLayout>
    </>
  );
};

export default UploadDocuments;
