import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Chip,
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import {
  checkValidation,
  regEmail,
  regPassword,
  regUppercase,
  regDigit,
  regSpecialSign,
} from '../../JS/FormControls';

const LoginDetails = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [disabled, setDisabled] = useState(true);

  React.useEffect(() => {
    let user = {
      email: email,
      password: password,
    };
    setDisabled(!checkValidation(user, regPassword, regEmail));
  }, [email, password]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.formContainer}
        alignItems="flex-start"
        alignContent="center"
      >
        <Grid item xs={12} style={{ paddingBottom: 24 }}>
          <Typography color="textPrimary" variant="h6">
            {t('login.login')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            padding: '24px 24px 0px 24px',
            boxShadow: '0px 0px 0px 1px #E0E0E0',
            borderRadius: 4,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <TextField
                  id="email"
                  name="email"
                  label={t('make_account.email')}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  autoFocus={isLargeScreen}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <TextField
                  id="password"
                  name="password"
                  label={t('make_account.password')}
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  autoComplete="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.chipsContainer + ' ' + classes.formControl}
            >
              <Grid container item xs={12} style={{ marginBottom: 12 }}>
                <Chip
                  style={{ marginRight: 8, marginBottom: 8 }}
                  label={'8 ' + t('make_account.characters')}
                  icon={
                    password && password.length > 7 ? (
                      <DoneIcon color="secondary" className="icon-done" />
                    ) : password.length === 0 ? null : (
                      <ErrorOutlineIcon color="error" className="icon-error" />
                    )
                  }
                />
                <Chip
                  style={{ marginRight: 8 }}
                  label={t('make_account.capital_letter')}
                  icon={
                    password && password.length > 0 ? (
                      regUppercase.test(password) ? (
                        <DoneIcon color="secondary" className="icon-done" />
                      ) : (
                        <ErrorOutlineIcon
                          color="error"
                          className="icon-error"
                        />
                      )
                    ) : null
                  }
                />
                <Chip
                  style={{ marginRight: 8 }}
                  label={t('make_account.digit')}
                  icon={
                    password && password.length > 0 ? (
                      regDigit.test(password) ? (
                        <DoneIcon color="secondary" className="icon-done" />
                      ) : (
                        <ErrorOutlineIcon
                          color="error"
                          className="icon-error"
                        />
                      )
                    ) : null
                  }
                />
                <Chip
                  style={{ marginBottom: 8 }}
                  label={t('make_account.special_sign')}
                  icon={
                    password && password.length > 0 ? (
                      regSpecialSign.test(password) ? (
                        <DoneIcon color="secondary" className="icon-done" />
                      ) : (
                        <ErrorOutlineIcon
                          color="error"
                          className="icon-error"
                        />
                      )
                    ) : null
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ padding: '24px 0' }}
        >
          <Grid item style={{ paddingRight: 24 }}>
            <Link href="/client/forgotpassword" variant="body2">
              {t('login.forgot_pass')}
            </Link>
          </Grid>

          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              endIcon={<KeyboardArrowRightIcon />}
              onClick={handleLogin}
              disabled={disabled}
              className={classes.iconRight}
            >
              {t('login.login')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginDetails;
