import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '64px 24px 40px 24px',
    [theme.breakpoints.down('1279')]: {
      paddingBottom: 64,
    },
    [theme.breakpoints.down('767')]: {
      padding: '24px 16px 85px 16px',
    },
    '& > div': {
      maxWidth: 450,
      [theme.breakpoints.down('767')]: {
        width: 'auto',
      },
    },
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    width: '100%',
    marginBottom: 48,
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('767')]: {
        maxWidth: 'calc(100vw - 80px)',
      },
    },
  },
  formControlMakeaccount: {
    minWidth: 120,
    display: 'flex',
    width: '100%',
    marginBottom: 32,
    '&.MuiAutocomplete-root': {
      [theme.breakpoints.down('767')]: {
        maxWidth: 'calc(100vw - 80px)',
      },
    },
  },
  formControlPass: {
    marginBottom: 8,
  },
  /***** MAKE ACCOUNT *****/
  makeAccount: {
    height: '100%',
    '& > div': {
      padding: '64px 24px 40px 24px',
      [theme.breakpoints.down('1279')]: {
        paddingBottom: 49,
      },
      [theme.breakpoints.down('767')]: {
        padding: '24px 16px 64px 16px',
      },
    },
  },

  content: {
    maxWidth: 1080,
  },

  leftSide: {
    paddingRight: 12,

    [theme.breakpoints.up('1279')]: {
      paddingBottom: 25,
    },
    [theme.breakpoints.down('1279')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('767')]: {},
    '& > div': {
      position: 'relative',
      maxWidth: 450,
      padding: 56,
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      borderRadius: '0 0 4px 4px',
      height: '100%',
      background: theme.palette.primary.main,
      [theme.breakpoints.down('767')]: {},
    },
    '& .leftSideCard': {
      background: theme.palette.primary.main,
    },
    '& > div::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: theme.palette.secondary.main,
      content: '""',
      height: 8,
      //borderRadius: '4px 4px 0 0',
    },
  },
  giftCard: {
    fill: '#fff',
    transform: 'scale(2)',
  },
  rightSide: {
    paddingLeft: 12,
    [theme.breakpoints.down('1279')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {},
    '& > div': {
      maxWidth: 450,
      [theme.breakpoints.down('1279')]: {
        paddingTop: 56,
      },
    },
  },
  chipsContainer: {
    marginBottom: 16,
    '& > div ': {
      marginRight: 8,
      background: '#F5F5F5',
    },
    '& svg.icon-error': {
      fill: '#F44336',
    },
    '& svg.icon-done': {
      fill: theme.palette.primary.main,
    },
    '& > div:first-of-type': {
      [theme.breakpoints.down('767')]: {
        marginBottom: 16,
      },
    },
  },
  question: {
    [theme.breakpoints.down('1279')]: {
      padding: 24,
      borderRadius: 4,
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      marginBottom: 48,
    },
    [theme.breakpoints.down('767')]: {
      marginBottom: 0,
    },
  },
  actions: {
    [theme.breakpoints.down('767')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#ffffff',
      zIndex: 999,
    },
  },
  buttons: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0',
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    [theme.breakpoints.down('1279')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {
      padding: '12px 16px',
    },
  },
  button: {
    justifyContent: 'flex-end',
    padding: '24px 0',
    alignItems: 'center',
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('1279')]: {
      justifyContent: 'space-between',
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('767')]: {
      padding: '12px 16px',
    },
  },
  sectionMakeacccount: {
    maxWidth: 450,
    margin: 'auto',
  },
  progressStepper: {
    display: 'flex',
    bottom: 0,
    width: '100%',
    '& > div': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      background: '#ffffff',
    },
  },

  section: {
    maxWidth: 1080,
    [theme.breakpoints.up('3200')]: {
      maxWidth: 1190,
    },
    [theme.breakpoints.up('3840')]: {
      maxWidth: 1450,
    },
  },
  subSection: {
    alignContent: 'flex-start',
    '& > div': {
      maxWidth: 450,
    },
  },
  title: {
    marginBottom: 48,
    [theme.breakpoints.down('767')]: {
      marginBottom: 32,
    },
  },
  subTitle: {
    display: 'block',
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  helperText: {
    '& > p': {
      marginLeft: 0,
    },
  },
  card: {
    marginBottom: 48,
  },
  signSide: {
    position: 'relative',
    '& > div:first-of-type ': {
      borderRadius: '4px 4px 0px 0px',
    },
  },
  autocompleteMultiple: {
    '& .MuiAutocomplete-endAdornment': {
      top: '14px !important',
    },
  },
  signButton: {
    padding: 14,
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '0px 0px 4px 4px',
    position: 'absolute',
    bottom: -42,
    '& .MuiButton-startIcon': {
      marginRight: '8px !important',
    },

    '& button': {
      border: '1px solid #E0E0E0',
    },
    '& .MuiButton-root:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  searchIcon: {
    '& .MuiInputAdornment-root': {
      color: theme.palette.text.disabled,
    },
  },
  disqTitle: {
    '& > *': {
      fontWeight: 'bold',
    },
  },
}));

export const useSidebarStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingRight: 24,
    background: '#FAFAFA',
    boxShadow: '-1px 0px 0px #EEEEEE',
    position: 'fixed',
    top: 72,
    bottom: 0,
    right: 0,
    width: '25%',
  },
  container: {
    margin: 'auto',
    padding: 64,
    [theme.breakpoints.down('md')]: {
      padding: 24,
    },
  },
  chartContainer: {
    width: '132px',
    height: '132px',
    margin: '72px auto 0',
  },
  logoSection: {
    width: 232,
    margin: 'auto',
    paddingBottom: 32,
    '& > div': {},
  },
  logo: {
    paddingBottom: 32,
  },
  logoImage: {
    display: 'block',
    margin: 'auto',
    '&:not(:last-of-type)': {
      marginRight: 20,
    },
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
}));
