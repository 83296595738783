import React from 'react';
import { Link, IconButton, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const CustomSnackbar = ({
  showSnackbar,
  handleCloseSnackbar,
  text1,
  text2,
  text3,
  action,
}) => {
  return (
    <>
      <Snackbar
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ top: 80 }}
      >
        <Alert
          severity="error"
          style={{ position: 'relative', paddingRight: 46 }}
        >
          {text1}
          <Link
            href={action}
            style={{
              margin: '0 4px',
              cursor: 'pointer',
            }}
          >
            {text2}
          </Link>
          {text3}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
            style={{
              marginLeft: 10,
              position: 'absolute',
              right: '10px',
              top: '12px',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
