import React, { createContext, useState } from 'react';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [newProjectAdded, setNewProjectAdded] = useState(false);
  const [projectAddedId, setProjectAddedId] = useState('');
  const [newProjectPathname, setNewProjectPathname] = useState('');
  const [projectEditSuccess, setProjectEditSuccess] = useState(false);
  return (
    <DashboardContext.Provider
      value={{
        newProjectAdded,
        setNewProjectAdded,
        projectAddedId,
        setProjectAddedId,
        newProjectPathname,
        setNewProjectPathname,
        projectEditSuccess,
        setProjectEditSuccess,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
