import React from 'react';
import { dropzone, dropzoneLabel, preview } from '../Onboarding/styles';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { Typography, Box } from '@material-ui/core';
import PreviewUploadFile from './PreviewUploadFile';

const DropzoneBox = (props) => {
  const {
    file,
    label,
    inputContent,
    getUploadParams,
    onChangeStatus,
    multiple,
    maxFiles,
    maxwidth,
  } = props;
  return (
    <>
      {!file.id && (
        <>
          <Box
            mb={2.5}
            mt={5.5}
            style={{
              maxWidth: maxwidth ? maxwidth : 'unset',
            }}
          >
            <Typography variant="overline" color="textSecondary">
              {label}
            </Typography>
          </Box>
          <Dropzone
            accept=".pdf,.doc,.docx,.ppt"
            getUploadParams={getUploadParams}
            onChangeStatus={onChangeStatus}
            multiple={multiple}
            PreviewComponent={PreviewUploadFile}
            maxFiles={maxFiles}
            inputContent={inputContent}
            styles={{
              dropzone: {
                ...dropzone,
                maxWidth: maxwidth,
              },
              inputLabel: dropzoneLabel,
              preview: preview,
            }}
          />
        </>
      )}
    </>
  );
};

export default DropzoneBox;
