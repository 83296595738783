import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { doughnutData } from '../../data/picklistOptions';
import NivoDoughnut from '../uiComponents/NivoDoughnut';
import { ReactComponent as ScProviders } from '../../assets/subsidy_providers.svg';

import { theme as bdtheme } from '../../JS/BrightDesignTheme';
import { useSidebarStyles as useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const CTASidebar = ({ progressStep, setProgressStep, center }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const COLORS = [bdtheme.palette.primary.main, 'rgba(224, 224, 224, 1)'];
  const data = doughnutData(progressStep);

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid container alignContent="flex-start">
          <Grid item xs={12}>
            <div className={classes.chartContainer}>
              <NivoDoughnut data={data} center={center} colors={COLORS} />
            </div>
            <Box mt={2} mb={8}>
              <Typography variant="body1" align="center" color="textSecondary">
                {t('cta_sidebar.completed')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              style={{ marginBottom: '40%' }}
            >
              <ScProviders />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CTASidebar;
